<template>
    <div class="maxMaxth evebt">
		
        <div class="cRKA8AT8lf2bItsMSztA">
            <div class="a7neOLNNGzlPwu32tGO4">
				<div class="v0FZl0YCkbZ5KOXk68M4 max_conte van-dropdown-menu-box">
					<div class="my-scrollbar" style="margin-bottom: .2rem;">
						
					</div>
					<!-- <ant-select :value="timValue" :options="optionTim" @change="_onChangeTime">
					</ant-select> -->
					<ant-select style="margin-left: .2rem;" :value="game_typeValue" :options="game_type"
						>
					</ant-select>
					<ant-select style="margin-left: .2rem;" :value="recordsTudoValue" :options="recordsTudo"
						>
					</ant-select>
					<!-- <van-dropdown-menu>
					  <van-dropdown-item v-model="value1" :options="option1" />
					</van-dropdown-menu>
					<van-dropdown-menu>
					  <van-dropdown-item v-model="value1" :options="option1" />
					</van-dropdown-menu> -->
				</div>
                <div class="RnNfgaFEYzhMdHzQIun2">
					<span>{{ $t('_VIP._Bônus') }}</span>
					<span class="XGEOwGXgDL9a2SebPqvj"> 0,00</span>
				</div>
            </div>
        </div>
		<div class="" style="justify-content: center;align-items: center;display: flex;height: calc(calc(var(--vh, 1vh)* 100) - 1.62rem - 1.40rem);">
			<van-empty
			    image-size="2.1rem"
			    class="custom-image"
			    :image="img_none_sj"
			    :description="$t('description')"
			/>
		</div>
        
     </div>
  </template>
  
  <script>

  import {
  	records,
	recordsTudo
  } from "@/utils/tim.js"
  
  
  export default {
   name: 'canReceive',
   components: {

   },
   data(){
     return {
		game_type: records,
		game_typeValue:'0',
		recordsTudo:recordsTudo,
		recordsTudoValue:'0'
     }
   },
   methods:{
     tabFun(e){
       this.$store.commit('active',e)
     },
     stickyScroLL(e){
     }
   }
  }
  </script>
  <style>
	  .max_conte .van-ellipsis{
		  width: .8rem;
	  }
	.a{
		 float: left;
		    max-width: 100%;
		    overflow: hidden;
		    text-overflow: ellipsis;
		    white-space: nowrap
	}
</style>

  <style scoped>
  
  .v0FZl0YCkbZ5KOXk68M4{
	  display: flex;
	  
  }
    .cRKA8AT8lf2bItsMSztA{
        height: .9rem;
        padding: 0 .2rem;
    }
    .a7neOLNNGzlPwu32tGO4 {
    -ms-flex-align: center;
    -ms-flex-pack: end;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    height: .5rem;
    height: .9rem;
    justify-content: space-between;
}
     .cRKA8AT8lf2bItsMSztA {
    display: -ms-flexbox;
    font-size: .24rem;
    line-height: 1.5;
        color: var(--cms-text-color-secondary);
}

.XGEOwGXgDL9a2SebPqvj {
    color: var(--cms-top-money-color)
}

  </style>
  