<template>
	 <div class="gameItem3Dian5" style="display: flex;flex-wrap: wrap;width:100%">
		 <gameItem3Dian5  style="--cardRowSpacing: 0.22rem;--cardColSpacing: 0.065rem;" v-if="config.ui_version=='3.5'"  v-for="(item,index) in itemListConter.list" :item="{...item,type:'game'}"  :key="index"></gameItem3Dian5>
		 <gameItem v-else  v-for="(item,index) in itemListConter.list" :item="{...item,type:'game'}"  :key="index"></gameItem>
	 </div>
</template>

<script>
	import gameItem from "@/components/gameItem/index.vue"
	import gameItem3Dian5 from "@/components/gameItem/index3.5.vue"
	import { mapState } from 'vuex';
	export default{
		components: {
			gameItem,
			gameItem3Dian5
		},
		computed:{
			...mapState(['popList','userInfo','moneyInfo','config']),
		},
		props:{
			itemListConter:{
				type:[Array,Object],
				required: {},
			}
		}
	}
</script>

<style>
	/* .gameItem3Dian5 .nS3vNq6zfAw_0i1GT8st:nth-child(4n){
		margin-right: 0 !important;
	} */
	.gameItem3Dian5 .nS3vNq6zfAw_0i1GT8st:nth-child(1n){
		margin-left: 0 !important;
	}
</style>