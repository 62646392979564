import { render, staticRenderFns } from "./plx.vue?vue&type=template&id=b060b534&scoped=true"
import script from "./plx.vue?vue&type=script&lang=js"
export * from "./plx.vue?vue&type=script&lang=js"
import style0 from "./plx.vue?vue&type=style&index=0&id=b060b534&prod&lang=css"
import style1 from "./plx.vue?vue&type=style&index=1&id=b060b534&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b060b534",
  null
  
)

export default component.exports