<template>
	<div class="maxMaxth evebt">
		<div class="s2WtfjR20Zysa1AkgmCv UevxvHbpmdKmKs9LpvO1">
			<div class="ant-row-flex ant-row-flex-space-between ant-row-flex-middle kKiKuxvcOfS5NE0lGt0O">
				<div class="ant-col">
					<div class="rECgrZMnzlEqXUtHytGH">
						<span class="hhXnTQYFZIZepnDoixKF">{{ $t('yuebao._Depositado') }}</span>
						<div>
							<span class="bBn1FFHLuQzUvNB2ReF5"> 0
								<div class="uh6csli2MHzFz87__Phx tool-tips UbHoywlSqBjwlCYEOA2Z"
									style="--cu-top: -0.25rem; --cu-right: unset; --cu-left: 90%;">
									<p class="tool-tips-box sNgyWmhVEZVrbSGKDBra">
										<span class="zr8gSibXFpfAbJRSuBde">{{ $t('yuebao._TaxadeJurosAnual100') }}</span>
									</p>
									<div class="tool-tips-tail YiNQnpSJUes7u5sqBZhp"></div>
								</div>
							</span>
						</div>
					</div>
				</div>
				<div class="ant-col xHchAD3DtxThyMmPsugl">
					<van-button class="ant-btn-a ant-btn-a-finance" type="primary" size="small">{{ $t('yuebao._Depósito') }}</van-button>
					<van-button class="ant-btn-a ant-btn-a-primary bkFHrH6t0oIHzywZE8E_" type="primary" size="small">{{ $t('yuebao._Transferir') }}</van-button>
				</div>
			</div>
			<div class="ant-row-flex ant-row-flex-space-between ant-row-flex-middle kKiKuxvcOfS5NE0lGt0O">
				<div class="ant-col">
					<div class="vqS1TIVgbtEtk8NhF4Jg CDVYKswyz5bbkfypq6Il"><span
							class="kKiKuxvcOfS5NE0lGt0O hhXnTQYFZIZepnDoixKF">
							<div class="nt3HkYGHVSEvaGFFn12x IaB7qowESm87OGdl_B_p valueHighlight">
								<span>
									{{$t('yuebao._Ciclodeassentamento1horas') }}
								</span>
							</div>
						</span><span class="hhXnTQYFZIZepnDoixKF">
							<div class="nt3HkYGHVSEvaGFFn12x"><span>{{$t('yuebao._Coleçãocumulativa') }}</span>
								<div><span class="currencyAmount"> 0,00</span></div>
							</div>
						</span></div>
				</div>
				<div class="ant-col ImxaNnYH2DdgyIGT_2jJ">
					<div class="QA313RmuYYmXNHa9Jn7z F8Q09dDeyyZAK7Do5pkF"><span
							class="hhXnTQYFZIZepnDoixKF oDoeDvd2HKAzribulwKG">
							<div class="nt3HkYGHVSEvaGFFn12x">
								<span>{{$t('yuebao._MáximodejurosIlimitado') }}</span>
							</div>
						</span><span class="oDoeDvd2HKAzribulwKG">
							<div class="nt3HkYGHVSEvaGFFn12x"><span>{{ $t('popup._Pendente') }}</span>
								<div><span class="currencyAmount yellowColor"> 0,000000</span></div>
							</div>
						</span></div>
					<van-button class="ant-btn-a ant-btn-a-primary bkFHrH6t0oIHzywZE8E_" type="primary" size="small">{{ $t('yuebao._Receber') }}</van-button>
				</div>
			</div>
		</div>
		<van-tabs v-model="activeName" @click="routerFun" :lineWidth="(activeLength*6+'px')" animated line-height="2px"
			class="ss-van-tab" background="var(--cms-background-color-primary)" :ellipsis="false"
			title-active-color="var(--cms-primary-color)"
			title-inactive-color="var(--cms-text-color-primary)"
			color="var(--cms-primary-color)"
			>

			<van-tab title-style="font-size: 0.22rem;" style="margin-bottom: 1.2rem;" v-for="(item,index) in tabList" :title="$t(item.name)"
				:name="index" :key="index">
				<div class="mHlQTGotPEK_5f9LYDfx" v-if="tabList[activeName].name=='popup._Detalhesdoregistro'">
					<div class="gPcijFU0IRrnGWDIffcn">
						<div class="xt8diCxLPJPPG8DQq5Yg van-dropdown-menu-box">
							<ant-select :value="activeName" :options="actions"
								>
							</ant-select>
							<ant-select style="margin-left: .2rem;" :value="activeName1" :options="actions1"
								>
							</ant-select>
							<!-- <van-dropdown-menu>
								<van-dropdown-item v-model="activeName" :options="actions" />
							</van-dropdown-menu> -->
							<!-- <van-dropdown-menu>
								<van-dropdown-item v-model="activeName1" :options="actions1" />
							</van-dropdown-menu> -->
						</div>
						<div class="VvaPhypYYq8uhP316mGm">
							<div>{{ $t('yuebao._RendaAcumulada0') }}</div>
							<span class="currencyAmount"> 0,00</span>
						</div>
					</div>
					<!-- 
					_Horas:"Horas",_Tipos:"Tipos",_Quantia:"Quantia",
					
					-->
					<van-row class="tenConter _border" style="background: var(--cms-background-color-primary) !important;">
					    <van-col class="tr" span="8">{{ $t('yuebao._Horas') }}</van-col>
						<van-col class="tr" span="8">{{ $t('yuebao._Tipos') }}</van-col>
						<van-col class="tr" span="8">{{ $t('yuebao._Quantia') }}</van-col>
					</van-row>
					<div style="height: 5rem;display: flex;align-items: center;justify-content: center;">
						<van-empty
						    image-size="2.1rem"
						    class="custom-image"
						    :image="img_none_sj"
						    :description="$t('description')"
						/>
					</div>
					
				</div>
				<div class="ZqKoc003Qi4Madfyrmji" v-if="tabList[activeName].name=='popup._Regrasdejuros'">
					<div class="SH1wTnZeFa_E0XnrYVH6">
						<div>1.<strong>Introdução aos ganhos:</strong> O valor depositado no tesouro de juros deve
							cumprir um ciclo completo para gerar juros. Se for sacado antes, o ciclo não contará para os
							lucros. Por exemplo, se o período atual de liquidação for de 1 horas, o valor transferido em
							2023-01-01 00:00:01 gerará o primeiro ciclo de juros em 2023-01-01 01:00:01;</div>
						<div>2.<strong>Ciclo de Liquidação:</strong> O período atual de liquidação de juros é de 1
							horas;</div>
						<div>3.<strong> Taxa de juros anual: </strong> A taxa de juros anual atual é100%;</div>
						<div>4.<strong>Fórmula de cálculo:</strong> Lucro dos juros = valor do depósito * taxa de juros
							anual / período de liquidação;</div>
						<div>5.<strong>Exemplo:</strong> Um depósito de 10.000 em 01/01/2023 às 00:00:01, com a taxa de
							juros anual de 100%, e o período de liquidação de 1 horas, tem como seu primeiro rendimento
							de juros obtido 2023-01-01 01:00:01. O cálculo é o seguinte:<strong>Primeiro rendimento de
								juros = 10.000*100%/365 dias/24 horas*1 horas = 1.141552</strong>;</div>
						<div>6.<strong>Limiar de entrada:</strong> O valor de cada transferência deve ser maior ou igual
							a 50 (ou seja, ≥ 50), sem limite superior no valor da transferência. Quanto mais você
							transfere, maior o lucro;</div>
						<div>7.<strong>Limite de juros:</strong> Não há limite para a taxa de juros atual. Lembre-se de
							cobrar sua renda regularmente ou com frequência para evitar perder mais renda;</div>
						<div>8.<strong>Tempo de reivindicação:</strong> Pode resgatar no próximo dia, o que significa
							que os juros gerados no mesmo dia podem ser reivindicados após 00:00 do próximo dia.</div>
						<div>9.<strong>Múltiplo de auditoria:</strong> O múltiplo de auditoria atual é 30 vezes
							(requisito de volume de apostas). Isso significa que os juros recebidos devem ser apostados
							antes do saque. Aposta.Plataforma válida ilimitadaApenas os juros recebidos requerem
							auditoria, enquanto o valor principal transferido para dentro ou para fora não requer
							auditoria;</div>
						<div>10.<strong>Declaração do evento: </strong>Esta função é limitada à conta. Tenho uma apostas
							normal de jogo e é proibido alugar uma conta, sem apostas de risco (para jogos de azar,
							emparelhamento, baixa compensação, escovar água), Arbitragem maliciosa, usando processos de
							plug -in, robôs, robôs utilizam acordos, vulnerabilidades, interfaces,O controle de grupo ou
							outros meios técnicos são proibidos. Uma vez verificado, a plataforma reserva o direito de
							encerrar os logins dos membros, suspender o uso do site pelos membros e confiscar bônus e
							lucros impróprios sem aviso prévio;</div>
						<div>11.<strong>Explicação:</strong> Quando um membro recebe a recompensa Yibao, a plataforma
							presumirá que o membro concorda e cumpre as condições correspondentes e outros regulamentos
							relevantes. Para evitar ambiguidade na compreensão do texto, a plataforma reserva-se a
							final. direito de interpretar este evento;</div>
					</div>
				</div>
			</van-tab>
		</van-tabs>

	</div>
</template>

<script>
	import dropdownMenu from "@/components/dropdown-menu/index.vue"
	export default {
		name: 'yuebao',
		components: {
			dropdownMenu
		},
		data() {
			return {
				showPopover: false,
				showPopover_a:false,
				actions: [],
				activeLength: "20",
				activeName: 0,
				activeName1: 0,
				activeName2: 0,

				tabList: [{
						name: 'popup._Detalhesdoregistro',
						route: "event"
					},
					{
						name: 'popup._Regrasdejuros',
						route: "vip"
					}
				]
			}
		},
		created(){
			// console.log(this.$t('yuebao.actiVCo'),'actiVCo')
			this.actions=this.$t('yuebao.actiVCo');
			this.actions1=this.$t('yuebao.actiVCo1');
		},
		methods: {
			routerFun(e, l) {
				this.activeLength = l.length;
			},
			tabFun(e) {
				this.$store.commit('active', e)
			},
			onConfirm() {
				this.$refs.item.toggle();
			},
			stickyScroLL(e) {

			}
		}
	}
</script>
<style>
	.ss-van-tab .van-tab {
		/* display: block !important; */
		flex: none !important;
	}

	.ss-van-tab .van-tabs__wrap--scrollable {
		border-bottom: .01rem solid var(--theme-color-line);
	}
	.van-popover__content {
	    background-color: var(--theme-main-bg-color) !important;
	    border: .01rem solid var(--theme-color-line)!important;
	    border-radius: .1rem;
	    -webkit-box-shadow: 0 .03rem .09rem 0 rgba(0,0,0,.1);
	    box-shadow: 0 .03rem .09rem 0 rgba(0,0,0,.1);
	    -webkit-box-sizing: border-box;
	    box-sizing: border-box;
	    overflow: hidden
	}
	.van-popover__action {
	    font-size: .24rem;
	    line-height: .8rem;
	    min-height: .8rem;
		    background-color: var(--theme-main-bg-color);
		 color: var(--theme-text-color-lighten);
	    padding: 0 .2rem;
		box-sizing: border-box;
		width: 114px;
	}
	.van-popover__action-text {
	    display: -webkit-box;
	    display: -webkit-flex;
	    display: flex;
	    -webkit-box-flex: initial;
	    -webkit-flex: initial;
		
	    /* flex: 1; */
	    -webkit-box-align: center;
	    -webkit-align-items: center;
	    align-items: center;
	    -webkit-box-pack: center;
	    -webkit-justify-content: center;
	    justify-content: center;
	    height: 100%;
	}
</style>
<style scoped>
	.s2WtfjR20Zysa1AkgmCv {
		background-color: var(--cms-background-color-primary);
		border-radius: 0;
		padding: .4rem .2rem .2rem
	}

	.s2WtfjR20Zysa1AkgmCv .kKiKuxvcOfS5NE0lGt0O {
		margin-bottom: .1rem;
	}

	.ant-col {
		min-height: 1px;
		position: relative
	}

	.s2WtfjR20Zysa1AkgmCv .rECgrZMnzlEqXUtHytGH {
		-ms-flex-align: center;
		align-items: center;
		display: -ms-flexbox;
		display: flex;
	}

	.s2WtfjR20Zysa1AkgmCv .rECgrZMnzlEqXUtHytGH .hhXnTQYFZIZepnDoixKF {
		color: var(--cms-text-color-secondary);
		font-size: .2rem;
		line-height: 1.45;
	}

	.s2WtfjR20Zysa1AkgmCv .rECgrZMnzlEqXUtHytGH .bBn1FFHLuQzUvNB2ReF5 {
		color: var(--cms-color-help);
		font-size: .3rem;
		font-weight: 700;
		display: block;
		position: relative;
		height: .43rem;
	}

	.uh6csli2MHzFz87__Phx {
		height: 0.32rem;
		left: var(--cu-left);
		position: absolute;
		right: var(--cu-right);
		top: var(--cu-top);
		width: fit-content;
		z-index: 100;
	}

	.sNgyWmhVEZVrbSGKDBra {
		background-color: var(--theme-secondary-color-error);
		border-radius: 0.125rem 0.125rem 0.125rem 0px;
		color: rgb(255, 255, 255);
		font-size: 0.14rem;
		height: 0.22rem;
		line-height: 0.22rem;
		padding: 0px 0.08rem;
		width: 100%;
		margin: 0;
	}

	.s2WtfjR20Zysa1AkgmCv .UbHoywlSqBjwlCYEOA2Z p:first-child {
		background-color: var(--cms-color-help) !important
	}

	.uh6csli2MHzFz87__Phx .sNgyWmhVEZVrbSGKDBra {
		font-size: 0.18rem;
		height: 0.26rem;
		line-height: 0.26rem;
		padding: 0px 0.07rem;
	}

	.uh6csli2MHzFz87__Phx .YiNQnpSJUes7u5sqBZhp {
		border-bottom: 0.07rem solid transparent;
		border-left: .07rem solid var(--theme-secondary-color-finance);
		border-right: 0.07rem solid transparent;
		height: 0px;
		width: 0px;
	}

	.uh6csli2MHzFz87__Phx p {
		margin-bottom: 0px;
	}

	.s2WtfjR20Zysa1AkgmCv .UbHoywlSqBjwlCYEOA2Z p:nth-child(2) {
		border-left-color: var(--theme-secondary-color-finance) !important;
	}

	.s2WtfjR20Zysa1AkgmCv .kKiKuxvcOfS5NE0lGt0O span:first-child {
		margin-right: .1rem;
	}

	.s2WtfjR20Zysa1AkgmCv .zr8gSibXFpfAbJRSuBde {
		color: var(--theme-secondary-color-help-text-color);
		font-size: .14rem;
		font-weight: 400;
		white-space: nowrap;
	}

	.s2WtfjR20Zysa1AkgmCv .xHchAD3DtxThyMmPsugl {
		display: -ms-flexbox;
		position: relative;
		display: flex
	}

	.ant-row-flex {
		display: flex;
		justify-content: center;
		flex-flow: row wrap;
		align-items: center;
		justify-content: space-between;
	}

	.s2WtfjR20Zysa1AkgmCv .ant-btn-a {
		font-size: .2rem;
		height: auto;
		min-height: .5rem;
		padding: 0 .05rem;
		width: 1.2rem;
		word-break: break-word;
		border-radius: 0.14rem;
	}

	.s2WtfjR20Zysa1AkgmCv .ant-col .ant-btn-a.ant-btn-a-finance {
		    background: var(--cms-primary-color);
		    color: var(--cms-theme-text-color-primary);
	}

	.s2WtfjR20Zysa1AkgmCv .ant-col .ant-btn-a:nth-child(2) {
		margin-left: .2rem
	}

	.ant-btn-a:not(.ant-btn-a-link) {
		background-color: var(--theme-disabled-bg-color);
		border-color: var(--theme-disabled-bg-color);
		color: var(--theme-disabled-font-color);
	}

	.s2WtfjR20Zysa1AkgmCv .vqS1TIVgbtEtk8NhF4Jg {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-direction: column;
		flex-direction: column
	}

	.s2WtfjR20Zysa1AkgmCv .CDVYKswyz5bbkfypq6Il>span {
		white-space: nowrap
	}

	.s2WtfjR20Zysa1AkgmCv .hhXnTQYFZIZepnDoixKF {
		color: var(--cms-text-color-secondary);
		font-size: .2rem;
		line-height: 1.45
	}

	.s2WtfjR20Zysa1AkgmCv .ImxaNnYH2DdgyIGT_2jJ {
		-ms-flex-align: center;
		align-items: center;
		display: -ms-flexbox;
		display: flex
	}

	.s2WtfjR20Zysa1AkgmCv .QA313RmuYYmXNHa9Jn7z {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-direction: column;
		flex-direction: column
	}

	.s2WtfjR20Zysa1AkgmCv .kKiKuxvcOfS5NE0lGt0O span:first-child {
		margin-right: .1rem
	}

	.nt3HkYGHVSEvaGFFn12x,
	html[data-device="mobile"] .nt3HkYGHVSEvaGFFn12x .currencyAmount {
		font-size: 0.2rem;
	}

	.s2WtfjR20Zysa1AkgmCv .ant-col>div>span:first-child {
		display: inline-block;
		margin-right: .1rem;
		max-width: none;
		white-space: pre-wrap
	}

	.currencyAmount.yellowColor {
		color: var(--cms-color-help) !important;
	}

	.s2WtfjR20Zysa1AkgmCv .oDoeDvd2HKAzribulwKG span:first-child {
		color: var(--cms-text-color-secondary);
		font-size: .2rem;
		line-height: 1.45;
		margin-right: .1rem;
	}

	.s2WtfjR20Zysa1AkgmCv .oDoeDvd2HKAzribulwKG {
		-ms-flex-pack: end;
		display: -ms-flexbox;
		display: flex;
		justify-content: flex-end;
		margin-right: 0 !important;
	}

	.F8Q09dDeyyZAK7Do5pkF div>span {
		display: flex;
		-ms-flex-align: end;
		align-items: flex-end;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.SH1wTnZeFa_E0XnrYVH6 {
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		font-size: .2rem;
		margin-top: 0;
		padding: .2rem;
	}

	.SH1wTnZeFa_E0XnrYVH6 {
		background-color: var(--theme-main-bg-color);
		border: .01rem solid var(--theme-color-line);
		border-radius: .1rem;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		margin-top: .1rem;
		min-height: 2rem;
		color: var(--theme-text-color);
		padding: .3rem;
	}

	.SH1wTnZeFa_E0XnrYVH6 :first-child {
		font-size: .22rem
	}

	.SH1wTnZeFa_E0XnrYVH6 :first-child {
		font-size: .22rem;
	}

	.ZqKoc003Qi4Madfyrmji {
		padding: .2rem;
		padding-top: .1rem;
	}

	.mHlQTGotPEK_5f9LYDfx {
		padding: 0 .2rem
	}

	.myDateRadio {
		border: .01rem solid var(--theme-color-line);
		background-color: var(--theme-main-bg-color);
		font-size: .2rem;
		height: .5rem !important;
		transition: all .3s cubic-bezier(.645, .045, .355, 1);
		border-radius: .5rem;
		line-height: .5rem;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0 11px;
		color: var(--theme-text-color-lighten) !important;
	}
	.myDateRadio_icon{
		color:  var(--theme-text-color-lighten) !important;
		transform: rotate(-90deg);
		width: .2rem;
		height: .2rem;
		transition: all .3s cubic-bezier(.645, .045, .355, 1);
		margin-left: .1rem;
	}
	.myDateRadio_icon.a90{
		transform: rotate(90deg) !important;
	}
	.xt8diCxLPJPPG8DQq5Yg,.gPcijFU0IRrnGWDIffcn{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.mHlQTGotPEK_5f9LYDfx .gPcijFU0IRrnGWDIffcn {
	    -ms-flex-pack: justify;
	    -webkit-box-sizing: border-box;
	    box-sizing: border-box;
	    justify-content: space-between;
	    padding: .2rem 0
	}

	 .gPcijFU0IRrnGWDIffcn .VvaPhypYYq8uhP316mGm {
	    -ms-flex-align: end;
	    align-items: flex-end;
		display: flex;
		    color: var(--cms-text-color-secondary);
	    font-size: .22rem;
	}
	.tenConter{
		border-radius: .1rem;
		height: .8rem !important;
	}
</style>
