<template>
	<div class="_ScTsE6Lu_yRfh75UsiJ ifzwCQu8BPs2c24nZdzQ">
		<div class="UeGcUDomFlcc2mBQ0XIy" @click="isCionter=true">
			<div class="s2JSdFgwf1ZYcbOS284g">
				<div><input placeholder="Data de Início"  :value="formattedDate" type="text" readonly="true" class="ant-input"></div>
			</div>
			
			<div class="PHXUb7fPfE2exetcWFTQ">
				<div><input placeholder="Data de Fim" type="text" :value="formatDate" readonly="true" class="ant-input"></div>
			</div>
		</div>
		<div class="dHVndEqlSzH6dxzVJAfg" v-if="isCionter">
			<div class="C4HgC78kDj8JtwFFTbzU">
				<div class="dM5y3iUOFSTT_o2fPw1M">
					<h5 class="_ba9Zt3JmFr3Awh14hea">Data de Início</h5>
					<van-datetime-picker 
						v-model="currentDate" 
						type="date" 
						item-height="33.28px"
						@change="timeOnFun"
						@confirm="timeOnFun"
						visible-item-count='5'
						:show-toolbar="false"
						title="选择年月日" :min-date="minDate"
						:max-date="maxDate" />
					<button type="button" @click="isCionter=false" class="ant-btn ant-btn-link aH6Xr2ypMHyKQQj1H5X9"><span>Cancelar</span></button>
				</div>
				<div class="mUKBqGtLNrKhQnPmCAAd"></div>
				<div class="zRd6ojknTKJE6SLBKAAw">
					<h5 class="_ba9Zt3JmFr3Awh14hea">Data de Fim</h5>
					<van-datetime-picker
						v-model="currentEndTime" 
						type="date" 
						@change="_getChangeEndTime"
						@confirm="_getChangeEndTime"
						item-height="33.28px"
						visible-item-count='5'
						:show-toolbar="false"
						title="选择年月日" :min-date="minEndTime"
						:max-date="maxEndTime" />
						<button type="button" @click="_timeOnFunEndDate" class="ant-btn ant-btn-link wFT0U9ao9FktEp8ucjMf"><span>Confimar</span></button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Icon',
		props: {
			name: String,
			active: {
				type: [String],
				required: 'home',
			},
		},
		data() {
			return {
				minDate: new Date(2024, 0, 1),
				maxDate:  new Date(),
				currentDate: new Date(),
				minEndTime:new Date(),
				maxEndTime:new Date(),
				currentEndTime:new Date(),
				// currentDate: new Date(2021, 0, 17),
				isCionter:false,
			};
		},
		methods: {
			timeOnFun(e){
				if(e.confirm){
					e.confirm()
				}else if(e) {
					this.minEndTime=e;
					// console.log(e)
					setTimeout(()=>{
						this.currentEndTime=e;
					},100)
				}
			},
			_getChangeEndTime(e){
				// console.log(this.currentEndTime)
				// console.log(this.currentDate)
				
			},
			_formattedDate(Time) {
			  // 转换日期对象为 'YYYY-MM-DD HH:mm:ss' 格式
			  const options = { year: 'numeric', month: '2-digit', day: '2-digit'};
			  return Time.toLocaleDateString(undefined, options);
			},
			_timeOnFunEndDate(){
				this.isCionter=false;
				this.$emit('_timeOnFunEndDate',this._formattedDate(this.currentDate),this._formattedDate(this.currentEndTime))
			}
		},
		computed: {
			
			iconName() {
				return `${this.prefix}${this.name}`
			},
			formattedDate() {
			  // 转换日期对象为 'YYYY-MM-DD HH:mm:ss' 格式
			  const options = { year: 'numeric', month: '2-digit', day: '2-digit'};
			  return this.currentDate.toLocaleDateString(undefined, options);
			},
			formatDate(){
				const options = { year: 'numeric', month: '2-digit', day: '2-digit'};
				return this.currentEndTime.toLocaleDateString(undefined, options);
			}
		}
	}
</script>
<style>
	.van-picker.van-datetime-picker {
	    background: none;
	    margin: .25rem 0;
	    width: 40vw
	}
	
	.van-picker__mask {
	    background: none!important
	}
	
	.van-hairline-unset--top-bottom:after {
	    border-bottom: .01rem solid var(--cms-divider-color)!important;
	    border-top: .01rem solid var(--cms-divider-color)!important
	}
	
	.van-picker-column__item {
	    color: var(--cms-text-color-secondary)!important
	}
	
	.van-picker-column__item.van-picker-column__item--selected {
	    color: var(--cms-text-color-primary)!important
	}
	.van-ellipsis {
	    font-size: .24rem
	}
	
</style>
<style scoped>
	._ScTsE6Lu_yRfh75UsiJ {
		position: relative;
		z-index: 999
	}

	._ScTsE6Lu_yRfh75UsiJ .dHVndEqlSzH6dxzVJAfg {
		left: 0;
		position: absolute;
		right: 0;
		top: .52rem;
		width: 7rem
	}

	._ScTsE6Lu_yRfh75UsiJ .dHVndEqlSzH6dxzVJAfg .C4HgC78kDj8JtwFFTbzU {
		-ms-flex-align: center;
		-ms-flex-pack: center;
		align-items: center;
		background-color: var(--cms-background-color-primary);
		    border: .01rem solid var(--cms-divider-color);
		border-radius: .14rem;
		-webkit-box-shadow: 0 .04rem .12rem 0 rgba(0, 0, 0, .1);
		box-shadow: 0 .04rem .12rem 0 rgba(0, 0, 0, .1);
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		display: -ms-flexbox;
		display: flex;
		height: 4.8rem;
		justify-content: center;
		padding: .2rem;
		width: 100%;
		box-sizing: border-box;
	}

	._ScTsE6Lu_yRfh75UsiJ .dHVndEqlSzH6dxzVJAfg .C4HgC78kDj8JtwFFTbzU .dM5y3iUOFSTT_o2fPw1M,
	._ScTsE6Lu_yRfh75UsiJ .dHVndEqlSzH6dxzVJAfg .C4HgC78kDj8JtwFFTbzU .zRd6ojknTKJE6SLBKAAw {
		-ms-flex-align: center;
		align-items: center;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-direction: column;
		flex-direction: column;
		width: 50%
	}

	._ScTsE6Lu_yRfh75UsiJ .dHVndEqlSzH6dxzVJAfg .C4HgC78kDj8JtwFFTbzU .dM5y3iUOFSTT_o2fPw1M .van-picker.van-datetime-picker,
	._ScTsE6Lu_yRfh75UsiJ .dHVndEqlSzH6dxzVJAfg .C4HgC78kDj8JtwFFTbzU .zRd6ojknTKJE6SLBKAAw .van-picker.van-datetime-picker {
		width: 100%
	}
	.ant-btn-link {
	    background-color: transparent;
	    border-color: transparent;
	    -webkit-box-shadow: none;
	    box-shadow: none;
	    color: var(--theme-ant-primary-color-0);
	}
	._ScTsE6Lu_yRfh75UsiJ .dHVndEqlSzH6dxzVJAfg .C4HgC78kDj8JtwFFTbzU .wFT0U9ao9FktEp8ucjMf {
	    background-color: var(--cms-primary-color);
	        color: var(--cms-theme-text-color-primary)
	}
	._ScTsE6Lu_yRfh75UsiJ .dHVndEqlSzH6dxzVJAfg .C4HgC78kDj8JtwFFTbzU .aH6Xr2ypMHyKQQj1H5X9,
	._ScTsE6Lu_yRfh75UsiJ .dHVndEqlSzH6dxzVJAfg .C4HgC78kDj8JtwFFTbzU .wFT0U9ao9FktEp8ucjMf {
		border: .01rem solid var(--cms-primary-color);
		font-size: .2rem;
		height: .5rem;
		line-height: .24rem;
		width: 1.2rem;
		border-radius: .1rem;
	}

	._ScTsE6Lu_yRfh75UsiJ .dHVndEqlSzH6dxzVJAfg .C4HgC78kDj8JtwFFTbzU .aH6Xr2ypMHyKQQj1H5X9>span,
	._ScTsE6Lu_yRfh75UsiJ .dHVndEqlSzH6dxzVJAfg .C4HgC78kDj8JtwFFTbzU .wFT0U9ao9FktEp8ucjMf>span {
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		display: -webkit-box;
		overflow: hidden;
		text-overflow: ellipsis;
		vertical-align: middle;
		white-space: pre-line
	}

	._ScTsE6Lu_yRfh75UsiJ .dHVndEqlSzH6dxzVJAfg .C4HgC78kDj8JtwFFTbzU .aH6Xr2ypMHyKQQj1H5X9 {
		color: var(--cms-primary-color)
	}

	._ScTsE6Lu_yRfh75UsiJ .dHVndEqlSzH6dxzVJAfg .C4HgC78kDj8JtwFFTbzU .wFT0U9ao9FktEp8ucjMf {
		background-color: var(--cms-primary-color);
		    color: var(--cms-theme-text-color-primary)
	}

	._ScTsE6Lu_yRfh75UsiJ .dHVndEqlSzH6dxzVJAfg .C4HgC78kDj8JtwFFTbzU .mUKBqGtLNrKhQnPmCAAd {
		border-left: .01rem solid var(--cms-divider-color);
		height: 6.2rem;
		margin: 0 .3rem;
		-webkit-transform: scale(.5);
		transform: scale(.5);
		-webkit-transform-origin: center;
		transform-origin: center;
		width: .01rem
	}


	.UeGcUDomFlcc2mBQ0XIy {
		-ms-flex-align: center;
		-ms-flex-pack: justify;
		align-items: center;
		background-color: var(--cms-background-color-primary);
		border: .01rem solid var(--cms-divider-color);
		border-radius: .25rem;
		display: -ms-flexbox;
		display: flex;
		font-size: .16rem;
		height: .5rem;
		justify-content: space-between;
		overflow: hidden;
		padding: 0 .15rem;
		position: relative;
		box-sizing: border-box;
		width: 3.2rem
	}

	.UeGcUDomFlcc2mBQ0XIy:focus {
		border-color: var(--theme-ant-primary-color-13)
	}

	.UeGcUDomFlcc2mBQ0XIy .FVe48XMI300J_ZlunRwx {
		color: var(--theme-text-color-lighten);
		font-size: .18rem;
		position: absolute;
		right: .12rem;
		top: 50%;
		-webkit-transform: translateY(-50%) rotate(-90deg);
		transform: translateY(-50%) rotate(-90deg)
	}

	.UeGcUDomFlcc2mBQ0XIy:after {
		background-color: var(--theme-text-color-lighten);
		content: "";
		height: .03rem;
		left: 50%;
		position: absolute;
		top: 50%;
		-webkit-transform: var(--theme-color-line);
		transform: var(--theme-color-line);
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		width: .11rem;
		z-index: 1
	}

	.UeGcUDomFlcc2mBQ0XIy .PHXUb7fPfE2exetcWFTQ,
	.UeGcUDomFlcc2mBQ0XIy .s2JSdFgwf1ZYcbOS284g {
		-ms-flex-align: center;
		align-items: center;
		display: -ms-flexbox;
		display: flex;
		height: .5rem;
		width: 1.4rem
	}

	.UeGcUDomFlcc2mBQ0XIy .PHXUb7fPfE2exetcWFTQ .ant-input,
	.UeGcUDomFlcc2mBQ0XIy .s2JSdFgwf1ZYcbOS284g .ant-input {
		padding: .04rem;
		text-align: center
	}

	.UeGcUDomFlcc2mBQ0XIy .PHXUb7fPfE2exetcWFTQ.i9u0_xTNP2sBcviCWGhA:after,
	.UeGcUDomFlcc2mBQ0XIy .s2JSdFgwf1ZYcbOS284g.i9u0_xTNP2sBcviCWGhA:after {
		background-color: var(--theme-ant-primary-color-13);
		bottom: 0;
		content: "";
		height: .03rem;
		left: 50%;
		position: absolute;
		-webkit-transform: translateX(-50%);
		transform: translateX(-50%);
		width: 1.1rem;
		z-index: 1
	}

	.UeGcUDomFlcc2mBQ0XIy .s2JSdFgwf1ZYcbOS284g .ant-input {
		padding-left: .2rem;
	}

	.UeGcUDomFlcc2mBQ0XIy .PHXUb7fPfE2exetcWFTQ .ant-input {
		padding-right: .2rem
	}

	.UeGcUDomFlcc2mBQ0XIy .ant-calendar-picker {
		font-size: .16rem
	}

	.UeGcUDomFlcc2mBQ0XIy .ant-calendar-picker-icon {
		display: none !important
	}

	.UeGcUDomFlcc2mBQ0XIy .ant-input {
		border: none
	}

	.UeGcUDomFlcc2mBQ0XIy .ant-input:focus {
		border: none;
		-webkit-box-shadow: none !important;
		box-shadow: none !important
	}

	.UeGcUDomFlcc2mBQ0XIy .ant-calendar-picker-input,
	.datePickerRangePanel .ant-calendar-column-header,
	.datePickerRangePanel .ant-calendar-my-select {
		font-size: .2rem
	}

	.ant-input {
		-webkit-font-feature-settings: "tnum";
		font-feature-settings: "tnum";
		background-color: #fff;
		background-image: none;
		border: 1px solid #d9d9d9;
		border-radius: 4px;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		color: rgba(0, 0, 0, .65);
		display: inline-block;
		font-size: 14px;
		font-variant: tabular-nums;
		height: 32px;
		line-height: 1.5;
		list-style: none;
		margin: 0;
		padding: 4px 11px;
		position: relative;
		-webkit-transition: all .3s;
		transition: all .3s;
		width: 100%
	}

	.ant-input::-moz-placeholder {
		color: #bfbfbf;
		opacity: 1
	}

	.ant-input:-ms-input-placeholder {
		color: #bfbfbf
	}

	.ant-input::-webkit-input-placeholder {
		color: #bfbfbf
	}

	.ant-input:-moz-placeholder-shown {
		text-overflow: ellipsis
	}

	.ant-input:-ms-input-placeholder {
		text-overflow: ellipsis
	}

	.ant-input:placeholder-shown {
		text-overflow: ellipsis
	}

	.ant-input:hover {
		border-color: var(--theme-ant-primary-color-13);
		border-right-width: 1px !important
	}

	.ant-input:focus {
		border-color: var(--theme-ant-primary-color-13);
		border-right-width: 1px !important;
		-webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, .2);
		box-shadow: 0 0 0 2px rgba(24, 144, 255, .2);
		outline: 0
	}

	html .ant-input,
	html .ant-select-selection {
		background-color: var(--cms-background-color-primary);
		border-color: var(--cms-background-color-primary);
		color: var(--cms-text-color-secondary);
		/* color: var(--cms-text-color-secondary)!important;
		    border: .02rem solid var(--cms-divider-color)!important;
		    background-color: var(--cms-background-color-primary)!important; */
	}

	html .ant-input:focus,
	html .ant-select-selection:focus {
		border-color: var(--theme-primary-color)
	}

	html .ant-input:focus,
	html .ant-select-open .ant-select-selection,
	html .ant-select-selection:focus {
		-webkit-box-shadow: 0 0 0 .02rem rgba(var(--theme-primay-color-hex), .2) !important;
		box-shadow: 0 0 0 .02rem rgba(var(--theme-primay-color-hex), .2) !important
	}

	.UeGcUDomFlcc2mBQ0XIy .PHXUb7fPfE2exetcWFTQ .ant-input,
	.UeGcUDomFlcc2mBQ0XIy .s2JSdFgwf1ZYcbOS284g .ant-input {
		font-size: .2rem;
		height: .5rem;
	}

	html .ant-input-search-icon {
		color: var(--theme-search-icon-color)
	}

	html .ant-input::-webkit-input-placeholder {
		color: var(--cms-text-color-secondary)
	}

	html .ant-input::-moz-placeholder {
		color: var(--cms-text-color-secondary)
	}

	html .ant-input:-ms-input-placeholder {
		color: var(--cms-text-color-secondary)
	}

	html .ant-input::-moz-selection {
		background: var(--theme-primary-color);
		color: var(--theme-primary-font-color)
	}

	html .ant-input::selection {
		background: var(--theme-primary-color);
		color: var(--theme-primary-font-color)
	}

	html .ant-input::-webkit-input-safebox-button {
		display: none
	}
	.dHVndEqlSzH6dxzVJAfg ._ba9Zt3JmFr3Awh14hea {
	    color: var(--cms-text-color-secondary);
	    font-size: .24rem;
	    font-weight: 700;
	    margin-bottom: 0;
		margin-top: 0px;
	}
	

</style>
