


<template>
	<div>
		<ul class="MEMM3gv4dMzqdqtxsb0s" v-if="notificationCusn.length">
			<li class="theme-box-shadow-h5" @click="tabIntdex=index" v-for="(item,index) in notificationCusn" v-if="tabIntdex=='none'">
				<div class="icon" :style="`width: 0.5rem; height: 0.4375rem; background-image: url(${_coloImg}); background-position: -5.3375rem -3.8rem; background-size: 9.075rem 8.85625rem;`"
	
	
	></div>
				<div class="conter">
					<div class="title" style="color: var(--cms-text-color-primary);    font-size: .24rem !important;">
						{{item.title}}
					</div>
					<div class="suntIme" style="color: var(--cms-text-color-secondary);    font-size: .22rem !important;">
						{{item.update_time}}
					</div>
				</div>
				<div style="display: flex;color: var(--cms-text-color-primary);    font-size: .22rem !important;">
					<span style="margin-right: .1rem;">Lidos</span>
					<svg-icon className="imageWrap" style="color: var(--cms-text-color-secondary);" icon-class="comm_icon_fh--svgSprite:all" />
				</div>
			</li>
			<div  @click="tabIntdex='none'">
				<p v-if="notificationCusn[tabIntdex]" @click="tabIntdex=='none'" v-html="notificationCusn[tabIntdex].content" style="font-size: .24rem;color: var(--theme-text-color-darken); "></p>
			</div>
		</ul>
		<van-empty
		    image-size="2.1rem"
		    class="custom-image"
		    :image="img_none_sj"
		    :description="$t('notice._SemMensagem')"
		/>
	</div>
</template>

<script>
	import {
		mapState
	} from 'vuex';
	import {
		baseConfig,
		popAndBanner,
		notification,
		jackpot,
		userMessage,
		getAllGameCategory,
		getDataByGameCategory
	} from "@/apis/modules/home.js"
	export default{
		computed: {
			...mapState(['popList','tabNav','banner','sidebarsList','marquee'])
		},
		data() {
			return{
				tabIntdex:'none',
				notificationCusn:[],
			}
		},
		created() {
			this.getNotification()
		},
		methods:{
			
			getNotification() {
				userMessage({}).then(({
					code,
					data,
					msg
				}) => { //404
					this.notificationCusn=data;
				})
			},
		}
	}
</script>

<style scoped>
	.MEMM3gv4dMzqdqtxsb0s {
	    background-color: transparent;
	    height: calc(100vh - 1.8rem);
	    height: calc(var(--vh, 1vh)*100 - 1.8rem);
	    margin: 0;
	    overflow: scroll;
		box-sizing: border-box;
	    padding-bottom: 1rem
	}
	.MEMM3gv4dMzqdqtxsb0s {
	    border-radius: .1rem;
	    margin-top: .2rem;
	    overflow: auto;
	    padding: .2rem  .2rem .2rem;
	    width: 100%
	}
	.MEMM3gv4dMzqdqtxsb0s li{
		display: flex;
		align-items: center;
	}
	.MEMM3gv4dMzqdqtxsb0s li {
	    height: auto;
	    min-height: 1.2rem
	}
.MEMM3gv4dMzqdqtxsb0s li {
    -ms-flex-align: center;
    align-items: center;
    background-color: var(--cms-background-color-primary);
    border-radius: .1rem;
    -webkit-box-shadow: 0 .03rem .09rem 0 rgba(0,0,0,.06);
    box-shadow: 0 .03rem .09rem 0 rgba(0,0,0,.06);
    height: 1rem;
    margin-bottom: .2rem;
	padding: .25rem .2rem;
	box-sizing: border-box;
	    width: 7.1rem;
    overflow: hidden;
    position: relative;
    width: 100%
}
.MEMM3gv4dMzqdqtxsb0s li .icon{
	display: inline-block;
	    position: relative;
	    width: 0.44rem;
	    height: 0.385rem;
		margin-right: .16rem;
	    background-position: -4.697rem -2.574rem;
	    background-size: 7.986rem 7.7935rem;
}
.MEMM3gv4dMzqdqtxsb0s .conter {
	    line-height: .4rem;
	    max-width: 5.8rem;
		width: 5.8rem;
}
.MEMM3gv4dMzqdqtxsb0s .imageWrap{
	    color: var(--cms-text-color-secondary);
	    -webkit-transform: rotate(180deg);
	    transform: rotate(180deg);
		width: .2rem;
		height: .2rem;
}
</style>
