<template>
	<div>
		<div class="promote">
			<van-nav-bar title="Faça login no dispositivo" left-arrow fixed placeholder @click-left="onClickLeft" />
		</div>

		<div class="ant-spin-container"  style="padding: .2rem;">
			<div class="my-scrollbar my-scrollbar-hover deviceScroll"
				style="width: 100%; height: 100%;">
				<div class="my-scrollbar-wrap my-scrollbar-wrap-y my-scrollbar-wrap-use-systembar">
					<div class="my-scrollbar-content">
						<div class="_d4b6OdOsdJiCQbvYYD_ theme-box-shadow">
							<div class="lrOYjqTLFGp1nCtIK8Vb"><img
									data-groups-id="my-img-8eb73b71-318b-4b46-95f0-40763c1e8b66"
									src="https://cdntoos.amei777.im/siteadmin/upload/img/1789758729715339266.png"
									alt="." data-blur="0" data-status=""
									class="goDRiiBsuEuXD3W1NphN II_5ZM4k4hTlrmJknbDi customLogo NhJhQV_fOIQZIzxUybVr">
								<div class="RoPHjIyZ0T1cuCYXae8t">
									<p><span>ID:</span>856142940</p>
									<p><span>Conta:</span>112233</p>
								</div>
							</div>
							<div class="bor_kpckGyY4C5nzu0Dt">
								<p class="o7fBChMyoPJTt3ZBRpaF">Dispositivo atual</p>
								<div class="TZwaeQqhJKdCbFR1jeuo">
									<div class="bpeN36_KmticuSheGP0t"><i class=""
											style="display: inline-flex; justify-content: center; align-items: center;"><svg
												width="1em" height="1em" fill="currentColor" aria-hidden="true"
												focusable="false" viewBox="0 0 13.792 23">
												<path id="icon_dl_sj"
													d="M5622.25,1131.995h-9.2a2.328,2.328,0,0,0-2.3,2.356v18.289a2.328,2.328,0,0,0,2.3,2.355h9.194a2.34,2.34,0,0,0,2.3-2.37V1134.36A2.328,2.328,0,0,0,5622.25,1131.995Zm-4.6,22.183a1.064,1.064,0,1,1,1.038-1.063A1.05,1.05,0,0,1,5617.646,1154.177Zm5-3.868a.7.7,0,0,1-.759.785h-8.516a.764.764,0,0,1-.646-.785v-14.8a.774.774,0,0,1,.767-.785h8.388a.775.775,0,0,1,.767.785Z"
													transform="translate(-5610.75 -1131.995)"></path>

											</svg></i></div>
									<div class="RnfBJCy79WXDUC3k4SBE">
										<div class="jDsS3Qyn3WKqbmXTrvdG">
											<p class="bK9qKU6cPTlAb2whk_1Z">Aplicativo</p>
											<p class="KkzETEXpVXQ9WXqBNM_b">H5</p>
										</div>
										<div class="jDsS3Qyn3WKqbmXTrvdG">
											<p class="bK9qKU6cPTlAb2whk_1Z">Versão</p>
											<p class="KkzETEXpVXQ9WXqBNM_b">v4.0.376</p>
										</div>
										<div class="jDsS3Qyn3WKqbmXTrvdG">
											<p class="bK9qKU6cPTlAb2whk_1Z">Tipo de equipamento</p>
											<p class="KkzETEXpVXQ9WXqBNM_b">Safari604.1</p>
										</div>
										<div class="jDsS3Qyn3WKqbmXTrvdG">
											<p class="bK9qKU6cPTlAb2whk_1Z">Sistema</p>
											<p class="KkzETEXpVXQ9WXqBNM_b">iOS 16.6</p>
										</div>
										<div class="jDsS3Qyn3WKqbmXTrvdG">
											<p class="bK9qKU6cPTlAb2whk_1Z">Área IP</p>
											<p class="KkzETEXpVXQ9WXqBNM_b">223.16.93.187 Hong Kong/Eastern-Kornhill</p>
										</div>
										<div class="jDsS3Qyn3WKqbmXTrvdG">
											<p class="bK9qKU6cPTlAb2whk_1Z">Horário de login</p>
											<p class="KkzETEXpVXQ9WXqBNM_b">UTC+-3 2024-07-16 03:42:08</p>
										</div>
									</div>
								</div>
							</div>
							<div class="rK780bsQBg4hcXL6yIei">
								<p class="o7fBChMyoPJTt3ZBRpaF qUXfl5VpdsciuIsoqMbQ">Outros dispositivos<i tabindex="-1"
										class="anticon lgTMQLEuSORyo6ZiHHdI"><i class=""
											style="display: inline-flex; justify-content: center; align-items: center;"><svg
												width="1em" height="1em" fill="currentColor" aria-hidden="true"
												focusable="false" viewBox="0 0 21.351 24">
												<g id="comm_icon_del" transform="translate(-1946.084 -41.758)">
													<path id="Path_503" data-name="Path 503"
														d="M1966.49,59.525c.1-.085.12-.114.141-.116,1.694-.211,1.694-.21,1.694,1.456q0,5.73,0,11.46c0,2.576-1.283,3.844-3.877,3.844-3.343,0-6.685.007-10.028,0a3.29,3.29,0,0,1-3.528-3.463c-.025-4.3-.007-8.6-.006-12.893,0-.1.019-.207.035-.376h1.8v.85q0,6.049,0,12.1c0,1.486.47,1.954,1.952,1.955q5.014,0,10.027,0c1.277,0,1.787-.492,1.789-1.77q.011-6.128,0-12.256Z"
														transform="translate(-2.844 -10.413)"></path>
													<path id="Path_504" data-name="Path 504"
														d="M1962.479,44.734h3.736c.623,0,1.234.078,1.22.89-.012.767-.567.919-1.217.918q-9.5-.005-19,0c-.632,0-1.139-.163-1.137-.9s.5-.917,1.135-.914c1.24,0,2.479,0,3.791,0,.019-.313.033-.567.05-.821a2.086,2.086,0,0,1,2.251-2.146c2.279-.016,4.558-.007,6.837,0a2.084,2.084,0,0,1,2.331,2.291C1962.484,44.236,1962.479,44.419,1962.479,44.734Zm-9.559-.065h7.689c.149-1.093.123-1.127-.851-1.128q-2.979,0-5.959,0C1952.811,43.541,1952.811,43.543,1952.92,44.669Z">
													</path>
													<path id="Path_505" data-name="Path 505"
														d="M1964.983,67.255c0-1.485,0-2.97,0-4.456,0-.552.143-1.01.74-1.162.641-.164,1.084.264,1.092,1.088.015,1.644.005,3.289.005,4.933,0,1.379.009,2.758,0,4.138-.007.786-.369,1.225-.969,1.185-.743-.05-.86-.587-.861-1.19Q1964.982,69.523,1964.983,67.255Z"
														transform="translate(-11.21 -11.772)"></path>
													<path id="Path_506" data-name="Path 506"
														d="M1977.18,67.343c0,1.484,0,2.968,0,4.453,0,.8-.333,1.236-.932,1.2-.745-.039-.857-.56-.856-1.176.007-3,0-5.99,0-8.985,0-.81.325-1.232.929-1.2.746.038.864.553.859,1.172C1977.173,64.321,1977.18,65.832,1977.18,67.343Z"
														transform="translate(-17.385 -11.792)"></path>
												</g>

											</svg></i></i></p>
								<div class="TZwaeQqhJKdCbFR1jeuo">
									<div class="bpeN36_KmticuSheGP0t"><i class=""
											style="display: inline-flex; justify-content: center; align-items: center;"><svg
												width="1em" height="1em" fill="currentColor" aria-hidden="true"
												focusable="false" viewBox="0 0 13.792 23">
												<path id="icon_dl_sj"
													d="M5622.25,1131.995h-9.2a2.328,2.328,0,0,0-2.3,2.356v18.289a2.328,2.328,0,0,0,2.3,2.355h9.194a2.34,2.34,0,0,0,2.3-2.37V1134.36A2.328,2.328,0,0,0,5622.25,1131.995Zm-4.6,22.183a1.064,1.064,0,1,1,1.038-1.063A1.05,1.05,0,0,1,5617.646,1154.177Zm5-3.868a.7.7,0,0,1-.759.785h-8.516a.764.764,0,0,1-.646-.785v-14.8a.774.774,0,0,1,.767-.785h8.388a.775.775,0,0,1,.767.785Z"
													transform="translate(-5610.75 -1131.995)"></path>

											</svg></i></div>
									<div class="RnfBJCy79WXDUC3k4SBE">
										<div class="jDsS3Qyn3WKqbmXTrvdG">
											<p class="bK9qKU6cPTlAb2whk_1Z">Aplicativo</p>
											<p class="KkzETEXpVXQ9WXqBNM_b">H5</p>
										</div>
										<div class="jDsS3Qyn3WKqbmXTrvdG">
											<p class="bK9qKU6cPTlAb2whk_1Z">Versão</p>
											<p class="KkzETEXpVXQ9WXqBNM_b">v4.0.376</p>
										</div>
										<div class="jDsS3Qyn3WKqbmXTrvdG">
											<p class="bK9qKU6cPTlAb2whk_1Z">Tipo de equipamento</p>
											<p class="KkzETEXpVXQ9WXqBNM_b">Chrome126.0.0.0</p>
										</div>
										<div class="jDsS3Qyn3WKqbmXTrvdG">
											<p class="bK9qKU6cPTlAb2whk_1Z">Sistema</p>
											<p class="KkzETEXpVXQ9WXqBNM_b">Windows 10</p>
										</div>
										<div class="jDsS3Qyn3WKqbmXTrvdG">
											<p class="bK9qKU6cPTlAb2whk_1Z">Área IP</p>
											<p class="KkzETEXpVXQ9WXqBNM_b">223.16.93.187 Hong Kong/Eastern-Kornhill</p>
										</div>
										<div class="jDsS3Qyn3WKqbmXTrvdG">
											<p class="bK9qKU6cPTlAb2whk_1Z">Horário de login</p>
											<p class="KkzETEXpVXQ9WXqBNM_b">UTC+-3 2024-06-23 01:35:13</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	
	</div>
</template>

<script>
	export default {
		components: {
		},
		data() {
			return {
				
				// Pesquisar
			}
		},
		created() {
	
		},
		methods: {
		
			onClickLeft() {
				this.$router.go(-1);
			}
		}
	}
</script>

<style scoped> 
 .lrOYjqTLFGp1nCtIK8Vb {
    -ms-flex-align: center;
    -ms-flex-pack: center;
    align-items: center;
    background-color: var(--theme-main-bg-color);
    border-radius: .14rem;
    -webkit-box-shadow: 0 .03rem .1rem 0 rgba(0,0,0,.06);
    box-shadow: 0 .03rem .1rem 0 rgba(0,0,0,.06);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: center;
    margin-bottom: .2rem;
    padding: .37rem 0 .34rem;
    width: 100%
}

 ._d4b6OdOsdJiCQbvYYD_ .lrOYjqTLFGp1nCtIK8Vb .NhJhQV_fOIQZIzxUybVr {
    height: .66rem;
    -o-object-fit: contain;
    object-fit: contain
}

 ._d4b6OdOsdJiCQbvYYD_ .lrOYjqTLFGp1nCtIK8Vb .RoPHjIyZ0T1cuCYXae8t {
    -ms-flex-align: center;
    -ms-flex-pack: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    margin-top: .36rem
}

 ._d4b6OdOsdJiCQbvYYD_ .lrOYjqTLFGp1nCtIK8Vb .RoPHjIyZ0T1cuCYXae8t p {
    color: var(--theme-text-color-darken);
    font-size: .22rem;
    margin-right: .54rem
}

 ._d4b6OdOsdJiCQbvYYD_ .lrOYjqTLFGp1nCtIK8Vb .RoPHjIyZ0T1cuCYXae8t p:last-child {
    margin-right: 0
}

 ._d4b6OdOsdJiCQbvYYD_ .lrOYjqTLFGp1nCtIK8Vb .RoPHjIyZ0T1cuCYXae8t p span {
    color: var(--theme-text-color-lighten);
    margin-right: .1rem
}
.bor_kpckGyY4C5nzu0Dt, ._d4b6OdOsdJiCQbvYYD_ .rK780bsQBg4hcXL6yIei {
    background-color: var(--theme-main-bg-color);
    border-radius: .14rem;
    -webkit-box-shadow: 0 .03rem .1rem 0 rgba(0,0,0,.06);
    box-shadow: 0 .03rem .1rem 0 rgba(0,0,0,.06);
    margin-bottom: .2rem;
    width: 100%
}
 
 ._d4b6OdOsdJiCQbvYYD_ .bor_kpckGyY4C5nzu0Dt .o7fBChMyoPJTt3ZBRpaF, ._d4b6OdOsdJiCQbvYYD_ .rK780bsQBg4hcXL6yIei .o7fBChMyoPJTt3ZBRpaF {
    -ms-flex-align: center;
    -ms-flex-pack: justify;
    align-items: center;
    color: var(--theme-text-color-darken);
    display: -ms-flexbox;
    display: flex;
    font-size: .24rem;
    justify-content: space-between;
    line-height: 1.8;
    min-height: .9rem;
    padding: 0 .3rem;
    text-align: left;
    width: 100%
}

 ._d4b6OdOsdJiCQbvYYD_ .bor_kpckGyY4C5nzu0Dt .o7fBChMyoPJTt3ZBRpaF.qUXfl5VpdsciuIsoqMbQ, ._d4b6OdOsdJiCQbvYYD_ .rK780bsQBg4hcXL6yIei .o7fBChMyoPJTt3ZBRpaF.qUXfl5VpdsciuIsoqMbQ {
    -ms-flex-align: center;
    -ms-flex-pack: justify;
    align-items: center;
    display: -ms-flexbox;
	box-sizing: border-box;
    display: flex;
    justify-content: space-between
}

 ._d4b6OdOsdJiCQbvYYD_ .bor_kpckGyY4C5nzu0Dt .o7fBChMyoPJTt3ZBRpaF.qUXfl5VpdsciuIsoqMbQ .lgTMQLEuSORyo6ZiHHdI, ._d4b6OdOsdJiCQbvYYD_ .rK780bsQBg4hcXL6yIei .o7fBChMyoPJTt3ZBRpaF.qUXfl5VpdsciuIsoqMbQ .lgTMQLEuSORyo6ZiHHdI {
    color: #ea4d3d;
    font-size: .3rem
}

 ._d4b6OdOsdJiCQbvYYD_ .bor_kpckGyY4C5nzu0Dt .TZwaeQqhJKdCbFR1jeuo, ._d4b6OdOsdJiCQbvYYD_ .rK780bsQBg4hcXL6yIei .TZwaeQqhJKdCbFR1jeuo {
    -ms-flex-align: start;
    -ms-flex-pack: start;
    align-items: flex-start;
    border-top: .01rem solid var(--theme-color-line);
    display: -ms-flexbox;
    display: flex;
    justify-content: flex-start;
	box-sizing: border-box;
    padding: .2rem .2rem .15rem .3rem;
    width: 100%
}

 ._d4b6OdOsdJiCQbvYYD_ .bor_kpckGyY4C5nzu0Dt .TZwaeQqhJKdCbFR1jeuo .bpeN36_KmticuSheGP0t, ._d4b6OdOsdJiCQbvYYD_ .rK780bsQBg4hcXL6yIei .TZwaeQqhJKdCbFR1jeuo .bpeN36_KmticuSheGP0t {
    -ms-flex-align: center;
    -ms-flex-pack: center;
    align-items: center;
    border: .01rem solid var(--theme-color-line);
    border-radius: .14rem;
    color: var(--theme-primary-color);
    display: -ms-flexbox;
    display: flex;
    font-size: .5rem;
    height: .8rem;
    justify-content: center;
    width: .8rem
}

 ._d4b6OdOsdJiCQbvYYD_ .bor_kpckGyY4C5nzu0Dt .TZwaeQqhJKdCbFR1jeuo .RnfBJCy79WXDUC3k4SBE, ._d4b6OdOsdJiCQbvYYD_ .rK780bsQBg4hcXL6yIei .TZwaeQqhJKdCbFR1jeuo .RnfBJCy79WXDUC3k4SBE {
    margin-left: .24rem;
    width: calc(100% - 1.04rem)
}

 ._d4b6OdOsdJiCQbvYYD_ .bor_kpckGyY4C5nzu0Dt .TZwaeQqhJKdCbFR1jeuo .RnfBJCy79WXDUC3k4SBE .jDsS3Qyn3WKqbmXTrvdG, ._d4b6OdOsdJiCQbvYYD_ .rK780bsQBg4hcXL6yIei .TZwaeQqhJKdCbFR1jeuo .RnfBJCy79WXDUC3k4SBE .jDsS3Qyn3WKqbmXTrvdG {
    -ms-flex-align: center;
    -ms-flex-pack: start;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    font-size: .24rem;
    justify-content: flex-start;
    margin-bottom: .25rem;
    width: 100%
}

 ._d4b6OdOsdJiCQbvYYD_ .bor_kpckGyY4C5nzu0Dt .TZwaeQqhJKdCbFR1jeuo .RnfBJCy79WXDUC3k4SBE .jDsS3Qyn3WKqbmXTrvdG .bK9qKU6cPTlAb2whk_1Z, ._d4b6OdOsdJiCQbvYYD_ .rK780bsQBg4hcXL6yIei .TZwaeQqhJKdCbFR1jeuo .RnfBJCy79WXDUC3k4SBE .jDsS3Qyn3WKqbmXTrvdG .bK9qKU6cPTlAb2whk_1Z {
    color: var(--theme-text-color-lighten);
    margin-right: .8rem;
    text-align: right;
    width: 20%
}

 ._d4b6OdOsdJiCQbvYYD_ .bor_kpckGyY4C5nzu0Dt .TZwaeQqhJKdCbFR1jeuo .RnfBJCy79WXDUC3k4SBE .jDsS3Qyn3WKqbmXTrvdG .KkzETEXpVXQ9WXqBNM_b, ._d4b6OdOsdJiCQbvYYD_ .rK780bsQBg4hcXL6yIei .TZwaeQqhJKdCbFR1jeuo .RnfBJCy79WXDUC3k4SBE .jDsS3Qyn3WKqbmXTrvdG .KkzETEXpVXQ9WXqBNM_b {
    color: var(--theme-text-color-darken);
    width: calc(80% - .8rem)
}

</style>
