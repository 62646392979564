<template>
  <div class="maxMaxth evebt" >
    <div class="HstXuR0ZLK9FDspFu8RO">
      <div class="Vlll4UyXTvDB3Ntca3mq">
        <div class="common-tabs-box">
          <ul class="common-tabs-nav P8RuaFULZKF3ME9gfgKl uxYYjiDd7u0QtHMh5yW3" style="width: 1.5rem;">
              <li class="TAg1HOhNrjpbcFzq75mo active bNrPaNsp97Sa9hmzMKZl subItem JOWqBceNWiyZuqdBAXLg sZKC80YroX_W1LCaI5C_ isVertical event-tab-item tab-1 ">
                <svg-icon className="imageWrap"  icon-class="icon_dtfl_zh_0--svgSprite:all"  />
                <div class="vi_TBZvr4AMy0V63Nni7 OYGFKea9dvDSZrAB5rQS text"><span>Misto</span></div>
              </li>
            </ul>
        </div>
        <div class="common-tabs-content" style="padding-bottom:.9rem">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text=""
            @load="onLoad"
          >
            <div class="tanb-List" @click="openUrl(item)" v-for="(item,index) in activitiesList" :key="index">
			   <img v-lazy="item.image" :src="item.image" alt="">
            </div>
          </van-list>
        </div>
        
      </div>
    </div>
   </div>
</template>
<script>
	// 
import {activities} from "@/apis/modules/event.js"
// @ is an alias to /src
import headerhomeEv from "@/components/headerhomeEv/index.vue"
export default {
  name: 'Home',
  components: {
    headerhomeEv
  },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
	  activitiesList:[],
    };
  },
  created() {
  	this.getActivities()
  },
  methods: {
	  getActivities(){
		  activities({}).then(({code,data={},msg})=>{
			  // console.log(code,data,msg)
			  this.activitiesList=data;
		  })
	  },
	openUrl(e){
		console.log(e)
		if(e.jump_url)
		if(e.type=='direct'){
			
			if(e.jump_url && e.jump_url!='#'){
				if(/^http/.test(e.jump_url)){
					window.open(url, '_blank');
					return false;
				}
				this.$router.push({name:e.jump_url})
			}
			// 跳转类型：direct直接跳URL，rich跳页面展示富文本（标题和内容）
		}else{
			this.$router.push({name:'details',query:{
				title:e.title,
				content:e.content,
			}})
		}
	},
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      setTimeout(() => {
        for (let i = 0; i < 10; i++) {
          this.list.push(this.list.length + 1);
        }

        // 加载状态结束
        this.loading = false;

        // 数据全部加载完成
        if (this.list.length >= 40) {
          this.finished = true;
        }
      }, 1000);
    },
  },
};
</script>
<style>
.common-tabs-content .van-list__placeholder,.common-tabs-content .van-list__finished-text{
  width: 5.4rem;
}

.HstXuR0ZLK9FDspFu8RO{
  padding: .2rem .2rem .2rem 0;
}
.HstXuR0ZLK9FDspFu8RO {
    margin-bottom: .2rem;
    min-height: 6rem;
    width: 100%;
}
 .jcpBRfkz3Fd_IR1HhSH8 {
    padding-top: .2rem;
    display: flex;
    height: 100%;
    justify-content: space-between;
    position: relative;
}
.common-tabs-box{
  -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100vh;
    height: calc(var(--vh, 1vh)* 100);
    position: fixed;
    left: auto;
    top: .9rem;
    padding-top: .2rem;
    overflow-y: auto;
    width: 2rem;
}
.jcpBRfkz3Fd_IR1HhSH8 .common-tabs-nav {
    display: block;
    margin-top: .2rem;
    max-height: none;
    padding-bottom: .1rem
}

.TAg1HOhNrjpbcFzq75mo{
  border: initial;
	    background-color: var(--cms-primary-color);
	    color: var(--cms-theme-text-color-primary);
  border-radius: .2rem;
  display: flex;
  justify-content: center;
    padding: 0 .1rem;
    border-radius: .16rem;
    -ms-flex-direction: column;
    align-items: center;
    flex-direction: column;
    height: 1.05rem;
}

.TAg1HOhNrjpbcFzq75mo .imageWrap{
  width: .44rem;
  height: .44rem;
}
.OYGFKea9dvDSZrAB5rQS{
  font-size: .17rem;
}
 .common-tabs-content {
    padding-left: 1.9rem;
}
.tanb-List{
  height: 2.6rem;
  width: 5.4rem;
  border-radius: .1rem;
  box-sizing: border-box;
  padding: .1rem;
  margin-bottom: .2rem;
      border-radius: .1rem;
  background: var(--cms-background-color-primary);
}
.tanb-List img{
  width: 100%;
  border-radius: .1rem;
}
</style>
