<template>
	<div>
		<div class="R57xbU1vEwuho81iaSVT box-shadow">
			<div class="R57xbU1vEwuho81iaSVT-conter">
				<van-row>
					<van-col span="12">
						<datetimePicker  @_timeOnFunEndDate="_timeOnFunEndDate" class="ifzwCQu8BPs2c24nZdzQ"></datetimePicker>
					</van-col>
					<van-col span="12">
						<label>{{$t('promote._RendimentoTotal')}}</label>
						<span class="currencyAmount"> {{shareInfo.all_bet_total}}</span>
					</van-col>
				</van-row>
				<van-row>
					<van-col span="12">
						<label>{{$t('promote._DDireto')}}</label><span class="currencyAmount">{{shareInfo.n1_bet_total}}</span>
					</van-col>
					<van-col span="12">
						<label>{{$t('promote._DdosOutros')}}</label><span class="currencyAmount">{{shareInfo.n2_bet_total}}</span>
					</van-col>
				</van-row>
			</div>
			<div class="TBtGFT5INIot5sEUIm8w vantDataList">
				<van-list class="reverse" v-if="fromList && fromList.length" v-model="loading" @load="onLoad"  :finished="finished">
					<div slot="default" v-for="(item,index) in fromList" :key="index" :title="item">
						<van-row type="flex" justify="space-between">
							<van-col span="12">
								<label>{{$t('promote._DataLiquidação')}}</label>
								<span class="dateTime">{{item.date}}</span>
							</van-col>
							<van-col span="12">
								<label>{{$t('promote._RendimentoTotal')}}</label>
								<span class="currencyAmount">{{item.all_bet_total}}</span>
							</van-col>
						</van-row>
						<van-row type="flex" justify="space-between">
							<!-- {{item}} -->
							
							<van-col span="12">
								<label>{{$t('promote._DDireto')}}</label>
								<span 
								@click="fubCtion(item,1,item.n1_bet_total)"
								:class="{
									active:item.n1_bet_total
								}">
									<span class="currencyAmount" >{{item.n1_bet_total_str}}</span>
								</span>
							</van-col>
							<van-col span="12">
								<label>{{$t('promote._DdosOutros')}}</label>
								<span 
								@click="fubCtion(item,2,item.n2_bet_total)"
								:class="{
									active:item.n2_bet_total
								}">
									<span class="currencyAmount"> {{item.n2_bet_total_str}}</span>
								</span>
							</van-col>
						</van-row>
					</div>
				</van-list>
				<van-empty v-if="!fromList || !fromList.length" image-size="2.1rem" class="custom-image"
					:image="img_none_sj"
					:description="$t('description')" />
			</div>
		</div>
		
	</div>
</template>

<script>
	import datetimePicker from "@/components/searchFor/datetimePicker.vue";
	import searchFor from "@/components/searchFor/searchFor.vue";
	import Direto from "./components/Direto.vue";
	import {
		sharePerformance,
		sharePerformanceDetail
	} from "@/apis/modules/share.js";
	export default {
		name: 'vip',
		components: {
			datetimePicker,
			searchFor,
			Direto
		},
		data() {
			return {
				shareInfo: {},
				fromList:[],
				fromType:1,
				finished: false,
				loading:false,
				formData: {
					
				}
			}
		},
		mounted() {
			this.sharePerformance();
		},
		methods: {
			_timeOnFunEndDate(Tim, anD) {
				this.fromList=[];
				this.finished = false;
				this.formData.start_time = Tim;
				this.formData.end_time = anD;
				this.fromType=1;
				this.sharePerformance();
			},
			onLoad() {
				if(this.finished){
					return false;
				}
				this.fromType=this.fromType+1;
				this.sharePerformance();
			},
			sharePerformance() {
				sharePerformance({
					...this.formData,
					page:this.fromType,
				}).then(({
					data
				}) => {
					let {list}=data;
					if(JSON.stringify(list)=='[]' || list.length<50){
						this.finished = true;
					}
					this.loading=false;
					if(this.fromType==1){
						this.shareInfo = data;
					}
					this.fromList = [...this.fromList,...data.list];
					// this.shareInfo = data;
					// this.fromList=[...this.fromList,...list]
				})
			},
			formatDate(dateName){
			  let date = new Date(dateName);
			  const year = date.getFullYear();
			  const month = (date.getMonth() + 1).toString().padStart(2, '0');
			  const day = date.getDate().toString().padStart(2, '0');
			  return `${year}/${day}/${month}`;
			},
			fubCtion(item,type,bet_total){
				if(bet_total){
					sharePerformanceDetail({
						date:this.formatDate(item.date),
						type:type,
					}).then(({data,bet_total})=>{
						this.$store.commit('publicFun',['isDireto',{
							isDireto:true,
							DetalhesdaAposta:false,
							date:this.formatDate(item.date),
							type:type,
							bet_total,
							...data,
						}]) 
					})
					
				}
				
				// {
				//   "date": "02/08/2024",
				//   "all_bet_total": "167,80",
				//   "n1_bet_total": 127,
				//   "n2_bet_total": 40.8,
				//   "n1_bet_total_str": "127,00",
				//   "n2_bet_total_str": "40,80"
				// } 
			},
			tabIndexTime(index, time) {
				this.shareTime = time;
				this.active = index;
				this.sharePerformance()
			},
		},
	}
</script>
<style>
	.R57xbU1vEwuho81iaSVT-conter .ant-col {
		-ms-flex-align: center;
		align-items: center;
		color: var(--theme-text-color-darken);
		display: -ms-flexbox;
		display: flex;
	}

	.R57xbU1vEwuho81iaSVT-conter .van-row {
		justify-content: center;
		display: flex;
		align-items: center;
	}

	.R57xbU1vEwuho81iaSVT-conter .van-col {
		margin-bottom: .2rem;
	}

	.R57xbU1vEwuho81iaSVT-conter .van-col label {
		color: var(--cms-text-color-secondary);
		margin-right: .1rem;
	}

	.R57xbU1vEwuho81iaSVT-conter .currencyAmount {
		color: var(--cms-text-color-primary);
	}
</style>
<style scoped>
	.ifzwCQu8BPs2c24nZdzQ {
		margin-right: .2rem;
	}

	.R57xbU1vEwuho81iaSVT {
		padding: .2rem;
	}

	.R57xbU1vEwuho81iaSVT-conter {
		background-color: var(--cms-background-color-primary);
		border-radius: .1rem;
		padding: .2rem .2rem 0
	}
	.TBtGFT5INIot5sEUIm8w.vantDataList .van-list>div .van-col label {
	    color: var(--theme-text-color-lighten);
	    margin-right: .1rem
	}
	.TBtGFT5INIot5sEUIm8w.vantDataList .van-list>div .van-col .active {
	    border-bottom: .01rem solid var(--theme-primary-color);
	    color: var(--theme-primary-color)
	}

	.TBtGFT5INIot5sEUIm8w.vantDataList .van-list>div .van-col .dateTime {
	    color: var(--theme-text-color-darken);
	    font-size: .2rem
	}
	.TBtGFT5INIot5sEUIm8w.vantDataList .van-list>div .van-col {
	    color: var(--theme-text-color-darken)
	}
	.TBtGFT5INIot5sEUIm8w.vantDataList .van-list>div .van-row--flex:not(:last-child) {
	    margin-bottom: .2rem;
		font-weight: ;
	}
	

</style>
