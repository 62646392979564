var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ContaBox"},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","width":"100%","overflow":"auto"}},[_c('div',{staticClass:"my-scrollbar",staticStyle:{"margin-bottom":".2rem","overflow-x":"scroll","overflow-y":"hidden"}},[_c('elSelectItem',{attrs:{"options":_vm.optionTim,"value":_vm.timValue},on:{"change":_vm._onChangeTime}}),_c('elSelectItem',{staticStyle:{"margin-left":".2rem"},attrs:{"options":_vm.game_type,"value":_vm.game_typeValue},on:{"change":_vm._onChangeGame_type}}),_c('elSelectItem',{staticStyle:{"margin-left":".2rem"},attrs:{"value":"1","options":[{text:"Todas as Plataformas",value:"1"}]}})],1),_c('div',{staticClass:"Ez1c7PkAs5rYSCRy9eeB",on:{"click":function($event){_vm.arrTime==''?_vm.arrTime='ant':_vm.arrTime=='ant'?_vm.arrTime='lant':_vm.arrTime=''}}},[_vm._m(0),_c('span',{staticClass:"KbIipJmp5a6OfjK58SlT"},[_c('span',{staticClass:"E9MTgrF_INGoXMxpvQiy",class:{
					upSxJysb76gS73lkVnUa:_vm.arrTime=='ant',
				 }}),_c('span',{staticClass:"gBHP20wzU6jCiRFMIdCN",class:{
					_2I4rmrxpydl05x4IMZP:_vm.arrTime=='lant',
				 }})])])]),_c('div',{staticClass:"TBtGFT5INIot5sEUIm8w vantDataList"},[(_vm.shareInfo.list && _vm.shareInfo.list.length)?_c('van-list',{attrs:{"finished":_vm.finished},on:{"load":_vm.onLoad},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},_vm._l((_vm.shareInfo.list),function(item,index){return _c('div',{key:index,staticClass:"yirWkOwey8HB15QLXqmz",attrs:{"title":item}},[_c('div',{staticClass:"E8vjOrxznOAKdRhXJRMX"},[_c('p',[_c('span',[_vm._v(_vm._s(item.game))])]),_c('p',[_c('span',[_vm._v(_vm._s(item.date || item.create_time))])])]),_c('div',{staticClass:"E8vjOrxznOAKdRhXJRMX hm5kVDuxEiHavCx1SBCQ"},[_c('p',[_c('span',[_c('span',{staticClass:"UXsnlJzbtvsfRVKRTLXu"},[_vm._v(_vm._s(item.count))]),_vm._v("/"),_c('span',{staticClass:"UXsnlJzbtvsfRVKRTLXu"},[_vm._v("R$ "+_vm._s(item.bet))]),_vm._v("/"),_c('span',{staticClass:"YgwzG0T3H3PBaUc3A3_s",class:{
									 fABmUWsKxI0hI9mbzoYD:item.gain>0,
									 YgwzG0T3H3PBaUc3A3_s:item.gain<0
								 }},[_vm._v(_vm._s(item.gain<0?'-':'+')+"R$"+_vm._s(item.gain))])])])])])}),0):_vm._e(),(!_vm.shareInfo.list || !_vm.shareInfo.list.length)?_c('van-empty',{staticClass:"custom-image",attrs:{"image-size":"2.1rem","image":_vm.img_none_sj,"description":_vm.$t('description')}}):_vm._e(),_c('div',{staticClass:"waxH0FBvm5SKSHEIdmzj wg-fixed-no-desktop",staticStyle:{"z-index":"9999999999"}},[_c('div',{staticClass:"pBg296B2aLWiyWbbg5An"},[_c('span',{staticClass:"RjCX53sI34q23D2TwPWl"},[_c('span',[_vm._v(_vm._s(_vm.$t("report._Valorcumulativodaaposta")))]),_c('span',{staticClass:"VsQlwP6H52Vyv4bEeq1q HGHvRuWIBhpGiBhkEAHu"},[_vm._v(_vm._s(_vm.shareInfo.all_count))])]),_c('span',{staticClass:"RjCX53sI34q23D2TwPWl"},[_c('span',[_vm._v(_vm._s(_vm.$t("report._TotaldeApostasVálidas")))]),_c('span',{staticClass:"kfrOXvCeC3B_UykAtxE_ HGHvRuWIBhpGiBhkEAHu"},[_vm._v("R$ "+_vm._s(_vm.shareInfo.all_bet))])]),_c('span',{staticClass:"RjCX53sI34q23D2TwPWl"},[_c('span',[_vm._v(_vm._s(_vm.$t("report._TotaldeV")))]),_c('span',{staticClass:"cVihnbOVCk0AV6cIk86g"},[_vm._v("R$"+_vm._s(_vm.shareInfo.all_gain))])])])])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"BR5wLnCNvHZmz12VrQIK"},[_c('p',[_vm._v("Apostas/")]),_c('p',[_vm._v("Valor/")]),_c('p',[_vm._v("Ganhos")])])
}]

export { render, staticRenderFns }