import http from './../http.interceptor.js';
 

export function getPlatformByGameCategory(params) {
	return http.post('/v3.home/getPlatformByGameCategory', params)
}

export function getGameList(params) {
	return http.post('/v3.home/getGameList', params)
}

export function searchGame(params) {
	return http.post('/v3.home/searchGame', params)
}

