<template>
    <div class="maxMaxth promote">
		<van-nav-bar
		  title="Centro de mensagens"
		  left-arrow
		  fixed
		  placeholder
		  @click-left="onClickLeft"
		/>
		<van-tabs v-model="activeName"
		@click="routerFun" 
		class="h"
		:lineWidth="(activeLength*6+'px')"
		animated
		line-height='1px'
		sticky
		offset-top=".9rem"
		background="var(--cms-background-color-primary)" 
		:ellipsis="false"
		title-active-color="var(--cms-primary-color)"
		title-inactive-color="var(--cms-text-color-primary)"
		color="var(--cms-primary-color)">
			<van-tab  title-style="font-size: 0.22rem;" v-for="(item,index) in tabList"  :title="$t(item.name)" :name='index' :key="index" >
				<component :is="item.content"></component>
			</van-tab>
		</van-tabs>
     </div>
  </template>
  
  <script>
	import Suporte from "./Suporte.vue"
	import Noticia from "./Noticia.vue"
	import Notificação from "./Notificação.vue"
	import PainelRolante from "./PainelRolante.vue"
	import BônusdeSugestão from "./BônusdeSugestão.vue"
	//import comsteKefy from "./js/keJSon.js"
	import { mapState } from 'vuex';
  export default {
	computed:{
			...mapState(['popList','userInfo'])
		},
   components: {
   },
   data(){
     return {
        activeName:0,
        activeLength:7,
		//tabConterList:comsteKefy,
        tabList:[
                {name:'notice._Suporte',content:Suporte},
				{name:'notice._Notícia',content:Noticia},
				{name:'notice._Notificação',content:Notificação},
				{name:'notice._PainelRolante',content:PainelRolante},
				{name:'notice._BônusdeSugestão',content:BônusdeSugestão},
				// {name:'notice._TelegramSuporte'},
				// {name:'notice._FacebookSuporte'},
				// {name:'notice._FAQ'},
				// {name:'notice._PerguntasFreqüentes'},
            ]
     }
   },
   created(){
	
   },
   mounted(){
	let {active}=this.$route.query;
	this.activeName=Number(active) || 0;
   },
   methods:{
     tabFun(e){
       this.$store.commit('active',e)
     },
     routerFun(e,l){
        this.activeLength=l.length;
     },
	 onClickLeft(){
	 		 this.$router.go(-1);
	 },
   }
  }
  
  </script>
  <style>
	  .promote .van-nav-bar {
	  	background: var(--cms-background-color-primary);
	  	border-bottom: .01rem solid var(--cms-background-color-primary);
	  	color: var(--cms-text-color-primary);
	  }
	  
	  .promote .van-nav-bar__title {
	  	color: var(--cms-text-color-primary);
	  }
	  
	  .promote .van-nav-bar .van-icon {
	  	color: var(--cms-text-color-secondary);
	  }
	  
	  .promote [class*=van-hairline]::after{
			border-bottom: .01rem solid var(--cms-background-color-primary) !important;
	  }
	  .promote .van-tabs div:first-child{
		  /* height: 100% !important; */
	  }
	  .promote .van-tabs--line .van-tabs__wrap{
		  height: .71rem;
		  border-bottom: .02rem solid var(--cms-divider-color);
	  }
	  .promote .van-tab--active {
	      font-weight: 400 !important;
	  }
	  .promote .h .van-tab__pane {
	      -ms-flex: 1;
	      flex: 1;
	      font-size: .2rem;
	      height: calc(100vh - 1.65rem);
	      height: calc(var(--vh, 1vh)* 100 - 1.65rem);
	      overflow: scroll;
	      width: 100%;
	  }
  </style>
  <style scoped>
	.promote{
		    background-color: var(--cms-background-color-secondary) !important;
			min-height: 100vh;
	}
	
	
  </style>
  