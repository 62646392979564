<template>
	<div>
		<van-row class="tenConter _border">
		    <van-col class="tr" span="4">{{ $t('_VIP._Nível') }}</van-col>
		    <van-col class="tr _disps" span="21">
				{{ $t('_VIP._Bônus') }}
		    </van-col>
		</van-row>
		<div class="tenConter-table-body">
				<van-row  :class="(index%2==0?'tenConter tc_sae':'tenConter')" :style="{
					border:formDelList.level==item.level?'1px solid var(--cms-primary-color)':''
				}" v-for="(item,index) in formDelList.list" :key="index">
					<van-col class="tr" span="4">
						<div class="JlCRc6ttKGcNUyDwFygw reward-mixins__levelImage l2ogUtOtFSdH0ER0ochY" style="flex: 0 0 0.7rem; width: 0.7rem; height: 0.7rem; font-size: 0.254545rem;">
							<img  v-lazy="item.backgroud"  class="goDRiiBsuEuXD3W1NphN" style="width: 0.7rem; height: 0.7rem;">
							<img v-lazy="item.icon"  class="goDRiiBsuEuXD3W1NphN" style="width: 0.7rem; height: 0.7rem;">
							<span :data-text="item.level">{{item.level }}</span>
						</div>
					</van-col>
					<van-col class="tr" span="21">
						<span class="RL9TJMMzDMOQihFhVnG2 lJM4_qheVPhMhtExmFdS">{{item.week_bonus}}</span>
					</van-col>
			</van-row>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			name: String,
			item: {
				type: [Array,Object],
				required: [],
			},
			formDelList:{
				type: [Array,Object],
				required: {},
			}
		},
		data() {
			return {
			};
		},
		methods: {
			onChange(e) {
				console.log(e)
			}
		}
	};
	
</script>

<style scoped>




	.JlCRc6ttKGcNUyDwFygw img,.NPzDoGC69c0JdVxoaQso img,.l2ogUtOtFSdH0ER0ochY img {
	    position: absolute
	}
	.JlCRc6ttKGcNUyDwFygw span:before,.NPzDoGC69c0JdVxoaQso span:before,.l2ogUtOtFSdH0ER0ochY span:before {
	    -webkit-background-clip: text;
	    background-clip: text;
	    background-image: -webkit-gradient(linear,left top,left bottom,from(#f7ea94),color-stop(51%,#e5b952),to(#ce9510));
	    background-image: linear-gradient(180deg,#f7ea94 0,#e5b952 51%,#ce9510);
	    background-size: cover;
	    content: attr(data-text);
	    height: 100%;
	    left: 0;
	    position: absolute;
	    text-shadow: none;
	    top: 0;
	    width: 100%;
	    z-index: 0
	}
	.l2ogUtOtFSdH0ER0ochY {
	    -ms-flex-align: center;
	    -ms-flex-pack: center;
	    align-items: center;
	    background-position: 50%;
	    background-repeat: no-repeat;
	    background-size: contain;
	    display: flex;
	    justify-content: center;
	    margin: auto;
	    position: relative;
	    text-align: center;
	}
	.JlCRc6ttKGcNUyDwFygw span,.NPzDoGC69c0JdVxoaQso span,.l2ogUtOtFSdH0ER0ochY span {
	    color: transparent;
	    display: inline-block;
	    font-style: normal;
	    font-weight: 700;
	    position: relative;
	    text-shadow: 0 .01rem 0 rgba(0,0,0,.4)
	}
	 ._2lvGimPeAnoEFiUw_fTw {
	    position: relative;
	    width: 3.1rem;
	    margin-top: .1rem;
	}
	 ._2lvGimPeAnoEFiUw_fTw .ant-progress-inner {
	    background-color: var(--theme-text-color-placeholder);
	    border-radius: .2rem
	}
	 ._2lvGimPeAnoEFiUw_fTw .ant-progress-inner .ant-progress-bg {
	    background-color: var(--theme-vip-progress-bg-color);
	    border-radius: .2rem !important;
	    height: .2rem !important;
	}
	._2lvGimPeAnoEFiUw_fTw>span {
	    -webkit-background-clip: text!important;
	    color: #fff;
	    font-size: .16rem;
	    left: 50%;
	    overflow: hidden;
	    position: absolute;
	    text-align: center;
	    text-overflow: ellipsis;
	    top: 60%;
	    transform: translate(-50%,-58%);
	    white-space: nowrap;
	    width: 94%
		}

</style>