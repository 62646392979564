<template>
    <header class="ant-layout-header pCKI81C6T6W2e4ZAfsHW  _s-s uJNjizvqNTc61kuO2wZO">
        <div class="IZ3RIKA0JMiWHOYb9u5f">
            <div class="DMmVnO4MrRvIrs3lfxmS" @click="goBack">
                <svg-icon  className="imageWrap"  icon-class="comm_icon_fh--svgSprite:all"  />
            </div>
            <div class="urdJVIoQ0Du2bRHlCOjg">
                <van-tabs v-model="tbaName" 
                    @click="routerFun" 
                    :lineWidth="(activeLength*7+'px')"
                    background="var(--cms-background-color-primary)" 
                    title-active-color="var(--cms-primary-color)"
                    title-inactive-color="var(--cms-text-color-primary)"
                    color="var(--cms-primary-color)"
					animated
					offset-top=".9rem"
					:ellipsis="false"
					>
                    <van-tab  title-style="font-size: 0.24rem;" v-for="(item,index) in tabList" :key="index" :title="$t(item.name)" :name="index">
						<!-- <component :is="content"></component> -->
						<div class="sontLIst" style="width: 100vw;margin-left: -.84rem;background-color:val(--cms-background-color-secondary);"  v-show="tbaName==index">
							<slot name="conter" :tabIndex="tbaName" ></slot>
						</div>
					</van-tab>
                 </van-tabs>
            </div>
			<div class="DMmVnO4MrRvIrs3lfxmS_right" @click="openUrl('notice')" notice>
				<svg-icon className="Gn5USDlcXrykBFgJY_Lc"  icon-class="icon_sys_menu_service--svgSprite:all"  />
			</div>
        </div>
    </header>
  </template>
   
  <script>
  
  export default {
    name: 'Icon',
    props: {
		tbaName:{
			type:Number,
			required:0,
		}
    },
    data(){
        return {
			activeName:0,
            show:false,
            activeLength:7,
            tabList:[
                {
                    name:'withdraw._Solicitarsaque',
                    route:"withdraw",
					current:0,
                },
                {
                    name:'withdraw._TarefasdeApostas',
                    route:"withdraw",
					current:1,
                },
                {
                    name:'withdraw._Contapararecebimento',
                    route:"withdraw",
					current:2,
                },
                {
                    name:'withdraw._RegistrodeSaques',
                    route:"withdraw",
					current:3,
                }
            ]
        }
    },
	mounted() {
		// let {current}=this.$route.query;
		// this.tbaName=current?Number(current):0;
	},
    methods: {
      routerFun(e,l){
		this.tbaName=e;
		this.$emit('modelValue',e)
        this.activeLength=l.length;
		// replace({ path: '/new-page' })
        this.$router.replace({name:this.tabList[e].route,query:{
			current:this.tabList[e].current
		}})
      },
      goBack() {
        this.$router.go(-1);
        }
    }
  }
  </script>
   <style>
.pCKI81C6T6W2e4ZAfsHW .van-tabs__line {
    background-color: var(--theme-primary-color);
    height: auto;
    height: .03rem;
}
.pCKI81C6T6W2e4ZAfsHW .van-tabs--line .van-tabs__wrap {
    border-bottom: none;
    height: 0.9rem;
}
._s-s .van-tabs__content--animated{
	 overflow: initial;
}
</style>
  <style  scoped>
    
.pCKI81C6T6W2e4ZAfsHW {
    background-color: var(--theme-top-nav-bg);
    border-bottom: none !important;
    height: var(--theme-header-height);
    line-height: normal;
    padding: 0;
    height: .9rem;
}
.IZ3RIKA0JMiWHOYb9u5f {
    background-color: var(--cms-background-color-primary);
    border-bottom: .01rem solid var(--cms-background-color-primary);
    overflow: initial;
    width: 100%;
    height: .9rem;
    position: fixed;
    z-index: 9;
}
.IZ3RIKA0JMiWHOYb9u5f .DMmVnO4MrRvIrs3lfxmS_right,.IZ3RIKA0JMiWHOYb9u5f  .DMmVnO4MrRvIrs3lfxmS {
    cursor: pointer;
    font-size: .24rem;
    left: 0;
    padding: .33rem .4rem .27rem .2rem;
    position: absolute;
    top: 0;
    z-index: 2;
}
.IZ3RIKA0JMiWHOYb9u5f .DMmVnO4MrRvIrs3lfxmS_right{
	right: 0;
	left: initial;
	height: .9rem;
	color: var(--cms-primary-color);
	padding: 0 !important;
	display: flex;
	align-items: center;
}
.Gn5USDlcXrykBFgJY_Lc{
	width: .44rem;
	height: .44rem;
	margin-right: .2rem;
}
.IZ3RIKA0JMiWHOYb9u5f  .DMmVnO4MrRvIrs3lfxmS  .imageWrap{
    width: .24rem;
    height: .24rem;
    color: var(--cms-text-color-secondary);
}
.IZ3RIKA0JMiWHOYb9u5f .urdJVIoQ0Du2bRHlCOjg {
    margin-left: .84rem;
	margin-right: .84rem;
    width: calc(100% - 1.68rem);
}
.ETlYzQW0ZLPLfrXjv6oO .van-tabs__nav {
    -webkit-overflow-scrolling: touch;
    background-color: var(--theme-main-bg-color);
    display: inline-block;
    overflow-x: auto;
    padding: 0;
    white-space: nowrap;
    width: 100%;
}

  </style>
  