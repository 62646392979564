var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"promote SenhadeLogin"},[_c('van-nav-bar',{attrs:{"title":"Senha de Login","left-arrow":"","fixed":"","placeholder":""},on:{"click-left":_vm.onClickLeft}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.old_password),expression:"!old_password"}],staticClass:"login-register-from-space"},[_c('div',{staticClass:"X5eogfqvbxD_ckZVQSll",staticStyle:{"padding-bottom":".4rem"}},[_vm._v("Alterar Senha")]),_c('div',{staticStyle:{"margin-bottom":".2rem"}},[_c('antInput',{class:{
				 	'input-active':_vm.isFocus
				 },attrs:{"left-icon":"a","modelValue":_vm.formConter.password,"maxlength":"16","form-key":"password","type":_vm.fonData.password.type,"placeholder":"Por favor, insira a nova senha"},on:{"focus":_vm.onFocus,"blur":_vm.onBlur,"onInput":_vm.onInput}},[_c('div',{staticStyle:{"display":"flex","align-items":"center"},attrs:{"slot":"left"},slot:"left"},[_c('svg-icon',{staticStyle:{"width":".26rem","height":".26rem","color":"var(--cms-text-color-secondary)"},attrs:{"className":"imageWrap","icon-class":"input_icon_mm--svgSprite:all"}}),_c('span',{staticClass:"ZEcfoKF3Q_zq5EqaOdUy"},[(!_vm.formConter.password)?_c('i',[_vm._v("*")]):_vm._e()])],1),_c('div',{attrs:{"slot":"right-icon"},on:{"click":function($event){return _vm.showHide('password')}},slot:"right-icon"},[_c('svg-icon',{staticStyle:{"width":".26rem","height":".26rem","color":"var(--cms-text-color-secondary)"},attrs:{"className":"right-icon","icon-class":_vm.fonData.password.type=='text'?'comm_icon_show--svgSprite:all':'comm_icon_hide--svgSprite:all'}})],1)])],1),(_vm.fonData.password.value)?_c('div',{staticClass:"ant-form-explain"},[_vm._v(_vm._s(_vm.fonData.password.value))]):_vm._e(),_c('div',{staticClass:"ant-space-item",staticStyle:{"margin-bottom":"0px"}},[_c('div',{staticClass:"ptEr7ybENfgNXBPMnsRb"},[_c('div',{staticClass:"rBX8yUXrZ3DzhJ4ZvZUD"},[_c('span',[_vm._v("Força")]),_vm._l((4),function(index){return _c('span',{key:index,staticClass:"VYe1C86O9HyF7KaCqIUg",class:{
						QZ0QekXF9duW7ClyMSdQ:index<=_vm.mimaQiangdu && _vm.formConter.password,
						
					},style:({
						    backgroundColor:_vm.mimaQiangdu==4?'var(--cms-text-color-secondary) !important':''
					})})})],2)])]),_c('div',{staticStyle:{"margin-bottom":".2rem"}},[_c('antInput',{class:{
				 	'input-active':_vm.isFocus
				 },attrs:{"left-icon":"a","modelValue":_vm.formConter.password_confirm,"maxlength":"16","form-key":"password_confirm","type":_vm.fonData.password_confirm.type,"placeholder":"Confirmar Novo Senha"},on:{"focus":_vm.onFocus,"blur":_vm.onBlur,"onInput":_vm.onInput}},[_c('div',{staticStyle:{"display":"flex","align-items":"center"},attrs:{"slot":"left"},slot:"left"},[_c('svg-icon',{staticStyle:{"width":".26rem","height":".26rem","color":"var(--cms-text-color-secondary)"},attrs:{"className":"imageWrap","icon-class":"input_icon_mm--svgSprite:all"}}),_c('span',{staticClass:"ZEcfoKF3Q_zq5EqaOdUy"},[(!_vm.formConter.password_confirm)?_c('i',[_vm._v("*")]):_vm._e()])],1),_c('div',{attrs:{"slot":"right-icon"},on:{"click":function($event){return _vm.showHide('password_confirm')}},slot:"right-icon"},[_c('svg-icon',{staticStyle:{"width":".26rem","height":".26rem","color":"var(--cms-text-color-secondary)"},attrs:{"className":"right-icon","icon-class":_vm.fonData.password_confirm.type=='text'?'comm_icon_show--svgSprite:all':'comm_icon_hide--svgSprite:all'}})],1)])],1),(_vm.fonData.password_confirm.value && _vm.fonData.password.type!='text')?_c('div',{staticClass:"ant-form-explain"},[_vm._v(_vm._s(_vm.fonData.password_confirm.value))]):_vm._e()]),(_vm.old_password)?_c('div',{staticClass:"login-register-from-space"},[_c('div',{staticClass:"X5eogfqvbxD_ckZVQSll",staticStyle:{"padding-bottom":".4rem"}},[_vm._v("Verifique a senha de login antiga")]),_c('div',{staticStyle:{"margin-bottom":".2rem"}},[_c('antInput',{class:{
				 	'input-active':_vm.isFocus
				 },attrs:{"left-icon":"a","modelValue":_vm.formConter.old_password,"maxlength":"16","form-key":"old_password","type":_vm.fonData.old_password.type,"placeholder":"Por favor, insira a nova senha"},on:{"focus":_vm.onFocus,"blur":_vm.onBlur,"onInput":_vm.onInput}},[_c('div',{staticStyle:{"display":"flex","align-items":"center"},attrs:{"slot":"left"},slot:"left"},[_c('svg-icon',{staticStyle:{"width":".26rem","height":".26rem","color":"var(--cms-text-color-secondary)"},attrs:{"className":"imageWrap","icon-class":"input_icon_mm--svgSprite:all"}})],1),_c('div',{attrs:{"slot":"right-icon"},on:{"click":function($event){return _vm.showHide('old_password')}},slot:"right-icon"},[_c('svg-icon',{staticStyle:{"width":".26rem","height":".26rem","color":"var(--cms-text-color-secondary)"},attrs:{"className":"right-icon","icon-class":_vm.fonData.old_password.type=='text'?'comm_icon_show--svgSprite:all':'comm_icon_hide--svgSprite:all'}})],1)])],1),(_vm.fonData.old_password.value)?_c('div',{staticClass:"ant-form-explain"},[_vm._v(_vm._s(_vm.fonData.old_password.value))]):_vm._e(),_c('div',{staticStyle:{"color":"var(--theme-primary-color)","font-size":".22rem","text-align":"right"}},[_vm._v(" Outros Métodos de Verificação ")])]):_vm._e(),_c('div',{staticClass:"Xhr_XJt3jLTtAL8pC16D verification-btn__group wg-fixed-no-desktop",on:{"click":_vm.registerUrl}},[_c('button',{staticClass:"ant-btn ant-btn-primary verification-btn__next",attrs:{"type":"button"}},[_vm._v(" Próximo ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }