<template>
	<div class="Idioma-popup">
		<van-popup v-model="isDireto.LinkdeConviteEvent" @close="$store.commit('publicFun',['isDireto',{
				LinkdeConviteEvent:false,
			}])">
			<div class='ant-modal-content'>
				<div class="ant-modal-header">
					<div class='ant-modal-title'>Link de Convite</div>
				</div>
				<div class='ant-modal-body'>
					<div class="ant-row-flex ant-row-flex-start C8cXiFHgN044ZoXZdLnO"
						style="display: flex;align-items: center;justify-content: space-between;">
						<ant-select :value="game_typeValue" :options="game_type" @change="_onChangeGame_type">
						</ant-select>
						<!-- <datetimePicker class="ifzwCQu8BPs2c24nZdzQ" @_timeOnFunEndDate="_timeOnFunEndDate"></datetimePicker> -->
						<div class="" style="margin-right: 0;">
							<searchFor @search="_search" @input="_searchInput" placeholder="Conta"></searchFor>
						</div>
						<!-- <div class="ant-col"><label>Data Liquidação</label><span class="dateTime">{{isDireto.date}}</span>
						</div>
						<div class="ant-col"><label>{{$store.state.isDireto.type==2?'D. dos Outros':'D. Direto'}} </label><span class="currencyAmount">{{shareInfo.bet_total}}</span></div> -->
					</div>
					<div class="TBtGFT5INIot5sEUIm8w vantDataList">
						<van-empty image-size="2.1rem" v-if="!shareInfo || !shareInfo.length" class="custom-image"
							:image="img_none_sj" :description="$t('description')" />
						<!-- <div role="feed" class="van-list reverse" > -->
						<van-list :finished="finished" v-model="loading" @load="onLoad">
							<div v-for="item in shareInfo">
								<div class="ant-row-flex ant-row-flex-space-between" style="margin-bottom:.1rem">
									<div class="Iu2XDEUxSXVTmZ2ClDl5" style="width: 49%;">
										<div @click="copyContent(item.id)" class="vKDmG8Nz_Vcot47o6C7L globalInnerMain">
											<div class="Qu0jzrV6QQQRuKG_6idK">{{item.user_name}}</div>
										</div>
									</div>
									<div class="ant-col ant-col-12" style="width: 49%;">
										<label class="MlezEysRdS_J1OuFqOI4">Válido ou não</label>
										<span class="currencyAmount">{{item.valid==1?'Sim':'Não'}}</span>
									</div>
								</div>
								<div class="ant-row-flex ant-row-flex-space-between">
									<div class="ant-col ant-col-12" style="width: 49%;">
										<label class="MlezEysRdS_J1OuFqOI4">Hora de <br> registro：</label>
										<span class="currencyAmount">{{item.reg_time}}</span>
									</div>
									<div class="ant-col ant-col-12" style="width: 49%;" @click="isMoedlFun(item)">
										<label class="MlezEysRdS_J1OuFqOI4"
											style=" color: var(--cms-text-color-secondary)">Detalhes</label>
									</div>
								</div>

							</div>

							
						</van-list>

					</div>
				</div>
			</div>

			<div @click="$store.commit('publicFun',['isDireto',{
				LinkdeConviteEvent:false,
			}])" class="QzRd3YqTnYxG8Latq8fW closeIcon"><img data-groups-id="my-img-1e7adda2-2e2a-446c-8fbb-cdfafd81c2c4"
					data-src-render="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFgAAABYCAMAAABGS8AGAAAAilBMVEUAAAD////e5Orv8vXe5Or////e5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Or////////h5+z8/f7z9ffm6/Dp7fHt8PT5+vv2+PrCEzcDAAAAJXRSTlMAYJYggoAJR6DVzbyRfHYuimpXKBlePTURrlDbxOm27OJwZaif1OMsUAAABGNJREFUWMPdmdtiojAQhlNAOSOoeK6tugRy4P1fb0lCO26DDRiv9r8Twsfkz0wSA/ovtVh6tzj8fP/4eP+M4iBdrpC93GtwJJQ1La+leNswisOgXNtQd9czpg2vNPGG4v1y/iR2lWDaVo9Ud2xv/YyxDmnqnsG77hOCOxFhC/+6znDuTsSuHdx89ZrgU5CW/nbluqutX6ZBJP1RYeN8kiEZZv2DJMr9nfbaZRL2/alZdJ3ignyIU5xvHzXaOJipVsQZaXWBG4UNi99zJuvR7LQcw00Il63xxdw2VTG02DO3dahqmuxG1Y8jw6iJY2g4V1wWH9BIFZEMmjoGLlPv302oI4eayYqLczRJKomoY/CX4wxNVI4F+bHPDpHcAk2Wp8gPcuOChQ/AnURWzx4GByEUiYNT9JQS0ds2HjSiEb1JnoDC+LABmzMCN55SIALDpVb4wn4eWSw4G0k4aR4x8b4rslAq+ky9HyOHlRFWujUiM34ELK9JI2zM0EJewyUbJVrIHuuufNpvQsT40QKB5IVsoOVsNnMRGnEDQuYh/F5S0QV9uX2rhN7QmBuQAwTWyX33osbRw6qU3nSukh5z3Hak/J/iIBut1awC8hC3mmmPlLQfLHDiiAbBQNa51R99CcL1nReOcCIZBgNZ50LEoHPHYl9pIOZLekCDHgNZ54LHoIx1QZ7758VQalUHECBrl3RtCSTYgYh5DSET2cgFkxcQfYDMZANX6dR2vi6/x455aAR5BBcFMHpiIGmJRpDNXDXt9MUW8c4VHxnII7mooN9pEXJZdwaymQvV1qoF6hPG0UQ2c5EPOfbRgfEKmcnANYHDEWAgA9cMBivswboVRzF4W3srYPD4SUs3+8G7iqw4qwJpoUDs0y2VEwQUYfqSAgEavONVJR3L/oPd0asmIVib5Jpd491rps3F3USPjjWkhe1EX1C5sQe/m/w1S9PtfnIvhcmh9WIKFm9grwnzm93yf5AWaxsj6w0LbNagDOt3yy0W9H3z75pNC+tNob7PduS+1mYbCwGy7D6tZRcuA/vrP7C/Nt/IGVTHXfrxI7LTSgacD/zhSezADvz1+nnRt+EWIjj2M7iF6EZztuC6IRd2zjXjqTx+sTCCQQJoRVOR9GkuqWTR6fJxLWwun+OmwmAeDuamR8S96PDUwMlDIVz8ckjSxk+Qr5hrxzOa/+1pshsXzH8feddpBBlfph67cdNpx0KSOZ5SgnOH1JI7R0ZyTZ3FWK6/Z5Xk7kzntrJdg7OxNvSHsfORPavpmOwoI1rD8bFJHm7l2TTZG+akZUz4pO4dYlbLB0hUrB96loVEBlDTYDN6oBPlXMUp3hergTG+xJjxSoXrTTrpupH2+8tPeEuXW3c37964c7eltz8SJu7KTpnzRzewqZTkFyHcS34TqnqsNgwjM5So7irVUvCbMwzmTtTCw6LTuuqWkShzkYV874T774TwpZDgON0ia7mHNDgdu++anT7ej3GQ+Wv0H+ov9UMLyPmx8boAAAAASUVORK5CYII="
					src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFgAAABYCAMAAABGS8AGAAAAilBMVEUAAAD////e5Orv8vXe5Or////e5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Or////////h5+z8/f7z9ffm6/Dp7fHt8PT5+vv2+PrCEzcDAAAAJXRSTlMAYJYggoAJR6DVzbyRfHYuimpXKBlePTURrlDbxOm27OJwZaif1OMsUAAABGNJREFUWMPdmdtiojAQhlNAOSOoeK6tugRy4P1fb0lCO26DDRiv9r8Twsfkz0wSA/ovtVh6tzj8fP/4eP+M4iBdrpC93GtwJJQ1La+leNswisOgXNtQd9czpg2vNPGG4v1y/iR2lWDaVo9Ud2xv/YyxDmnqnsG77hOCOxFhC/+6znDuTsSuHdx89ZrgU5CW/nbluqutX6ZBJP1RYeN8kiEZZv2DJMr9nfbaZRL2/alZdJ3ignyIU5xvHzXaOJipVsQZaXWBG4UNi99zJuvR7LQcw00Il63xxdw2VTG02DO3dahqmuxG1Y8jw6iJY2g4V1wWH9BIFZEMmjoGLlPv302oI4eayYqLczRJKomoY/CX4wxNVI4F+bHPDpHcAk2Wp8gPcuOChQ/AnURWzx4GByEUiYNT9JQS0ds2HjSiEb1JnoDC+LABmzMCN55SIALDpVb4wn4eWSw4G0k4aR4x8b4rslAq+ky9HyOHlRFWujUiM34ELK9JI2zM0EJewyUbJVrIHuuufNpvQsT40QKB5IVsoOVsNnMRGnEDQuYh/F5S0QV9uX2rhN7QmBuQAwTWyX33osbRw6qU3nSukh5z3Hak/J/iIBut1awC8hC3mmmPlLQfLHDiiAbBQNa51R99CcL1nReOcCIZBgNZ50LEoHPHYl9pIOZLekCDHgNZ54LHoIx1QZ7758VQalUHECBrl3RtCSTYgYh5DSET2cgFkxcQfYDMZANX6dR2vi6/x455aAR5BBcFMHpiIGmJRpDNXDXt9MUW8c4VHxnII7mooN9pEXJZdwaymQvV1qoF6hPG0UQ2c5EPOfbRgfEKmcnANYHDEWAgA9cMBivswboVRzF4W3srYPD4SUs3+8G7iqw4qwJpoUDs0y2VEwQUYfqSAgEavONVJR3L/oPd0asmIVib5Jpd491rps3F3USPjjWkhe1EX1C5sQe/m/w1S9PtfnIvhcmh9WIKFm9grwnzm93yf5AWaxsj6w0LbNagDOt3yy0W9H3z75pNC+tNob7PduS+1mYbCwGy7D6tZRcuA/vrP7C/Nt/IGVTHXfrxI7LTSgacD/zhSezADvz1+nnRt+EWIjj2M7iF6EZztuC6IRd2zjXjqTx+sTCCQQJoRVOR9GkuqWTR6fJxLWwun+OmwmAeDuamR8S96PDUwMlDIVz8ckjSxk+Qr5hrxzOa/+1pshsXzH8feddpBBlfph67cdNpx0KSOZ5SgnOH1JI7R0ZyTZ3FWK6/Z5Xk7kzntrJdg7OxNvSHsfORPavpmOwoI1rD8bFJHm7l2TTZG+akZUz4pO4dYlbLB0hUrB96loVEBlDTYDN6oBPlXMUp3hergTG+xJjxSoXrTTrpupH2+8tPeEuXW3c37964c7eltz8SJu7KTpnzRzewqZTkFyHcS34TqnqsNgwjM5So7irVUvCbMwzmTtTCw6LTuuqWkShzkYV874T774TwpZDgON0ia7mHNDgdu++anT7ej3GQ+Wv0H+ov9UMLyPmx8boAAAAASUVORK5CYII="
					alt="." data-blur="0" data-status="loaded" class="goDRiiBsuEuXD3W1NphN"></div>
		</van-popup>
		<van-popup v-model="isMoedl" >
			<div class="ant-modal-content">
				<div class="ant-modal-header">
					<div class='ant-modal-title'>Detalhes da expansão</div>
				</div>
				<div class="ant-modal-body">
					<div style="height: 3rem;">
						<div class="fJXqApBUOr8iyoj6KURz">
							<span  :class="{
								kSFnWJ3GYVtlFV38juGg:isDireto.ruleRecharge<=itemInfo.recharge_total,
								ddh7G5P0jRLU7yyzYd9F:isDireto.ruleRecharge>itemInfo.recharge_total,
							}">Depósito Total{{isDireto.ruleRecharge}}+</span>
						</div>
						<div class="fJXqApBUOr8iyoj6KURz">
							<span :class="{
								kSFnWJ3GYVtlFV38juGg:isDireto.relebet<=itemInfo.bet_total,
								ddh7G5P0jRLU7yyzYd9F:isDireto.relebet>itemInfo.bet_total,
							}">Apostas{{isDireto.relebet}}+</span>
						</div>
					</div>
				</div>
			</div>
			<div @click="isMoedl=false" class="QzRd3YqTnYxG8Latq8fW closeIcon"><img data-groups-id="my-img-1e7adda2-2e2a-446c-8fbb-cdfafd81c2c4"
					data-src-render="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFgAAABYCAMAAABGS8AGAAAAilBMVEUAAAD////e5Orv8vXe5Or////e5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Or////////h5+z8/f7z9ffm6/Dp7fHt8PT5+vv2+PrCEzcDAAAAJXRSTlMAYJYggoAJR6DVzbyRfHYuimpXKBlePTURrlDbxOm27OJwZaif1OMsUAAABGNJREFUWMPdmdtiojAQhlNAOSOoeK6tugRy4P1fb0lCO26DDRiv9r8Twsfkz0wSA/ovtVh6tzj8fP/4eP+M4iBdrpC93GtwJJQ1La+leNswisOgXNtQd9czpg2vNPGG4v1y/iR2lWDaVo9Ud2xv/YyxDmnqnsG77hOCOxFhC/+6znDuTsSuHdx89ZrgU5CW/nbluqutX6ZBJP1RYeN8kiEZZv2DJMr9nfbaZRL2/alZdJ3ignyIU5xvHzXaOJipVsQZaXWBG4UNi99zJuvR7LQcw00Il63xxdw2VTG02DO3dahqmuxG1Y8jw6iJY2g4V1wWH9BIFZEMmjoGLlPv302oI4eayYqLczRJKomoY/CX4wxNVI4F+bHPDpHcAk2Wp8gPcuOChQ/AnURWzx4GByEUiYNT9JQS0ds2HjSiEb1JnoDC+LABmzMCN55SIALDpVb4wn4eWSw4G0k4aR4x8b4rslAq+ky9HyOHlRFWujUiM34ELK9JI2zM0EJewyUbJVrIHuuufNpvQsT40QKB5IVsoOVsNnMRGnEDQuYh/F5S0QV9uX2rhN7QmBuQAwTWyX33osbRw6qU3nSukh5z3Hak/J/iIBut1awC8hC3mmmPlLQfLHDiiAbBQNa51R99CcL1nReOcCIZBgNZ50LEoHPHYl9pIOZLekCDHgNZ54LHoIx1QZ7758VQalUHECBrl3RtCSTYgYh5DSET2cgFkxcQfYDMZANX6dR2vi6/x455aAR5BBcFMHpiIGmJRpDNXDXt9MUW8c4VHxnII7mooN9pEXJZdwaymQvV1qoF6hPG0UQ2c5EPOfbRgfEKmcnANYHDEWAgA9cMBivswboVRzF4W3srYPD4SUs3+8G7iqw4qwJpoUDs0y2VEwQUYfqSAgEavONVJR3L/oPd0asmIVib5Jpd491rps3F3USPjjWkhe1EX1C5sQe/m/w1S9PtfnIvhcmh9WIKFm9grwnzm93yf5AWaxsj6w0LbNagDOt3yy0W9H3z75pNC+tNob7PduS+1mYbCwGy7D6tZRcuA/vrP7C/Nt/IGVTHXfrxI7LTSgacD/zhSezADvz1+nnRt+EWIjj2M7iF6EZztuC6IRd2zjXjqTx+sTCCQQJoRVOR9GkuqWTR6fJxLWwun+OmwmAeDuamR8S96PDUwMlDIVz8ckjSxk+Qr5hrxzOa/+1pshsXzH8feddpBBlfph67cdNpx0KSOZ5SgnOH1JI7R0ZyTZ3FWK6/Z5Xk7kzntrJdg7OxNvSHsfORPavpmOwoI1rD8bFJHm7l2TTZG+akZUz4pO4dYlbLB0hUrB96loVEBlDTYDN6oBPlXMUp3hergTG+xJjxSoXrTTrpupH2+8tPeEuXW3c37964c7eltz8SJu7KTpnzRzewqZTkFyHcS34TqnqsNgwjM5So7irVUvCbMwzmTtTCw6LTuuqWkShzkYV874T774TwpZDgON0ia7mHNDgdu++anT7ej3GQ+Wv0H+ov9UMLyPmx8boAAAAASUVORK5CYII="
					src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFgAAABYCAMAAABGS8AGAAAAilBMVEUAAAD////e5Orv8vXe5Or////e5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Or////////h5+z8/f7z9ffm6/Dp7fHt8PT5+vv2+PrCEzcDAAAAJXRSTlMAYJYggoAJR6DVzbyRfHYuimpXKBlePTURrlDbxOm27OJwZaif1OMsUAAABGNJREFUWMPdmdtiojAQhlNAOSOoeK6tugRy4P1fb0lCO26DDRiv9r8Twsfkz0wSA/ovtVh6tzj8fP/4eP+M4iBdrpC93GtwJJQ1La+leNswisOgXNtQd9czpg2vNPGG4v1y/iR2lWDaVo9Ud2xv/YyxDmnqnsG77hOCOxFhC/+6znDuTsSuHdx89ZrgU5CW/nbluqutX6ZBJP1RYeN8kiEZZv2DJMr9nfbaZRL2/alZdJ3ignyIU5xvHzXaOJipVsQZaXWBG4UNi99zJuvR7LQcw00Il63xxdw2VTG02DO3dahqmuxG1Y8jw6iJY2g4V1wWH9BIFZEMmjoGLlPv302oI4eayYqLczRJKomoY/CX4wxNVI4F+bHPDpHcAk2Wp8gPcuOChQ/AnURWzx4GByEUiYNT9JQS0ds2HjSiEb1JnoDC+LABmzMCN55SIALDpVb4wn4eWSw4G0k4aR4x8b4rslAq+ky9HyOHlRFWujUiM34ELK9JI2zM0EJewyUbJVrIHuuufNpvQsT40QKB5IVsoOVsNnMRGnEDQuYh/F5S0QV9uX2rhN7QmBuQAwTWyX33osbRw6qU3nSukh5z3Hak/J/iIBut1awC8hC3mmmPlLQfLHDiiAbBQNa51R99CcL1nReOcCIZBgNZ50LEoHPHYl9pIOZLekCDHgNZ54LHoIx1QZ7758VQalUHECBrl3RtCSTYgYh5DSET2cgFkxcQfYDMZANX6dR2vi6/x455aAR5BBcFMHpiIGmJRpDNXDXt9MUW8c4VHxnII7mooN9pEXJZdwaymQvV1qoF6hPG0UQ2c5EPOfbRgfEKmcnANYHDEWAgA9cMBivswboVRzF4W3srYPD4SUs3+8G7iqw4qwJpoUDs0y2VEwQUYfqSAgEavONVJR3L/oPd0asmIVib5Jpd491rps3F3USPjjWkhe1EX1C5sQe/m/w1S9PtfnIvhcmh9WIKFm9grwnzm93yf5AWaxsj6w0LbNagDOt3yy0W9H3z75pNC+tNob7PduS+1mYbCwGy7D6tZRcuA/vrP7C/Nt/IGVTHXfrxI7LTSgacD/zhSezADvz1+nnRt+EWIjj2M7iF6EZztuC6IRd2zjXjqTx+sTCCQQJoRVOR9GkuqWTR6fJxLWwun+OmwmAeDuamR8S96PDUwMlDIVz8ckjSxk+Qr5hrxzOa/+1pshsXzH8feddpBBlfph67cdNpx0KSOZ5SgnOH1JI7R0ZyTZ3FWK6/Z5Xk7kzntrJdg7OxNvSHsfORPavpmOwoI1rD8bFJHm7l2TTZG+akZUz4pO4dYlbLB0hUrB96loVEBlDTYDN6oBPlXMUp3hergTG+xJjxSoXrTTrpupH2+8tPeEuXW3c37964c7eltz8SJu7KTpnzRzewqZTkFyHcS34TqnqsNgwjM5So7irVUvCbMwzmTtTCw6LTuuqWkShzkYV874T774TwpZDgON0ia7mHNDgdu++anT7ej3GQ+Wv0H+ov9UMLyPmx8boAAAAASUVORK5CYII="
					alt="." data-blur="0" data-status="loaded" class="goDRiiBsuEuXD3W1NphN"></div>
		</van-popup>
	</div>
</template>

<script>
	// 
	import searchFor from "@/components/searchFor/searchFor.vue";
	import {
		shareInvite
	} from "@/apis/modules/share.js";
	import {
		mapState
	} from 'vuex';
	export default {
		components:{
			searchFor
		},
		computed: {
			...mapState(['isDireto'])
		},
		props: {
			isShow: {
				type: Boolean,
				default: () => {
					return true;
				}
			}
		},
		data() {
			return {
				shareInfo: [],
				itemInfo:{},
				isMoedl: false,
				game_typeValue: "0",
				loading: false,
				fromType: 1,
				finished: false,
				user_name:'',
				game_type: [{
						text: "Válido ou não",
						value: '0'
					},
					{
						text: "Válido",
						value: '1'
					},
					{
						text: "Inválido",
						value: "2"
					}
				]
			}
		},
		mounted() {
			this.shareInvite();
		},
		methods: {
			_onChangeGame_type(e){
				this.game_typeValue=e[0];
				this.shareInfo=[];
				this.fromType=1;
				this.shareInvite()
			},
			_search(e){
				this.user_name=e;
				this.shareInfo=[];
				this.fromType=1;
				this.shareInvite()
			},
			_searchInput(e){
							this.user_name = e.target.value;
			},
			shareInvite() {
				shareInvite({
					page:this.fromType,
					type:this.game_typeValue,
					user_name:this.user_name
				}).then(({
					data
				}) => {
					this.loading=false;
					if(JSON.stringify(data)=='[]' || data.length<50){
						this.finished = true;
					}else{
						this.finished = false;
					}
					// data.list=[{
					// 	gain:1111
					// }]
					// this.itemInfo=data[0];
					this.shareInfo = [...this.shareInfo,...data];
				})
			},
			onLoad() {
				if(this.finished || this.shareInfo.length<50){
					this.loading=false;
					return false;
				}
				this.fromType=this.fromType+1;
				this.shareInvite();
				// this.getBetLog();
			},
			isMoedlFun(item){
				this.itemInfo=item
				this.isMoedl=true;
			},
			closeFun() {
				// this.isShow=false;
				this.$emit('qingkOngs')
			},
			switchLang(e = 'en') {
				this.$i18n.locale = e;
				this._i18n.locale = e;
				// this.isShow=false;
				this.$emit('_remove')
				this.$vuex('_i18nLocale', e)
			},
		}

	}
</script>
<style>
	.TBtGFT5INIot5sEUIm8w .van-list.reverse>div {
		position: relative;
	}
</style>
<style scoped>
	.fJXqApBUOr8iyoj6KURz:nth-child(odd) {
	    /* background-color: var(--theme-bg-color) */
	}
	.fJXqApBUOr8iyoj6KURz{
		 height: .5rem;
		 display: flex;
		 align-items: center;
	}
	.fJXqApBUOr8iyoj6KURz .kSFnWJ3GYVtlFV38juGg {
	    color: #04be02
	}
	
	.fJXqApBUOr8iyoj6KURz .ddh7G5P0jRLU7yyzYd9F {
	    color: var(--theme-text-color-darken)
	}
	.fJXqApBUOr8iyoj6KURz span {
	    font-size: .16rem;
	    height: .5rem;
		display: inline-block;
	    line-height: .5rem;
	    padding-left: .2rem;
	    text-align: left
	}
	.Q6NkNwL6l8CDPSdtrO2P {
		color: var(--cms-primary-color) !important;
	}

	.TBtGFT5INIot5sEUIm8w .van-list>div {
		position: relative;
	}
	.TBtGFT5INIot5sEUIm8w .van-list{
		padding: 0 .0rem 0 0rem;
	}
	.MCxdxRhxa0voqrCEptAa {
		color: var(--cms-global-secondary1-color) !important;
	}

	.iSeiyC3bqps4IGGTXatc {
		background-color: var(--theme-secondary-color-error);
		color: var(--theme-secondary-color-error)
	}

	.TmTlgIIHiQR86nkpcl8M {
		color: var(--theme-secondary-color-finance);
		/* background-color:var(--theme-secondary-color-finance); */
	}

	.Q6NkNwL6l8CDPSdtrO2P1 {
		background-color: var(--theme-secondary-color-error);
	}

	.MCxdxRhxa0voqrCEptAa1 {
		background-color: var(--theme-secondary-color-success);
	}

	.iSeiyC3bqps4IGGTXatc1 {
		background-color: var(--theme-secondary-color-error);
		color: var(--theme-secondary-color-error)
	}

	.TmTlgIIHiQR86nkpcl8M1 {
		background-color: var(--theme-secondary-color-finance);
	}

	.ant-modal-content .mwRRRArq5gAZYhhvbvhg .W81PYwak4A0iuAdse7m7 {
		font-size: .24rem;
		height: .6rem;
		line-height: .6rem;
	}

	.ant-modal-content {
		background-color: var(--cms-background-color-primary);
		border: thin solid;
		border-color: var(--cms-divider-color);
		border-radius: .2rem;
		max-height: calc(100vh - .1rem);
		max-height: calc(var(--vh, 1vh)*100 - .1rem);
		overflow: auto;
		position: static;
		width: 6rem;
		padding: 0 0 .1rem 0;
	}

	.ant-modal-close-x {
		font-size: .2rem;
		height: .6rem;
		line-height: .6rem;
		color: var(--theme-text-color);
		width: .6rem;
		position: absolute;
		right: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		top: 0;
		font-size: .2rem;
	}

	.ant-modal-close:focus,
	.ant-modal-close:hover {
		color: rgba(0, 0, 0, .75);
		text-decoration: none
	}

	.Idioma-popup .van-popup {
		background-color: initial;
	}

	.ant-modal-content .ant-modal-header {
		border-bottom: none;
		border: none;
		border-radius: .2rem .2rem 0 0;
		padding: .2rem .3rem
	}

	.ant-modal-body::after {
		display: none;
	}

	.ant-modal-content .ant-modal-header .ant-modal-title {
		color: var(--cms-text-color-primary);
		font-size: .3rem;
		font-weight: revert;
		line-height: .34rem;
		text-align: center
	}

	.ant-modal-content .ant-modal-body {
		padding: .2rem .2rem 0 .2rem;
		position: relative;
	}

	.ant-modal-content .ant-modal-body:after {
		background-color: var(--cms-divider-color);
		content: "";
		height: .01rem;
		left: 0;
		position: absolute;
		top: 0rem;
		width: 100%
	}

	.XmFVAcOt7YCkuEJ1j2xA {
		border: thin solid var(--theme-color-line);
		border-radius: 100%;
		font-size: .18rem;
		height: .3rem;
		line-height: .33rem;
		width: .3rem
	}

	.Iu2XDEUxSXVTmZ2ClDl5 {
		display: flex;
		align-items: center;

	}

	._yZsg5Aq0active .cicle {
		-ms-flex-align: center;
		-ms-flex-pack: center;
		align-items: center;
		background: var(--cms-primary-color);
		border: thin solid var(--cms-primary-color);
		color: var(--theme-primary-font-color);
		display: -ms-flexbox;
		display: flex;
		justify-content: center
	}

	._yZsg5Aq0active>span {
		color: var(--cms-primary-color)
	}

	.XmFVAcOt7YCkuEJ1j2xA i {
		opacity: 0
	}

	.XmFVAcOt7YCkuEJ1j2xA .imageNav-a {
		width: .18rem;
		color: var(--cms-background-color-secondary);
		height: .18rem;
	}

	.QzRd3YqTnYxG8Latq8fW {
		-ms-flex-align: center;
		-ms-flex-pack: center;
		align-items: center;
		background: none;
		border-radius: 50%;
		display: -ms-flexbox;
		display: flex;
		height: .64rem;
		justify-content: center;
		margin: 0 auto;
		margin-top: .2rem;
		width: .64rem
	}

	.QzRd3YqTnYxG8Latq8fW img {
		width: 100%;
		height: 100%;
	}

	.Tx8SM0ickmaT3XlvpBa3 {
		padding: .3rem 0
	}

	.Tx8SM0ickmaT3XlvpBa3 li {
		-ms-flex-align: center;
		-ms-flex-pack: justify;
		align-items: center;
		display: -ms-flexbox;
		display: flex;
		font-size: .22rem;
		justify-content: space-between;
		margin-bottom: .2rem;
		min-height: .26rem
	}

	.Tx8SM0ickmaT3XlvpBa3 li.B1TtAa0JTkMu9BpqT74X {
		-ms-flex-align: start;
		align-items: start;
		border-top: thin dashed var(--theme-color-line);
		color: var(--theme-text-color-lighten);
		-ms-flex-direction: column;
		flex-direction: column;
		padding: .16rem 0 .3rem
	}

	.Tx8SM0ickmaT3XlvpBa3 li.B1TtAa0JTkMu9BpqT74X img {
		height: 1.25rem;
		margin-top: .12rem;
		width: 1.25rem
	}

	.PmwtECGo9wfGBMP0uy71 {
		color: var(--cms-primary-color);
		cursor: pointer
	}

	.PmwtECGo9wfGBMP0uy71 svg {
		font-size: .24rem;
		width: .24rem;
		height: .24rem
	}

	.vKDmG8Nz_Vcot47o6C7L .Chb8LxYkjJj1HlLlsG5G {
		-ms-flex-negative: 0;
		flex-shrink: 0;
		font-size: .24rem;
		margin-left: .1rem;
		position: relative;
		top: .02rem
	}

	.Tx8SM0ickmaT3XlvpBa3 li:last-child {
		margin-bottom: 0
	}

	.Tx8SM0ickmaT3XlvpBa3 li>span:first-child {
		color: var(--theme-text-color-lighten);
		-ms-flex: 1;
		flex: 1;
		max-width: 40%
	}

	.Tx8SM0ickmaT3XlvpBa3 li>span:last-child {
		color: var(--theme-text-color-darken);
		max-width: 60%;
		word-break: break-all
	}

	.Tx8SM0ickmaT3XlvpBa3 li .ImHReXiC3qHaglpWgdTK {
		-ms-flex-item-align: start;
		align-self: flex-start
	}

	.Tx8SM0ickmaT3XlvpBa3 li .s5Ow2TGiE3H2Yw1AxDNi {
		-ms-flex-align: center;
		align-items: center;
		display: -ms-flexbox;
		display: flex
	}

	.Tx8SM0ickmaT3XlvpBa3 li .s5Ow2TGiE3H2Yw1AxDNi img {
		border-radius: .05rem
	}

	.Tx8SM0ickmaT3XlvpBa3 li .JCvkz7A6x1jjaTlhnuDX {
		max-width: 3.3rem
	}

	.Tx8SM0ickmaT3XlvpBa3 li .PZ7EGYiPjnqzRGJ8oL6s {
		max-width: 2.7rem
	}

	.Tx8SM0ickmaT3XlvpBa3 li .DooNBP2n8v4ABSZfz_KA {
		display: -ms-flexbox;
		display: flex;
		line-height: 1.2;
		max-width: 2.7rem;
		text-align: right;
		word-break: break-all
	}

	.Tx8SM0ickmaT3XlvpBa3 li .DooNBP2n8v4ABSZfz_KA>span:first-child {
		max-width: 2.3rem;
		text-align: right
	}

	.Tx8SM0ickmaT3XlvpBa3 li .Md6HK8VKOeLdNOfHEVTY {
		color: var(--theme-primary-color);
		cursor: pointer;
		font-size: .27rem;
		margin-left: .1rem
	}

	.Tx8SM0ickmaT3XlvpBa3 li .XgqnfUsNuIo0CPXFCyLm {
		color: var(--theme-primary-color) !important;
		cursor: pointer
	}

	.Tx8SM0ickmaT3XlvpBa3 .ns96l6JACKUvG1AFxK8m {
		-ms-flex-align: start;
		align-items: flex-start
	}

	.Tx8SM0ickmaT3XlvpBa3 .xMEGQ0pRRv4sF_55Er_g {
		-ms-flex-align: start;
		align-items: start
	}

	.Tx8SM0ickmaT3XlvpBa3 .xMEGQ0pRRv4sF_55Er_g>span {
		word-break: break-word !important
	}

	.Tx8SM0ickmaT3XlvpBa3 .xMEGQ0pRRv4sF_55Er_g>div {
		-ms-flex-pack: center;
		display: -ms-flexbox;
		display: flex;
		-ms-flex: 1;
		flex: 1;
		justify-content: center
	}

	.Tx8SM0ickmaT3XlvpBa3 .xMEGQ0pRRv4sF_55Er_g .TfsHLL_9svRpFp1hyDt2 {
		-ms-flex-align: center;
		-ms-flex-pack: start;
		align-items: center;
		height: 1.5rem;
		justify-content: flex-start
	}

	.Tx8SM0ickmaT3XlvpBa3 .xMEGQ0pRRv4sF_55Er_g .TfsHLL_9svRpFp1hyDt2 .FKa0XIVD1gdhve7pK7sP {
		-ms-flex-align: center;
		-ms-flex-pack: center;
		align-items: center;
		border: .01rem solid var(--theme-primary-color);
		border-radius: .1rem;
		color: var(--theme-primary-color);
		display: -ms-flexbox;
		display: flex;
		font-size: .16rem;
		height: .5rem;
		justify-content: center;
		margin-left: .3rem;
		text-align: center;
		width: 1.2rem
	}

	.Tx8SM0ickmaT3XlvpBa3 .VMmO1mBS3iM9ZHdoRrx9 {
		font-weight: 700
	}

	.mwRRRArq5gAZYhhvbvhg {
		-ms-flex-align: center;
		align-items: center;
		border-bottom: thin dashed var(--theme-color-line);
		display: -ms-flexbox;
		display: flex;
		-ms-flex-direction: column;
		flex-direction: column;
		padding-bottom: .3rem;
		padding-top: .16rem
	}

	.mwRRRArq5gAZYhhvbvhg .AwOlFgTYfgvrUiDuREQ3 {
		border-radius: 50%;
		height: 1.1rem;
		width: 1.1rem;
	}

	.mwRRRArq5gAZYhhvbvhg .W81PYwak4A0iuAdse7m7 {
		font-size: .2rem;
		line-height: 1.2;
		margin: .2rem 0 .05rem
	}

	.mwRRRArq5gAZYhhvbvhg .ipChavXPGtlb9XQlgfgp {
		-ms-flex-align: center;
		-ms-flex-pack: center;
		align-items: center;
		color: var(--theme-text-color-darken);
		display: -ms-flexbox;
		display: flex;
		font-size: .4rem;
		height: .58rem;
		justify-content: center;
		line-height: .58rem;
		position: relative;
		text-align: center;
		width: 100%
	}

	.mwRRRArq5gAZYhhvbvhg .ipChavXPGtlb9XQlgfgp>span>span {
		font-size: .3rem
	}

	.mwRRRArq5gAZYhhvbvhg .hG9F2N5olLZbiOF2jkhS {
		color: var(--theme-text-color-darken);
		font-size: .22rem;
		margin-bottom: .1rem
	}

	.mwRRRArq5gAZYhhvbvhg .hG9F2N5olLZbiOF2jkhS .lYpwQ8PhWhUT4VrgkHkQ {
		color: var(--theme-secondary-color-error);
		display: inline-block;
		font-size: .24rem;
		margin: 0 .02rem
	}

	.mwRRRArq5gAZYhhvbvhg .hG9F2N5olLZbiOF2jkhS .lYpwQ8PhWhUT4VrgkHkQ span {
		background-image: none
	}

	.mwRRRArq5gAZYhhvbvhg .CBcpFLycz4SL5QOYR0S4 {
		color: var(--theme-text-color-lighten);
		font-size: .14rem;
		text-align: center
	}

	.mwRRRArq5gAZYhhvbvhg .CBcpFLycz4SL5QOYR0S4 span {
		color: var(--theme-secondary-color-finance);
		margin-left: .05rem
	}

	.mwRRRArq5gAZYhhvbvhg .jsW62VlEjiVBmbg1goxu {
		color: var(--theme-text-color-lighten);
		font-size: .18rem;
		text-align: center
	}

	.mwRRRArq5gAZYhhvbvhg .jsW62VlEjiVBmbg1goxu p {
		line-height: .3rem
	}

	.mwRRRArq5gAZYhhvbvhg .jsW62VlEjiVBmbg1goxu span {
		color: var(--theme-secondary-color-finance);
		margin-left: .05rem
	}

	.C8cXiFHgN044ZoXZdLnO,
	.C8cXiFHgN044ZoXZdLnO .ant-col {
		font-size: .2rem
	}

	.C8cXiFHgN044ZoXZdLnO .ant-col label,
	.C8cXiFHgN044ZoXZdLnO label {
		color: var(--theme-text-color-lighten);
		margin-right: .05rem
	}

	.C8cXiFHgN044ZoXZdLnO .ant-col span,
	.C8cXiFHgN044ZoXZdLnO span {
		color: var(--theme-text-color-darken)
	}

	.C8cXiFHgN044ZoXZdLnO .ant-col span .suffix,
	.C8cXiFHgN044ZoXZdLnO span .suffix {
		padding: 0 .05rem
	}

	.C8cXiFHgN044ZoXZdLnO .ant-col .dateTime,
	.C8cXiFHgN044ZoXZdLnO .dateTime {
		color: var(--cms-text-color-secondary);
		font-size: .2rem
	}

	.C8cXiFHgN044ZoXZdLnO .ant-col:first-child,
	.C8cXiFHgN044ZoXZdLnO:first-child {
		margin-right: .2rem
	}

	.C8cXiFHgN044ZoXZdLnO {
		padding: 0 0rem .2rem
	}

	.dG0FpGdkHU5MgpFCLljd {
		-ms-flex-align: center;
		-ms-flex-pack: center;
		align-items: center;
		background-color: #c12929;
		border-radius: .04rem 0 .04rem 0;
		display: -ms-flexbox;
		display: flex;
		height: .23rem;
		justify-content: center;
		left: 0;
		line-height: 1;
		position: absolute;
		top: 0;
		width: -webkit-fit-content;
		width: -moz-fit-content;
		width: fit-content;
		width: .3rem
	}

	.dG0FpGdkHU5MgpFCLljd .TmCsEwuUw4tM7cplmM6W {
		-webkit-text-fill-color: transparent;
		-webkit-background-clip: text;
		background-clip: text;
		background-image: -webkit-gradient(linear, left top, left bottom, color-stop(-5%, #f7ea94), color-stop(58%, #e5b952), color-stop(114%, #ce9510));
		background-image: linear-gradient(180deg, #f7ea94 -5%, #e5b952 58%, #ce9510 114%);
		font-size: .16rem;
		font-weight: 700;
		line-height: 1
	}

	.Iu2XDEUxSXVTmZ2ClDl5 .TJ8NUYQ9fq36xPLTbHQH {
		left: 0;
		position: absolute;
		top: 0
	}

	.Iu2XDEUxSXVTmZ2ClDl5 .vKDmG8Nz_Vcot47o6C7L {
		-ms-flex-align: center;
		-ms-flex-pack: start;
		align-items: center;
		display: -ms-flexbox;
		display: flex;
		justify-content: flex-start
	}

	.Iu2XDEUxSXVTmZ2ClDl5 .vKDmG8Nz_Vcot47o6C7L .Qu0jzrV6QQQRuKG_6idK {
		color: var(--cms-text-color-secondary);
		margin-right: .1rem;
		overflow: hidden;
		text-align: left;
		text-overflow: ellipsis
	}

	.Iu2XDEUxSXVTmZ2ClDl5 .vKDmG8Nz_Vcot47o6C7L .Chb8LxYkjJj1HlLlsG5G {
		-ms-flex-negative: 0;
		flex-shrink: 0;
		font-size: .24rem;
		margin-left: .1rem;
		position: relative;
		top: .02rem
	}

	.van-list>div .MlezEysRdS_J1OuFqOI4 {
		color: var(--theme-text-color-lighten);
		margin-right: .05rem
	}

	.van-list>div .currencyAmount,
	.van-list>div .suffix {
		color: var(--cms-text-color-secondary)
	}

	.vantDataList {
		font-size: .2rem;
		height: calc(-2.7rem + 80vh);
	}

	.ant-row-flex-space-between {
		-ms-flex-pack: justify;
		display: flex;
		justify-content: space-between
	}
</style>
