<template>
	<div style="background-color: aliceblue;">
		<HomeConter></HomeConter>
<!-- 		<svg data-v-9e2f8540="" data-v-00684976="" aria-hidden="true" class="svg-icon imageWrap">
			<use data-v-9e2f8540="" xlink:href="#icon-sprite_icon_dtfl_dz_0--svgSprite:all"></use>
			</svg> -->
		<div class="">
			<svg  v-for="(i,index) in svgSFunsLIst" class="svg-icon imageWrap" aria-hidden="true" >
			     <use :xlink:href="'#'+i" />
			</svg>
		</div>
	</div>
</template>

<script>
	import HomeConter from "@/components/home/HomeConter.vue"
	import four from "@/components/home/four.vue"
	const req = require.context('@/assets/icons/svg', false, /\.svg$/)
	console.log(req,'requirerequirerequire');
	console.log(req)
	// const requireAll = requireContext => requireContext.keys()
	// const re = /\.\/(.*)\.svg/
	// const icons = requireAll(req).map(i => {
	// 	console.log(i.match(re))
	//   return i.match(re)[1]
	// })
	// console.log(icons)
	export function requireAllContext(){
	  const req = require.context('@/assets/icons/svg', false, /\.svg$/);
	  const requireAll = requireContext => requireContext.keys().map(requireContext)
	  return requireAll(req)
	}
	let svgS=requireAllContext();
	function svgSFunsLIst(){
		let ions=[];
		svgS.map((item,index)=>{
			let children=item.default.node.children;
			for (let i =0;i<children[0].children.length;i++){
				ions.push(children[0].children[i].id)
			}
		})
		return ions;
	}
	
	console.log(svgSFunsLIst())
	// import Vue from 'vue'
	// import SvgIcon from '@/components/svgIcon'// svg component
	 
	// register globally
	// Vue.component('svg-icon', SvgIcon)
	 
	// const req = require.context('./svg', false, /\.svg$/)
	// const requireAll = requireContext => requireContext.keys().map(requireContext)
	// console.log(req,requireAll)
	export default{
		components:{
						HomeConter,
		},
		data() {
			return {
				svgSFunsLIst:svgSFunsLIst()
			}
		},
		
	}
</script>

<style scoped>
	.svg-icon {
	  vertical-align: -0.15em;
	  fill: currentColor;
	  overflow: hidden;
	  display: inline;
	  width: .36rem;
	  height: .36rem;
	}
	.svg-icon image{
		  vertical-align: -0.15em;
		  fill: currentColor;
		  overflow: hidden;
		  display: inline;
		 width: 100%;
		 height: 100%;
	}
</style>