import { render, staticRenderFns } from "./el-select-item.vue?vue&type=template&id=0dcaf113&scoped=true"
import script from "./el-select-item.vue?vue&type=script&lang=js"
export * from "./el-select-item.vue?vue&type=script&lang=js"
import style0 from "./el-select-item.vue?vue&type=style&index=0&id=0dcaf113&prod&lang=css"
import style1 from "./el-select-item.vue?vue&type=style&index=1&id=0dcaf113&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0dcaf113",
  null
  
)

export default component.exports