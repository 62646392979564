var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"promote SenhadeLogin"},[_c('van-nav-bar',{attrs:{"title":_vm.$t('security._SenhadeSaque'),"left-arrow":"","fixed":"","placeholder":""},on:{"click-left":_vm.onClickLeft}}),(!_vm.old_password)?_c('div',{staticClass:"I9T4OGduED94X6ahO3Qj"},[(!_vm.userInfo.set_withdraw_password)?_c('div',{staticClass:"DRcvr_ui3dMkJZpnft5Z"},[_vm._v(_vm._s(_vm.$t('security._Paraase')))]):_vm._e(),(!_vm.userInfo.set_withdraw_password)?_c('div',{staticClass:"Xq4eL9kY4HGkgyNzZTx4"},[_vm._v(_vm._s(_vm.$t('security._Definasuasenhadesaque')))]):_vm._e(),(_vm.userInfo.set_withdraw_password)?_c('div',{staticClass:"Xq4eL9kY4HGkgyNzZTx4"},[_vm._v("Alterar Senha de Saque")]):_vm._e(),_c('div',{staticClass:"Ou1aviPriFX421w97dGA"},[_c('span',[_vm._v(_vm._s(_vm.$t('security._NovaSenhadeSaque')))]),_c('i',{class:{
					isShowNum:_vm.isShowNum
				},on:{"click":function($event){_vm.isShowNum=!_vm.isShowNum}}},[_c('svg-icon',{attrs:{"className":"ZaAWGnvFuhPwlBk40od_","icon-class":`${_vm.isShowNum?'comm_icon_show--svgSprite:all':'comm_icon_hide--svgSprite:all'}`}})],1)]),_c('div',{staticClass:"conterArr"},[_c('ant-input-password',{staticStyle:{"margin-bottom":".5rem"},attrs:{"isShow":_vm.isShowNum},on:{"change":_vm.newPassword}}),(_vm.isShowPasword)?_c('div',{staticClass:"ant-form-explain"},[_vm._v(_vm._s(_vm.$t('security._Nãomesmosounúmeroscontínuos')))]):_vm._e()],1),_c('div',{staticClass:"Ou1aviPriFX421w97dGA"},[_c('span',[_vm._v(_vm._s(_vm.$t('security._Confirmenova')))]),_c('i',{class:{
					isShowNumConfirm:_vm.isShowNumConfirm
				},on:{"click":function($event){_vm.isShowNumConfirm=!_vm.isShowNumConfirm}}},[_c('svg-icon',{attrs:{"className":"ZaAWGnvFuhPwlBk40od_","icon-class":`${_vm.isShowNumConfirm?'comm_icon_show--svgSprite:all':'comm_icon_hide--svgSprite:all'}`}})],1)]),_c('div',{staticClass:"conterArr"},[_c('ant-input-password',{staticStyle:{"margin-bottom":".5rem"},attrs:{"isShow":_vm.isShowNumConfirm},on:{"change":_vm.new_passwordConfirm}}),(_vm.isShowNumConfirm)?_c('div',{staticClass:"ant-form-explain"},[_vm._v(_vm._s(_vm.$t('security._Nãomesmosounúmeroscontínuos')))]):(_vm.consistent)?_c('div',{staticClass:"ant-form-explain"},[_vm._v(_vm._s(_vm.$t('security._Senhaincorreta')))]):_vm._e()],1),_c('div',{staticClass:"FoD6bH3V5A47RnLkco6A"},[_vm._v(" "+_vm._s(_vm.$t('security._Atenção'))+" ")])]):_vm._e(),(_vm.old_password)?_c('div',{staticClass:"I9T4OGduED94X6ahO3Qj login-register-from-space"},[(_vm.userInfo.set_withdraw_password)?_c('div',{staticClass:"Xq4eL9kY4HGkgyNzZTx4"},[_vm._v("Verificar a senha para login")]):_vm._e(),_c('div',{staticStyle:{"margin-bottom":".2rem"}},[_c('antInput',{class:{
				 	'input-active':_vm.isFocus
				 },attrs:{"left-icon":"a","modelValue":_vm.password,"maxlength":"16","form-key":"password","type":_vm.passwordType,"placeholder":"Por favor, insira a nova senha"},on:{"focus":_vm.onFocus,"blur":_vm.onBlur,"onInput":_vm.onInput}},[_c('div',{staticStyle:{"display":"flex","align-items":"center"},attrs:{"slot":"left"},slot:"left"},[_c('svg-icon',{staticStyle:{"width":".26rem","height":".26rem","color":"var(--theme-text-color-lighten)"},attrs:{"className":"imageWrap","icon-class":"input_icon_mm--svgSprite:all"}})],1),_c('div',{attrs:{"slot":"right-icon"},on:{"click":function($event){return _vm.showHide('password')}},slot:"right-icon"},[_c('svg-icon',{staticStyle:{"width":".26rem","height":".26rem","color":"var(--theme-text-color-lighten)"},attrs:{"className":"right-icon","icon-class":_vm.passwordType=='text'?'comm_icon_show--svgSprite:all':'comm_icon_hide--svgSprite:all'}})],1)])],1),(_vm.passwordTypeValue)?_c('div',{staticClass:"ant-form-explain"},[_vm._v(_vm._s(_vm.passwordTypeValue))]):_vm._e(),_c('div',{staticStyle:{"color":"var(--theme-primary-color)","font-size":".22rem","text-align":"right"}},[_vm._v(" Outros Métodos de Verificação ")])]):_vm._e(),_c('div',{staticClass:"Xhr_XJt3jLTtAL8pC16D verification-btn__group wg-fixed-no-desktop",on:{"click":_vm.withdrawPassword}},[_c('button',{staticClass:"ant-btn ant-btn-primary ant-btn-block verification-btn__next",attrs:{"type":"button"}},[_vm._v(" "+_vm._s(!_vm.userInfo.set_withdraw_password || _vm.old_password ?_vm.$t('security._Confirmar'): 'Próximo')+" ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }