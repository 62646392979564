<template>
	<div class="promote">
		<van-nav-bar :title="$t('withdraw._DetalhesdeRetiradas')" left-arrow fixed placeholder @click-left="onClickLeft" />

		<!-- 
						_Paraasegurança:"Para a segurança da sua conta, introduza a palavra-passe de levantamento",
						_EsqueceuaSenha:"Esqueceu a Senha?",
						_Próximo:"Próximo",
						_InserirSenha:"Inserir Senha",
						_VerificarSenhadeSaque:"Verificar Senha de Saque",
						_DetalhesdeRetiradas:"Detalhes de Retiradas",
						_Tipodetransação:"Tipo de transação",
						_MétododeRetirada:"Método de Retirada",
						_HoradeCriação:"Hora de Criação",
						_Horadeexecução:"Hora de execução",
						_NúmerodoPedido:"Número do Pedido",
						_:"",
						_:"",
						_:"",
						_:"",
						_:"",
						_:"",

					{{ $t('withdraw._NúmerodoPedido') }}
					 
					-->
		<div class="ant-spin-container">
			<section class="qxkFxK6CQByKrJJkH2d4">
				<div class="sJE43QCoTSzPBDouVfxI">
					<!-- .I7L5BKKDvfc4LtZ9QV5x {
					    background-color: var(--theme-secondary-color-finance)
					}
					
					.Vp8Ab1eEpFjJfeNuKTzI {
					    background-color: var(--theme-secondary-color-success)
					}
					
					.mZdNUV1TD6aN7_ngmOtO {
					    background-color: var(--theme-secondary-color-error)
					} -->
					<div class="kcTCy9vHkf1H3mdqwzPu "
						:class="{
							I7L5BKKDvfc4LtZ9QV5x:itemInfo.status<=1,
							Vp8Ab1eEpFjJfeNuKTzI:itemInfo.status==2,
							mZdNUV1TD6aN7_ngmOtO:itemInfo.status==3,
						}"
					>
					<i data-id="sprite_main_comm_icon_pay_1"
							aria-hidden="true" focusable="false" class=""
							v-if="itemInfo.status==2"
							:style="{
								'background-image':`url(${_coloImg})`
							}"
							style="display: inline-block; position: relative; width: 1.1rem; height: 1.1rem;  background-position: -1.3rem -13.1057rem; background-size: 22.8171rem 22.2671rem;"></i>
							<i data-id="sprite_main_comm_icon_pay_1"
									aria-hidden="true" focusable="false" class=""
									v-if="itemInfo.status==1"
									:style="{
										'background-image':`url(${_coloImg})`
									}"
									style="display: inline-block; position: relative; width: 1.1rem; height: 1.1rem;  background-position: -3.6rem -13.057rem; background-size: 22.8171rem 22.2671rem;"></i>
									
							<i data-id="sprite_main_comm_icon_pay_1"
									aria-hidden="true" focusable="false" class=""
									v-if="itemInfo.status==3"
									:style="{
										'background-image':`url(${_coloImg})`
									}"
									style="display: inline-block; position: relative; width: 1.1rem; height: 1.1rem;  background-position: -2.44rem -13.1057rem; background-size: 22.8171rem 22.2671rem;"></i>
					</div>
					<p class="EW01JYMCEqV6B9J3qrcI KYYvz9FP2NopeXMcakPR" v-if="itemInfo.status<=1" >Esperando Retirada </p>
					<p class="EW01JYMCEqV6B9J3qrcI ndJ_zm5K047iicfJJUE1" v-if="itemInfo.status==2" >Retirada Bem Sucedida</p>
					<p class="EW01JYMCEqV6B9J3qrcI xxTQd0bNMWIQEWt9TUaI" v-if="itemInfo.status==3" >Retirada Cancelada</p>
					<!-- <p class="EW01JYMCEqV6B9J3qrcI ndJ_zm5K047iicfJJUE1">{{itemInfo.status}}</p> -->
					<p class="Qzkt5Y1kLOk8zRjHobrG"><span>R$ {{itemInfo.money}}</span></p>
				</div>
				<ul class="RHKUHsjbhO3fAAxlEGeg">
					<li><span>{{ $t('withdraw._Tipodetransação') }}</span><span>{{itemInfo.account_type}}</span></li>
					<li><span>{{ $t('withdraw._MétododeRetirada') }}</span><span class="SxjT_FOY1RN3ugh42aHQ"><span
								class="EFyusYPjNv2Oi2rPxsqY"><img
									src="./../../assets/icons/images/icon_normal_pix.png" alt="."
									data-blur="0" data-status="loaded"
									class="goDRiiBsuEuXD3W1NphN GgAalyCT_nMrUn3ge8Q8 use-skeleton" basescale="0.8"
									style="width: 0.4rem; height: 0.4rem;"></span><span>PIX</span></span></li>
					<li><span>{{ $t('withdraw._HoradeCriação') }}</span><span>{{itemInfo.create_time}}</span></li>
					<li v-if=" itemInfo.status==2 && itemInfo.update_time"><span>{{ $t('withdraw._Horadeexecução') }}</span><span>{{itemInfo.update_time}}</span></li>
					<li><span>{{ $t('withdraw._NúmerodoPedido') }}</span><span @click="copyContent(itemInfo.osn)" class="lxhhuvR4pdXvlw2qSAS5">{{itemInfo.osn}}<span
								class="PmwtECGo9wfGBMP0uy71 copyIcon VV3ZAK2KjUQCSD0J5Ii4">
								<svg-icon  style="width: .26rem;height: .26rem;margin-left:.2rem;color: var(--cms-primary-color);"  icon-class="comm_icon_copy--svgSprite:all"  />
								</span></span></li>
				</ul>
			</section>
		</div>
		
	</div>
</template>

<script>
	export default {
		data() {
			return {
				itemInfo: {}
			}
		},
		mounted() {
			let {
				content
			} = this.$route.query;
			if (content) {
				this.itemInfo = JSON.parse(content)
			}
		},
		methods: {
			onClickLeft() {
				this.$router.go(-1);
			}
		}
	}
</script>

<style scoped>
	.promote{
		min-height: 100vh;
		background-color: var(--cms-background-color-secondary);
	}
	.ant-spin-container {
		padding: .2rem;
	}
	.qxkFxK6CQByKrJJkH2d4 {
	    background-color: var(--cms-background-color-primary);
	    -webkit-box-shadow: 0 .03rem .09rem 0 rgba(0,0,0,.06);
	    box-shadow: 0 .03rem .09rem 0 rgba(0,0,0,.06);
	    min-height: 40vh;
		padding: .3rem .3rem 0;
		    position: relative;
	}
	.sJE43QCoTSzPBDouVfxI {
	    padding-top: .1rem
	}
	.kcTCy9vHkf1H3mdqwzPu {
	    height: 1.1rem;
	    width: 1.1rem;
		margin: 0 auto;
		border-radius: 50%;
	}
	.Vp8Ab1eEpFjJfeNuKTzI {
	    background-color: var(--theme-secondary-color-success)
	}
	.RHKUHsjbhO3fAAxlEGeg li .SxjT_FOY1RN3ugh42aHQ {
	    -ms-flex-align: center;
	    -ms-flex-pack: end;
	    align-items: center;
	    display: -ms-flexbox;
	    display: flex;
	    justify-content: flex-end
	}
	.EFyusYPjNv2Oi2rPxsqY{
		margin-right: .2rem;
	}
	.EW01JYMCEqV6B9J3qrcI{
		font-size: .24rem;
		    height: .6rem;
			text-align: center;
		    line-height: .6rem;
	}
	.KYYvz9FP2NopeXMcakPR {
	    color: var(--theme-secondary-color-finance)
	}
	
	.ndJ_zm5K047iicfJJUE1 {
	    color: var(--theme-secondary-color-success)
	}
	
	.xxTQd0bNMWIQEWt9TUaI {
	    color: var(--cms-text-color-secondary)
	} 
	.I7L5BKKDvfc4LtZ9QV5x {
	    background-color: var(--theme-secondary-color-finance)
	}
	
	.Vp8Ab1eEpFjJfeNuKTzI {
	    background-color: var(--theme-secondary-color-success)
	}
	
	.mZdNUV1TD6aN7_ngmOtO {
	    background-color: var(--theme-secondary-color-error)
	}
.sJE43QCoTSzPBDouVfxI .Qzkt5Y1kLOk8zRjHobrG {
    font-size: .5rem;
    height: .73rem;
    line-height: .73rem
}
.Qzkt5Y1kLOk8zRjHobrG {
    color: var(--cms-primary-color);
    font-size: .4rem;
    height: .58rem;
    line-height: .58rem;
    text-align: center;
    vertical-align: middle;
    width: 100%
}
.RHKUHsjbhO3fAAxlEGeg li {
    border: none;
    font-size: .24rem;
    line-height: .26rem;
    min-height: .26rem
}
.RHKUHsjbhO3fAAxlEGeg li>span:last-child {
    color: var(--cms-text-color-secondary);
    -ms-flex: 1;
    flex: 1;
    margin-left: 1.2rem;
    text-align: right;
    word-break: break-all
}
.qxkFxK6CQByKrJJkH2d4 .RHKUHsjbhO3fAAxlEGeg li:first-child {
    border-top: thin dashed var(--theme-color-line);
}
.qxkFxK6CQByKrJJkH2d4 .RHKUHsjbhO3fAAxlEGeg li {
    -ms-flex-align: center;
    -ms-flex-pack: justify;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    font-size: .2rem;
    justify-content: space-between;
    line-height: .26rem;
    min-height: .26rem;
    padding: .2rem 0;
}
.RHKUHsjbhO3fAAxlEGeg li>span:first-child {
    color: var(--theme-text-color-lighten);
}
.qxkFxK6CQByKrJJkH2d4 .RHKUHsjbhO3fAAxlEGeg {
    padding-bottom: .2rem;
    padding-top: .3rem;
}
.qxkFxK6CQByKrJJkH2d4 .RHKUHsjbhO3fAAxlEGeg {
    padding-bottom: .15rem;
}
</style>
