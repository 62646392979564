<template>
    <div class="maxMaxth promote promoteMy  tab_name">
		<van-nav-bar
		  title="Relatório"
		  left-arrow
		  fixed
		  placeholder
		  @click-left="onClickLeft"
		/>
		<van-tabs v-model="activeName"
		@click="routerFun" 
		class="h"
		:lineWidth="(activeLength*6+'px')"
		animated
		line-height='1px'
		sticky
		offset-top=".9rem"
		background="var(--cms-background-color-primary)" :ellipsis="false"
		title-active-color="var(--cms-primary-color)"
		title-inactive-color="var(--cms-text-color-primary)"
		color="var(--cms-primary-color)"
		>
			<van-tab  title-style="font-size: 0.22rem;" v-for="(item,index) in tabList"  :title="$t(item.name)" :name='index' :key="index" >
				<component :is="item.content"></component>
			</van-tab>
		</van-tabs>
     </div>
  </template>
  
  <script>
	import Conta from "./Conta.vue"
	import Apostas from "./Apostas.vue"
	import Relatório from "./Relatório.vue"
	
  export default {
   components: {
   },
   data(){
     return {
        activeName:0,
        activeLength:7,
        tabList:[
                {name:'report._Conta',content:Conta},
				{name:'report._Apostas',content:Apostas},
				{name:'report._Relatório',content:Relatório},
            ]
     }
   },
   mounted(){
	let {current}=this.$route.query;
	this.activeName=Number(current) || 0;
   },
//    
   methods:{
     tabFun(e){
       this.$store.commit('active',e)
     },
     routerFun(e,l){
        this.activeLength=l.length;
     },
	 onClickLeft(){
	 		 this.$router.go(-1);
	 },
   }
  }
  
  </script>
  <style>
	  .promote .van-nav-bar {
	  	background: var(--cms-background-color-primary);
	  	border-bottom: .01rem solid var(--cms-background-color-primary);
	  	color: var(--cms-text-color-primary);
	  }
	  
	  .promote .van-nav-bar__title {
	  	color: var(--cms-text-color-primary);
	  }
	  
	  .promote .van-nav-bar .van-icon {
	  	color: var(--cms-text-color-secondary);
	  }
	  
	  .promote [class*=van-hairline]::after{
			border-bottom: .01rem solid var(--cms-divider-color) !important;
	  }
	  .promote .van-tabs div:first-child{
		  /* height: 100% !important; */
	  }
	  .promote .van-tabs--line .van-tabs__wrap{
		  height: .71rem;
		  border-bottom: .02rem solid var(--cms-divider-color);
	  }
	  .promote .van-tab--active {
	      font-weight: 400 !important;
	  }
	  .promote .h .van-tab__pane {
	      -ms-flex: 1;
	      flex: 1;
	      font-size: .2rem;
	      height: calc(100vh - 1.65rem);
	      height: calc(var(--vh, 1vh)* 100 - 1.65rem);
	      overflow: scroll;
	      width: 100%;
	  }
	 .tab_name .van-tabs__wrap--scrollable .van-tab{
		  flex: none !important; 
	  }
	 .promote .el-select{
		     /* height: .5rem; */
			 max-width: 3.15rem;
			 min-width: 1.6rem;
			 height: .5rem !important;
			 background-color: var(--cms-background-color-primary);
			 transition: all .3s cubic-bezier(.645, .045, .355, 1);
			 border-radius: .5rem;
			 line-height: .5rem;
			 box-sizing: border-box;
			 overflow: hidden;
			 border: .01rem solid var(--cms-divider-color);
	 }
	  .promote .el-select .el-input{
		 display: flex;
		 align-items: center;
		 
	 }
	.promote .el-input__inner{
		 height: 100%;
		 background-color: var(--cms-background-color-primary);
		 border: none;
		 font-size: .2rem;
		 display: flex;
		 justify-content: center;
		 align-items: center;
		 text-indent: 0.2rem;
		 height: .5rem;
		 padding: 0 !important;
		 margin-right: .5rem;
		 line-height: .5rem;
		 color: var(--cms-text-color-secondary) !important;
	 }
	 .promote .el-input__inner{
		 overflow: hidden;
		 text-overflow: ellipsis;
		 white-space: nowrap;
		 overflow:hidden; white-space:nowrap; text-overflow:ellipsis;
	 }
	 .el-select .el-input__inner:focus,.el-range-editor.is-active, .el-range-editor.is-active:hover, .el-select .el-input.is-focus .el-input__inner{
		 /* border: 0.01rem solid var(--theme-primary-color) !important; */
		 border: none;
	 }
	.promote .el-input__suffix{
		 height: auto;
		 display: inline-block;
		 /* right: -.4rem; */
	 }
	.el-select .el-input .el-select__caret{
		    color: var(--cms-text-color-secondary) !important;
	}
	.promote .el-input__icon{
		line-height: .5rem;
	}
  </style>
  <style scoped>
	.promote{
		    background-color: var(--cms-background-color-secondary) !important;
	}
	.tab_name .pBg296B2aLWiyWbbg5An {
		-ms-flex-line-pack: space-evenly;
		align-content: space-evenly;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		font-size: .22rem;
		margin-bottom: 0;
		min-height: 1rem;
		padding: .05rem .2rem .1rem;
		width: 100%
	}
	.tab_name .pBg296B2aLWiyWbbg5An .RjCX53sI34q23D2TwPWl {
	    -ms-flex-align: center;
	    -ms-flex-pack: center;
	    align-items: center;
	    display: -ms-flexbox;
	    display: flex;
	    justify-content: center;
	    margin: 0;
	    width: 50%
	}
	.tab_name .pBg296B2aLWiyWbbg5An .RjCX53sI34q23D2TwPWl>span {
	    -webkit-line-clamp: 2;
	    -webkit-box-orient: vertical;
	    display: -webkit-box;
	    margin: 0;
	    overflow: hidden;
	    padding-right: .05rem;
	    text-overflow: ellipsis;
	    vertical-align: middle;
	    width: 50%;
	    word-break: break-all
	}
	.tab_name .pBg296B2aLWiyWbbg5An {
	    -ms-flex-line-pack: space-evenly;
	    align-content: space-evenly;
	    display: -ms-flexbox;
	    display: flex;
	    -ms-flex-wrap: wrap;
	    flex-wrap: wrap;
		    color: var(--theme-text-color);
	    font-size: .22rem;
		font-weight: 200;
	    margin-bottom: 0;
	    min-height: 1rem;
	    padding: .05rem .2rem .1rem;
	    width: 100%
	}
	.tab_name .RjCX53sI34q23D2TwPWl>span:last-child {
	    padding-left: .2rem;
	    padding-right: 0
	}
	.tab_name .pBg296B2aLWiyWbbg5An .HGHvRuWIBhpGiBhkEAHu {
	    color: var(--theme-text-color-darken);
	}
	
	.tab_name .pBg296B2aLWiyWbbg5An .HNKlmlfGsE25ksqykrVs {
	    color: var(--theme-secondary-color-error);
	}
	.tab_name .pBg296B2aLWiyWbbg5An .VsQlwP6H52Vyv4bEeq1q {
	    color: var(--cms-global-secondary1-color);
	}
	
	.tab_name .pBg296B2aLWiyWbbg5An .kfrOXvCeC3B_UykAtxE_ {
	    color: var(--cms-text-color-primary);
	}
	
	.tab_name .pBg296B2aLWiyWbbg5An .cVihnbOVCk0AV6cIk86g {
	    color: var(--cms-global-secondary2-color);
	}
	
  </style>
  