<template>
  <div>
	  <van-search
	    v-bind="$attrs"
	    v-on="$listeners"
	    v-model="modelValue"
		@input="onInput"
		@clear="onClear"
		@focus="isFus"
		@blur="isFun"
		:disabled="disabled"
		:left-icon='leftIcon'
		:class="{
			'input-active':isFocus
		}"
	  >
	    <template v-for="(_, slot) of $slots"  v-slot:[slot]="scope">
	      <slot :name="slot" :clearable="clearable" v-bind="scope"></slot>
	    </template>
	  </van-search>
  </div>
</template>
 
<script>
import { ref, watch, defineComponent } from 'vue';
import { Search } from 'vant';
export default defineComponent({
  name: 'InheritSearch',
  components: {
    VanSearch: Search,
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
	disabled:{
		type: Boolean,
		default: false,
	},
	formKey:{
      type: String,
      default: '',
    },
	...Search.props
  },
  data() {
  	return{
		isFocus:false,
	}
  },
  methods:{
	isFun(e) {
		this.isFocus = false;
		this.$emit('blur',e,this.formKey)
	},  
	isFus(e){
		this.isFocus = true;
		this.$emit('focus',e,this.formKey)
	}
  },
  setup(props, { emit }) {
    const currentValue = ref(props.modelValue);
	const formKey = ref(props.formKey);
    const onInput = (value) => {
      currentValue.value = value;
      emit('update:modelValue', value);
	  emit('onInput', value,formKey.value);
    };
 
    const onClear = (event) => {
      currentValue.value = '';
      emit('update:modelValue', '');
      emit('clear', event);
    };
 
    watch(() => props.modelValue, (value) => {
      currentValue.value = value;
    });
 
    return {
      currentValue,
      onInput,
      onClear,
    };
  },
});
</script>
<style>
	.z-ant-input .van-search {
		display: flex;
		align-items: center;
		border-radius: .14rem;
		font-size: .22rem;
		height: .7rem;
		background-color: var(--cms-background-color-primary);
		border: 1px solid #d9d9d9;
		padding-right: .1rem;
		border-color: var(--cms-divider-color);
	}
	
	.z-ant-input .van-checkbox__icon .van-icon {
		border-radius: .06rem;
		border-color: var(--theme-color-line);
	}
	.z-ant-input .van-ellipsis{
		position: relative;
	}
	.z-ant-input .van-cell__title{
		position: relative;
		padding-left: 0.42rem;
	}
	.z-ant-input .van-cell__title:before,.z-ant-input .van-ellipsis:before{
			content: '';
			display: inline-block;
		    position: relative;
		    width: 0.36rem;
		    height: 0.36rem;
			position: absolute;
			overflow: hidden;
			background-image: url('./../../assets/5e8fdeaf-a95f-4566-adac-d84c9605a176.png');
			background-position: -8.58857rem -3.35571rem;
			background-size: 9.33429rem 9.10929rem;
	}
	.z-ant-input .van-cell__title:before{
		left: 0;
		top: .2rem;
	}
	
	.z-ant-input .van-search__content {
		background-color: var(--cms-background-color-primary);
	}
	
	.z-ant-input .van-checkbox__label {
		display: flex;
		align-items: center;
	}
	
	.z-ant-input .data-user-agreement {
		color: var(--theme-primary-color)
	}
	
	.z-ant-input input::-webkit-input-placeholder {
		/* 使用webkit内核的浏览器 */
		color: var(--cms-text-color-secondary) !important;
		line-height: .3rem !important;
		font-size: .22rem !important;
		font-weight: 400 !important;
	}
	
	.z-ant-input input:-moz-placeholder {
		/* Firefox版本4-18 */
		color: var(--cms-text-color-secondary) !important;
		line-height: .3rem !important;
		font-size: .22rem !important;
		font-weight: 400 !important;
	}
	
	.z-ant-input input::-moz-placeholder {
		/* Firefox版本19+ */
		color: var(--cms-text-color-secondary) !important;
		line-height: .3rem !important;
		font-size: .22rem !important;
		font-weight: 400 !important;
	}
	
	.z-ant-input input:-ms-input-placeholder {
		/* IE浏览器 */
		color: var(--cms-text-color-secondary) !important;
		line-height: .3rem !important;
		font-size: .22rem !important;
		font-weight: 400 !important;
	}
	.z-ant-input .van-field__control:disabled{
		-webkit-text-fill-color: var(--cms-text-color-secondary) !important;
	}
	
	
	.input-active {
		/* var(--theme-primary-color); */
		box-shadow: 0 2px 0 rgba(0, 0, 0, .045);
		border: .02rem solid var(--cms-primary-color) !important;
	}
	
	
</style>