<template>
	<van-dropdown-menu>
	  <van-dropdown-item v-model="value" :options="option" />
	  <van-dropdown-item title="筛选" ref="item">
	    <van-cell center title="包邮">
	      <template #right-icon>
	        <van-switch v-model="switch1" size="24" active-color="#ee0a24" />
	      </template>
	    </van-cell>
	    <van-cell center title="团购">
	      <template #right-icon>
	        <van-switch v-model="switch2" size="24" active-color="#ee0a24" />
	      </template>
	    </van-cell>
	    <div style="padding: 5px 16px;">
	      <van-button type="danger" block round @click="onConfirm">
	        确认
	      </van-button>
	    </div>
	  </van-dropdown-item>
	</van-dropdown-menu>
</template>

<script>
	export default {
	  props: {
	      showPopup:{
	          type: Boolean,
	          required: false,
	      },
	  },
	  data() {
	    return {
	      value: 0,
	            switch1: false,
	            switch2: false,
	            option: [
	              { text: '全部商品', value: 0 },
	              { text: '新款商品', value: 1 },
	              { text: '活动商品', value: 2 },
	            ],
	    };
	  },
	  methods: {
	      
	 
	  }
	};
	
</script>

<style>
</style>