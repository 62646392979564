<template>
	<div class="promote">
		<van-nav-bar :title="title" left-arrow fixed placeholder @click-left="onClickLeft" />
		<div v-if="isConter" style="padding: .2rem;font-size: 0.22rem;">
			<div class="ejXficlz5ysFaJf0CHhl"  v-html="content">
				
			</div>
		</div>
		<div v-else  style="font-size: .22rem;padding: .2rem;color: var(--theme-text-color-darken);" v-html="content">
		</div>
	</div>
</template>

<script>
	export default{
		data() {
			return {
				title:'',
				content:'',
				isConter:false
			}
		},
		created(e) {
			let {title,content,isConter}=this.$route.query
			this.title=title;
			this.content=content;
			this.isConter=isConter;
			
		},
		methods:{
			onClickLeft(){
				this.$router.go(-1);
			}
		}
	}
</script>

<style scoped>
	.promote{
		min-height:100vh;
		background-color: var(--cms-background-color-secondary);
	}
	.ejXficlz5ysFaJf0CHhl{
		    background-color: var(--cms-background-color-primary);
		    min-height: 5.8rem;
		    padding: .2rem;
		    word-break: break-word;
			    color: var(--cms-text-color-secondary);

			border-radius: .14rem;
			    box-shadow: 0 .03rem .1rem 0 rgba(0, 0, 0, .06);
	}
</style>