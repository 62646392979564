<template>
	<div  style="height: 100vh;position: relative;">
		
		<div class="quaegx_TcjzjWuZxCKqt">
			<div class="CM15xXfS14zAORKzMcKf">
				<h3 class="nn6wIpAkKKTREHoL7oe8">
					{{ $t('withdraw._Contapararecebimento') }}<span>(1/{{contaDeLevantamento?'1':0}})</span>
					<span @click="animate" class="ZaAWGnvFuhPwlBk40od_" style="display: flex;">
						<svg-icon style="width: 100%;height: 100%;"
							icon-class="comm_icon_retry--svgSprite:all" />
					</span>
				</h3>
				<span  @click="isShowNum=!isShowNum" style="display: flex;" :class="{
					active:isShowNum
				}">
					<svg-icon className="ZaAWGnvFuhPwlBk40od_"
						:icon-class="`${isShowNum?'comm_icon_show--svgSprite:all':'comm_icon_hide--svgSprite:all'}`" />
				</span>
			</div>
			<div @click="isShow=true">
				<antST @_click="isShow=true"
				  :active="true"
				  :contaDeLevantamentoFromDta="$attrs.contaDeLevantamentoFromDta"  :disabled="true"></antST>
			</div>
		</div>
		<div class="quaegx_TcjzjWuZxCKqt"  @click="isShow=true" v-if="!contaDeLevantamento">
			<div  style="display: flex;flex: 1;">
				<antT @_click="isShow=true"></antT>
			</div>
		</div>
		
		<!-- <senha :isShow='isShow' @_click="isShow=false"></senha> -->
		<plx :isShow='isShow'  @onIsShow="isShow=false" :formDta="$attrs.contaDeLevantamentoFromDta"  :getBank="$attrs.getBank" @_click="isShow=false"></plx>
		
	</div>
</template>

<script>
	import antT from "./ant-t.vue"
	import senha from "./senha.vue"
	import plx from "./plx.vue"
	import antST from "./ant-s-t.vue"
	export default{
		components:{
			antT,
			senha,
			antST,
			plx
		},
		props:{
			contaDeLevantamento:{
				type: Boolean,
				default: false,
			},
		},
		// 
		data() {
			return{
				isShow:false,
				isShowNum:false,
			}
		},
		mounted() {
		},
		methods:{
			animate(e){
				let target=e.target;
				let tagName=target.tagName.toLowerCase()
				if(tagName=='svg'){
					target.classList.add('animate__manimated');
					let time=setTimeout(()=>{
						clearTimeout(time)
						target.classList.remove('animate__manimated')
					},300)
				}
			}
		}
		
	}
</script>

<style scoped>
	.quaegx_TcjzjWuZxCKqt {
		background-color: var(--cms-background-color-primary);
		border-radius: .14rem;
		margin: .2rem;
		max-height: 50%;
		overflow: hidden;
		padding: .2rem;
		box-sizing: border-box;
		width: calc(100% - .4rem);
	}
	.bootom_slis{
		/* position: absolute; */
		/* bottom: 0; */
		/* left: 0; */
		
		display: flex;
		bottom: .7rem;
		box-sizing: border-box;
		width: 100vw;
		z-index: 2;
		background-color: var(--theme-main-bg-color);
		border-radius: .14rem .14rem 0 0;
		-webkit-box-shadow: 0 -.06rem .1rem 0 var(--theme-bg-shadow);
		box-shadow: 0 -.06rem .1rem 0 var(--theme-bg-shadow);
		max-height: 50%;
		padding: .2rem;
	}
	.CM15xXfS14zAORKzMcKf{
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.nn6wIpAkKKTREHoL7oe8{
		display: flex;
		align-items: center;
		font-size: .22rem;
		color: var(--cms-text-color-primary)
	}
	.nn6wIpAkKKTREHoL7oe8 span {
	    color: var(--cms-text-color-secondary);
	    font-weight: 400;
	    margin-left: .06rem;
		margin-right: .1rem;
	}
	.nn6wIpAkKKTREHoL7oe8 .ZaAWGnvFuhPwlBk40od_{
		    color: var(--cms-primary-color);
		    font-size: .3rem;
			width: .3rem;
			height: .3rem;
	}
	.active .ZaAWGnvFuhPwlBk40od_{
		 color: var(--cms-primary-color);
	}
	.ZaAWGnvFuhPwlBk40od_{
		    font-size: .36rem;
			width: .36rem;
			height: .36rem;
		    margin-right: .15rem;
			    color: var(--cms-text-color-secondary);
	}
</style>
