<template>
	<div class="R57xbU1vEwuho81iaSVT box-shadow">
		<div class="R57xbU1vEwuho81iaSVT-conter">
			<van-row>
				<van-col span="12">
					<datetimePicker  @_timeOnFunEndDate="_timeOnFunEndDate" class="ifzwCQu8BPs2c24nZdzQ"></datetimePicker>
				</van-col>
				<van-col span="12">
					<label> {{$t('promote._ComissõesTotais')}}</label>
					<span class="currencyAmount">  0,00</span>
				</van-col>
			</van-row>
			<van-row>
				<van-col span="12">
					<label> {{$t('promote._ComissãoDireta')}} </label><span class="currencyAmount"> 0,00</span>
				</van-col>
				<van-col span="12">
					<label>{{$t('promote._OutraComissão')}}</label><span class="currencyAmount"> 0,00</span>
				</van-col>
			</van-row>
		</div>
		<div class="TBtGFT5INIot5sEUIm8w vantDataList">
			<!-- v-if="shareInfo.list && shareInfo.list.length" -->
			<van-empty image-size="2.1rem" class="custom-image"
				:image="img_none_jl"
				:description="$t('description')" />
		</div>
	
		
	</div>
</template>

<script>
	import datetimePicker from "@/components/searchFor/datetimePicker.vue";
	import searchFor from "@/components/searchFor/searchFor.vue";
	import {
		sharePerformance
	} from "@/apis/modules/share.js";
	export default {
		name: 'vip',
		components: {
			datetimePicker,
			searchFor
		},
		data() {
			return {
				shareInfo: {

				},
				finished: false,
				formData: {
					start_time: '',
					end_time: "",
				}
			}
		},
		mounted() {
			// this.sharePerformance();
		},
		methods: {
			_timeOnFunEndDate(Tim, anD) {
				this.formData.start_time = Tim;
				this.formData.end_time = anD;
				// this.sharePerformance();
			},
			sharePerformance() {
				return false;
				sharePerformance(this.formData).then(({
					data
				}) => {
					this.shareInfo = data;
				})
			},
			tabIndexTime(index, time) {
				this.shareTime = time;
				this.active = index;
				this.sharePerformance()
			},
		},
	}
</script>
<style>
	.R57xbU1vEwuho81iaSVT-conter .ant-col {
		-ms-flex-align: center;
		align-items: center;
		color: var(--theme-text-color-darken);
		display: -ms-flexbox;
		display: flex;
	}

	.R57xbU1vEwuho81iaSVT-conter .van-row {
		justify-content: center;
		display: flex;
		align-items: center;
	}

	.R57xbU1vEwuho81iaSVT-conter .van-col {
		margin-bottom: .2rem;
	}

	.R57xbU1vEwuho81iaSVT-conter .van-col label {
		color: var(--cms-text-color-secondary);
		margin-right: .1rem;
	}

	.R57xbU1vEwuho81iaSVT-conter .currencyAmount {
		color: var(--cms-text-color-primary);
	}
</style>
<style scoped>
	.ifzwCQu8BPs2c24nZdzQ {
		margin-right: .2rem;
	}

	.R57xbU1vEwuho81iaSVT {
		padding: .2rem;
	}

	.R57xbU1vEwuho81iaSVT-conter {
		background-color: var(--cms-background-color-primary);
		border-radius: .1rem;
		padding: .2rem .2rem 0
	}
	.TBtGFT5INIot5sEUIm8w.vantDataList .van-list>div .van-col label {
	    color: var(--theme-text-color-lighten);
	    margin-right: .1rem
	}
	.TBtGFT5INIot5sEUIm8w.vantDataList .van-list>div .van-col .active {
	    border-bottom: .01rem solid var(--theme-primary-color);
	    color: var(--theme-primary-color)
	}

	.TBtGFT5INIot5sEUIm8w.vantDataList .van-list>div .van-col .dateTime {
	    color: var(--theme-text-color-darken);
	    font-size: .2rem
	}
	.TBtGFT5INIot5sEUIm8w.vantDataList .van-list>div .van-col {
	    color: var(--theme-text-color-darken)
	}
	.TBtGFT5INIot5sEUIm8w.vantDataList .van-list>div .van-row--flex:not(:last-child) {
	    margin-bottom: .2rem;
		font-weight: ;
	}
	

</style>
