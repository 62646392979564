<template>
	<div class="promote SenhadeLogin">
			<van-nav-bar title="Senha de Login" left-arrow fixed placeholder @click-left="onClickLeft" />
		<div class="login-register-from-space " v-show="!old_password"  >
			<div class="X5eogfqvbxD_ckZVQSll" style="padding-bottom: .4rem;">Alterar Senha</div>
			<div class="" style="margin-bottom: .2rem;">
				<antInput
					left-icon="a" :modelValue="formConter.password"
						@focus="onFocus"
						@blur="onBlur"
						@onInput="onInput"
						maxlength="16"
						form-key='password'
						:type="fonData.password.type"
						placeholder="Por favor, insira a nova senha" :class="{
					 	'input-active':isFocus
					 }"
				>
					<div slot="left" style="display: flex;align-items: center;">
						<svg-icon className="imageWrap"
							style="width: .26rem;height: .26rem;color: var(--cms-text-color-secondary);"
							icon-class="input_icon_mm--svgSprite:all" />
						<span class="ZEcfoKF3Q_zq5EqaOdUy">
							<i v-if="!formConter.password">*</i>
						</span>
					</div>
					<!-- fonData.password.type -->
					<div slot="right-icon" @click="showHide('password')">
						<svg-icon className="right-icon"
							style="width: .26rem;height: .26rem;color: var(--cms-text-color-secondary);"
							:icon-class="fonData.password.type=='text'?'comm_icon_show--svgSprite:all':'comm_icon_hide--svgSprite:all'" />
					</div>
				</antInput>
			</div>
			<div class="ant-form-explain" v-if="fonData.password.value">{{fonData.password.value}}</div>
			
			<div class="ant-space-item" style="margin-bottom: 0px;">
				<div class="ptEr7ybENfgNXBPMnsRb">
					<div class="rBX8yUXrZ3DzhJ4ZvZUD">
						<span>Força</span>
						<span v-for="index in 4" :key="index" :style="{
							    backgroundColor:mimaQiangdu==4?'var(--cms-text-color-secondary) !important':''
						}" :class="{
							QZ0QekXF9duW7ClyMSdQ:index<=mimaQiangdu && formConter.password,
							
						}" class="VYe1C86O9HyF7KaCqIUg "></span>
					</div>
				</div>
			</div>
			<!-- 1v-if="fonData.password_confirm." -->
			<div class="" style="margin-bottom: .2rem;"  >
				<antInput
					left-icon="a" :modelValue="formConter.password_confirm"
						@focus="onFocus"
						@blur="onBlur"
						@onInput="onInput"
						maxlength="16"
						form-key='password_confirm'
						:type="fonData.password_confirm.type"
						placeholder="Confirmar Novo Senha" :class="{
					 	'input-active':isFocus
					 }"
				>
					<div slot="left" style="display: flex;align-items: center;">
						<svg-icon className="imageWrap"
							style="width: .26rem;height: .26rem;color: var(--cms-text-color-secondary);"
							icon-class="input_icon_mm--svgSprite:all" />
						<span class="ZEcfoKF3Q_zq5EqaOdUy">
							<i v-if="!formConter.password_confirm">*</i>
						</span>
					</div>
					<div slot="right-icon" @click="showHide('password_confirm')">
						<svg-icon className="right-icon"
							style="width: .26rem;height: .26rem;color: var(--cms-text-color-secondary);"
							:icon-class="fonData.password_confirm.type=='text'?'comm_icon_show--svgSprite:all':'comm_icon_hide--svgSprite:all'" />
					</div>
				</antInput>
			</div>
			<div class="ant-form-explain" v-if="fonData.password_confirm.value && fonData.password.type!='text'">{{fonData.password_confirm.value}}</div>
		<!-- 	<div>
				
				<div class="" style="display: flex;align-items: center;padding-bottom: .2rem;">
					<button type="button"
						style="border-radius: .14rem;"
						@click="registerUrl"
						class="GaL3XJonIwzK4ZeJyCyq">
						{{ $t("Login._Registro") }}
					</button>
				</div>
			</div> -->
			
		</div>
		<div class="login-register-from-space" v-if="old_password">
			<div class="X5eogfqvbxD_ckZVQSll" style="padding-bottom: .4rem;">Verifique a senha de login antiga</div>
			<div class="" style="margin-bottom: .2rem;">
				<antInput
					left-icon="a" 
					:modelValue="formConter.old_password"
						@focus="onFocus"
						@blur="onBlur"
						@onInput="onInput"
						maxlength="16"
						form-key='old_password'
						:type="fonData.old_password.type"
						placeholder="Por favor, insira a nova senha" :class="{
					 	'input-active':isFocus
					 }"
				>
					<div slot="left" style="display: flex;align-items: center;">
						<svg-icon className="imageWrap"
							style="width: .26rem;height: .26rem;color: var(--cms-text-color-secondary);"
							icon-class="input_icon_mm--svgSprite:all" />
					</div>
					<!-- fonData.password.type -->
					<div slot="right-icon" @click="showHide('old_password')">
						<svg-icon className="right-icon"
							style="width: .26rem;height: .26rem;color: var(--cms-text-color-secondary);"
							:icon-class="fonData.old_password.type=='text'?'comm_icon_show--svgSprite:all':'comm_icon_hide--svgSprite:all'" />
					</div>
				</antInput>
			</div>
			<div class="ant-form-explain" v-if="fonData.old_password.value">{{fonData.old_password.value}}</div>
			<div style="    color: var(--theme-primary-color);font-size: .22rem;text-align: right;">
				Outros Métodos de Verificação
			</div>
		</div>
		
		<div @click="registerUrl" class="Xhr_XJt3jLTtAL8pC16D verification-btn__group wg-fixed-no-desktop">
			<button type="button"
				class="ant-btn ant-btn-primary  verification-btn__next"
				>
				Próximo
			</button>
		</div>
	</div>
</template>

<script>
	function checkPasswordStrength(password) {
	  let score = 0;
	  if (password.length > 6) score++;
	  if (password.length > 0) score++;
	  if(password.length >= 6){
		  if (password.match(/[A-Z]/)) score++; // 大写字母
		  if (password.match(/[a-z]/)) score++; // 小写字母
		  if (password.match(/[0-9]/)) score++; // 数字
		  if (password.match(/[\W_]/)) score++; // 特殊字符
	  }
	  if (password.length > 10) score++;
	  switch (score) {
	    case 0:
	    case 1:
	    case 2:
	    case 3:
	    case 4:
	      return 1; // 弱
	    case 5:
	      return 2; // 中等
		case 6:
			return 3; // 强
	    case 7:
		default:
		  return 4; // 非常强
	  }
	}
	function validatePassword(password) {
	    if (password.length < 6) {
	            return false; // 密码长度必须为6位
	        }
	        // 定义正则表达式来检查不同的字符类型
	        const hasDigit = /\d/.test(password);
	        const hasLower = /[a-z]/.test(password);
	        const hasUpper = /[A-Z]/.test(password);
	        const hasSpecial = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password); // 定义一些常见的特殊字符
	        // 计算不同类型的字符数（这里实际上只关心是否存在，所以是布尔值）
	        let typeCount = 0;
	        if (hasDigit) typeCount++;
	        if (hasLower) typeCount++;
	        if (hasUpper) typeCount++;
	        if (hasSpecial) typeCount++;
	        // 检查是否至少有两种字符类型
	        return typeCount >= 2;
	}
	import register from "@/components/logoin/register.vue"
	import antInput from "@/components/logoin/input.vue"
	import {pustRegister,changeLoginPassword} from "@/apis/modules/login.js";
	import { Toast } from 'vant';
	export default {
		components:{
			antInput
		},
		props: {
			isRecord: {
				type: Boolean,
				default: true,
			}
		},
		data() {
			return {
				old_password:false,
				checked: true,
				isFocus: false,
				formConter:{
					new_password:'',
					new_password_confirm:'',
					password:'',
					password_confirm:'',
					old_password:'',
				},
				value: '',
				fonData: {
					user_name:{
						placeholder:'Por favor, insira Conta',
						tis:'Erro no formato da conta,4-16 caracteres, suporta inglês/números',
						value:''
					},
					password:{
						placeholder:'6-16 caracteres, incluindo pelo menos duas /n letras/números/símbolos',
						tis:'6-16 caracteres, incluindo pelo menos duas /n letras/números/símbolos',
						value:'',
						type:'password'
					},
					password_confirm:{
						placeholder:'6-16 caracteres, incluindo pelo menos duas letras/números/símbolos',
						tis:'Senha incorreta, digite novamente!',
						value:'',
						type:'password'
					},
					old_password:{
						placeholder:'6-16 dígitos, inglês/números/símbolos',
						tis:'6-16 dígitos, inglês/números/símbolos',
						value:'',
						type:'password'
					},
					
					// 
				},
				mimaQiangdu:-1,
				value1: 0,
				option1: [{
					text: 'BRL（BRL）',
					value: 0
				}],
				text: '',
				captcha:()=>{},
				riqifanshi:false,
			}
		},
		created() {
			let _this=this;
		},
		mounted() {
			// this.$refs.searchVal.toggle()
			
		},
		methods: {
			onClickLeft(){
								 this.$router.go(-1);
						},
			openUrlLofin(name,quser){
				this.openUrl(name,quser);
				this.$store.commit('isRecord')
			},
			pustRegister(){
				let {new_password,new_password_confirm,password,password_confirm,old_password}=this.formConter;
				changeLoginPassword({
					new_password_confirm:password_confirm,
					new_password:password,
					old_password:old_password,
				}).then(({data,msg,code})=>{
					if(code==0){
						if(this.old_password){
							let rememberPassword = window.localStorage.getItem('rememberPassword');
							let aem = JSON.parse(rememberPassword);
							if (rememberPassword) {
								let aem = JSON.parse(rememberPassword);
								if(aem[1]){
									aem[1].password=password_confirm;
								}
								if(aem[0]){
									aem[0].password=password_confirm;
								}
								window.localStorage.setItem('rememberPassword', JSON.stringify(aem))
							}
							this.$router.push({name:'home'});
							localStorage.removeItem('lifeData');
							setTimeout(()=>{
								location.reload();
							})
						}
						if(!this.old_password){
							this.old_password=true;
						}
						
					}else{
						// Toast(msg);
						Toast.fail({
							message:this.myLineBreaks(msg),
							duration:1000
						});
					}
					
				})
			},
			registerUrl(form){
				let {user_name,password,phone}=this.formConter;
				let _istrue=false;
				if(!password || password.length<6){
					_istrue=true;
				}
				console.log(this.formConter)
				if(_istrue){
					for(let i in this.formConter){
						this.onInput(this.formConter[i],i)
					}
					return false;
				}
				this.pustRegister();
				// if(!password || password.length<6){
				// 	_istrue=true;
				// }
				
				// this.captcha.showCaptcha();
				// invite_id
				
			},
			showHide(e){
				this.fonData[e].type=this.fonData[e].type=='password'?'text':'password'
			},
			onFocus(e,formKey){
			  // if(!formKey){return false}
			  // let  formSo= this.formConter[formKey];
			  // let formValue= this.fonData[formKey];
			  // console.log(formValue,formKey)
			  // if(!formSo){
				 // formValue.value=formValue.placeholder 
			  // }
			},
			// @blur="onBlur"
			onBlur(e,formKey){
				if(!formKey){return false}
				let  formSo= this.formConter[formKey];
				let formValue= this.fonData[formKey];
				if(!formSo){
					formValue.value=formValue.placeholder 
				}
			},
			onInput(e,formKey){
				if(!formKey){return false}
				this.formConter[formKey]=e;
				let  formSo= this.formConter[formKey];
				let formValue= this.fonData[formKey];
				if(formSo){
					let regex = /^[a-zA-Z0-9_]{4,16}$/;
					if(formKey=='password'){
						this.mimaQiangdu=formSo.length?checkPasswordStrength(e):-1;
						if(!validatePassword(e)){
							formValue.value=formValue.tis;
						}else{
							formValue.value='';
						}
					}
					console.log(validatePassword(e),formKey)
					if(formKey=='password_confirm' && formSo){
						if(formSo!=this.formConter.password){
							formValue.value=formValue.tis;
						}else if(formSo==this.formConter.password){
							formValue.value='';
						}
					}
					if(formKey=='old_password'){
						this.mimaQiangdu=formSo.length?checkPasswordStrength(e):-1;
						if(!validatePassword(e)){
							formValue.value=formValue.tis;
						}else{
							formValue.value='';
						}
					}
					
				}else{
					formValue.value=formValue.placeholder 
				}
				this.formConter={
					...this.formConter
				}
				
			},
			onChange() {
			
			},
			isFun(e) {
				this.isFocus = e;
			},
		}
	}
</script>
<style>
		
	.login-register-from-space .van-checkbox__icon_{
		position: relative;
		padding-left: .24rem;
	}
	.login-register-from-space .van-checkbox__icon_ .van-checkbox__icon{
		position: absolute;
		left: 0;
		top: .05rem;
	}
	.login-register-from-space .van-search {
		display: flex;
		align-items: center;
		border-radius: .14rem;
		font-size: .22rem;
		height: .7rem;
		background-color: transparent;
		border: 1px solid #d9d9d9;
		padding-right: .1rem;
		border-color: var(--cms-text-color-secondary);
	}

	.login-register-from-space .van-checkbox__icon .van-icon {
		border-radius: .06rem;
		border-color: var(--theme-color-line);
	}
	.login-register-from-space .van-ellipsis{
		position: relative;
		padding-left: .5rem;
	}
	.login-register-from-space .van-cell__title{
		position: relative;
		padding-left: 0.42rem;
	}
	.login-register-from-space .van-dropdown-menu__title{
		padding: 0 !important;
	}
	.login-register-from-space .van-cell__title:before,.login-register-from-space .van-ellipsis:before{
			content: '';
			display: inline-block;
		    position: relative;
		    width: 0.36rem;
		    height: 0.36rem;
			position: absolute;
			left:0;
			overflow: hidden;
			background-image: url('./../../assets/5e8fdeaf-a95f-4566-adac-d84c9605a176.png');
			background-position: -8.58857rem -3.35571rem;
			background-size: 9.33429rem 9.10929rem;
	}
	.login-register-from-space .van-cell__title:before{
		left: 0;
		top: -.05rem !important;
	}
	.verification-btn__group {
		    -ms-flex-pack: center;
		    background-color: var(--cms-background-color-primary);
		    bottom: 0;
		    -webkit-box-shadow: 0 -.03rem .1rem 0 rgba(0,0,0,.1);
		    box-shadow: 0 -.03rem .1rem 0 rgba(0,0,0,.1);
		    display: -ms-flexbox;
		    display: flex;
		    height: 1.3rem;
		    justify-content: center;
			box-sizing: border-box;
		    position: fixed;
			left: auto; 
		    /* width: var(--theme-max-width) */
			width: 100%;
			max-width:var(--theme-max-width) ;
		}
	.Xhr_XJt3jLTtAL8pC16D button {
	    font-size: .24rem;
	    height: .7rem;
		 width: 7.1rem!important;
		 margin-top: initial;
		 background-color: var(--cms-primary-color);
		 border-color: var(--cms-divider-color);
		 color: var(--cms-theme-text-color-primary)
	}
	.login-register-from-space .van-search__content {
		background-color: var(--theme-main-bg-color);
	}

	.login-register-from-space .van-checkbox__label {
		display: flex;
		align-items: center;
	}

	.login-register-from-space .data-user-agreement {
		color: var(--cms-primary-color)
	}
	.login-register-from-space input {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	}

	.login-register-from-space input::-webkit-input-placeholder {
		/* 使用webkit内核的浏览器 */
		color: var(--cms-text-color-secondary) !important;
		line-height: .3rem !important;
		font-size: .22rem !important;
		font-weight: 400 !important;
	}

	.login-register-from-space input:-moz-placeholder {
		/* Firefox版本4-18 */
		color: var(--cms-text-color-secondary) !important;
		line-height: .3rem !important;
		font-size: .22rem !important;
		font-weight: 400 !important;
	}

	.login-register-from-space input::-moz-placeholder {
		/* Firefox版本19+ */
		color: var(--cms-text-color-secondary) !important;
		line-height: .3rem !important;
		font-size: .22rem !important;
		font-weight: 400 !important;
	}

	.login-register-from-space input:-ms-input-placeholder {
		/* IE浏览器 */
		color: var(--cms-text-color-secondary) !important;
		line-height: .3rem !important;
		font-size: .22rem !important;
		font-weight: 400 !important;
	}
	
	.van-field__body input {
		color: var(--cms-text-color-primary);
	}

	.input-active {
		/* var(--theme-primary-color); */
		box-shadow: 0 2px 0 rgba(0, 0, 0, .045);
		border: .02rem solid var(--cms-primary-color) !important;
	}

	.v0FZl0YCkb_Z5KOXk68M4._max-width {
		width: auto !important;
		max-width: initial !important;
	}

	.v0FZl0YCkb_Z5KOXk68M4._max-width .van-dropdown-menu__bar {
		height: .7rem;
	}

	.v0FZl0YCkb_Z5KOXk68M4._max-width .van-dropdown-menu__bar .van-dropdown-menu__item {
		border: .01rem solid var(--theme-color-line);
		background-color: var(--theme-main-bg-color);
		font-size: .2rem;
		height: .7rem !important;
		transition: all .3s cubic-bezier(.645, .045, .355, 1);
		border-radius: .1rem;
		line-height: .5rem;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0 11px;
		padding-right: .4rem !important;
		color: var(--theme-text-color-lighten) !important;
	}

	.v0FZl0YCkb_Z5KOXk68M4._max-width .addcinte {
		height: .7rem;
	}

	.v0FZl0YCkb_Z5KOXk68M4._max-width .van-dropdown-menu__bar .van-ellipsis {
		color: var(--cms-text-color-secondary);
	}

	.v0FZl0YCkb_Z5KOXk68M4._max-width .van-popup,
	.v0FZl0YCkb_Z5KOXk68M4._max-width .van-dropdown-item {
		width: 100%;
	}

	.v0FZl0YCkb_Z5KOXk68M4._max-width .van-dropdown-item {
		top: .8rem !important;
	}
	.login-register-body .van-search__content{
		padding-left: 0px !important;
		margin-left: -.05rem;
	}
	/* border: .01rem solid var(--theme-primary-color) !important; */
</style>
<style scoped>
	.icontest{
		height: .7rem;
		/* width: 1.2rem; */
		/* padding-right: .15rem; */
		position: relative;
	}
	.icontest:before {
	    background-color: var(--theme-color-line);
	    bottom: .1rem;
	    content: "";
	    height: auto;
	    position: absolute;
	    right: 0.15rem;
	    top: .1rem;
	    width: .01rem;
	}
	.Conter_arrConte .icontest:before{
		display: none; 
	}
	.X5eogfqvbxD_ckZVQSll {
		color: var(--cms-text-color-primary);
		font-size: .22rem;
		line-height: 1.28;
		padding: 0 .3rem .07rem 0;
		text-align: left
	}

	.login-register-from-space {
		padding-left: .3rem;
		padding-right: .3rem;
		box-sizing: border-box;
		padding-top: .2rem;
		width: 100%
	}

	.ZEcfoKF3Q_zq5EqaOdUy {
		color: var(--theme-secondary-color-error);
		display: flex;
		align-items: center;
		margin-left: .02rem;
		position: relative;
		padding-right: .09rem;
	}
	.ZEcfoKF3Q_zq5EqaOdUy i{
		position: absolute;
		/* top: -.01rem; */
	}
	
	.GaL3XJonIwzK4ZeJyCyq {
	    background-color: #fff;
	    background-image: none;
	    border: 1px solid #d9d9d9;
	    border-radius: 4px;
	    -webkit-box-shadow: 0 2px 0 rgba(0,0,0,.015);
	    box-shadow: 0 2px 0 rgba(0,0,0,.015);
	    color: rgba(0,0,0,.65);
	    cursor: pointer;
	    display: inline-block;
	    font-size: 14px;
	    font-weight: 400;
	    height: 32px;
	    line-height: 1.499;
	    padding: 0 15px;
	    position: relative;
	    text-align: center;
	    -ms-touch-action: manipulation;
	    touch-action: manipulation;
	    -webkit-transition: all .3s cubic-bezier(.645,.045,.355,1);
	    transition: all .3s cubic-bezier(.645,.045,.355,1);
	    -webkit-user-select: none;
	    -moz-user-select: none;
	    -ms-user-select: none;
	    user-select: none;
	    white-space: nowrap
	}
	.GaL3XJonIwzK4ZeJyCyq{
		color: var(--cms-theme-text-color-primary);
		background: var(--cms-primary-color);
		border-color: var(--cms-primary-color);
			    font-size: .24rem;
			    height: .7rem;
				width: 100%;
				    text-shadow: none;
					    box-shadow: 0 2px 0 rgba(0, 0, 0, .045);
	}
	
	.chen_texbt {
		color: var(--cms-text-color-secondary);
		font-size: .22rem;
	}

	.ant-form-explain {
		color: var(--theme-secondary-color-error);
		font-size: .18rem;
		transition: color .3s cubic-bezier(.215, .61, .355, 1);
		line-height: 1.5 !important;
		position: relative;
		padding-left: .3rem;
		margin-bottom: .2rem;
	}

	.ant-form-explain::before {
		background-color: var(--theme-secondary-color-error);
		content: "";
		height: 0.18rem;
		margin-right: 0.1rem;
		visibility: visible;
		left: 0;
		top: 50%;
		margin-top: -0.09rem;
		width: 0.18rem;
		display: inline-block;
		position: absolute;
		background-image: url('./../../assets/5e8fdeaf-a95f-4566-adac-d84c9605a176.png');
		background-size: 13.068rem 12.753rem;
		border-radius: 50%;
		background-position: -12.888rem -3.825rem;
	}

	.HwvTcjBqOBJV3S7RoZ7g {
		display: flex;
		align-items: center;
		min-height: .26rem;
		font-size: .22rem;
		margin-bottom: .2rem;
	}

	.HwvTcjBqOBJV3S7RoZ7g li {
		display: flex;
		width: 50%;
		color: var(--theme-text-color-darken);
	}

	.HwvTcjBqOBJV3S7RoZ7g svg {
		-ms-flex-align: center;
		-ms-flex-pack: center;
		align-items: center;
		background-color: var(--theme-main-bg-color);
		border: .01rem solid var(--theme-color-line);
		border-radius: 9999.99rem;
		color: var(--theme-text-color-lighten);
		display: -ms-flexbox;
		display: flex;
		font-size: .3rem;
		min-width: .3rem;
		justify-content: center;
		line-height: 1;
		margin-right: .1rem;
	}

	.xuanzeactive {
		color: var(--theme-primary-color) !important;
	}

	.xuanzeactive svg {
		color: #000 !important;
		border: .01rem solid var(--theme-primary-color);
		background-color: var(--theme-primary-color);
	}

	.right-icon {
		height: .36rem !important;
		width: .36rem !important;
	}

	.rBX8yUXrZ3DzhJ4ZvZUD {
		display: flex;
		align-items: center;
		margin-bottom: .2rem;
	}

	.ptEr7ybENfgNXBPMnsRb .rBX8yUXrZ3DzhJ4ZvZUD span {
		color: var(--cms-text-color-primary);
		font-size: .22rem;
		margin-right: .1rem;
		-webkit-transition: all .3s;
		transition: all .3s;
	}

	.ptEr7ybENfgNXBPMnsRb .rBX8yUXrZ3DzhJ4ZvZUD .VYe1C86O9HyF7KaCqIUg {
		background-color: var(--cms-divider-color);
		border-radius: .12rem;
		height: .12rem;
		margin-right: .1rem;
		transition: all .3s;
		width: .8rem;
	}

	.ptEr7ybENfgNXBPMnsRb .rBX8yUXrZ3DzhJ4ZvZUD .QZ0QekXF9duW7ClyMSdQ {
		background-color: var(--cms-global-secondary3-color) !important;
	}

	.inner-textbox {
		text-align: left !important;
		line-height: 1.5;
		margin-bottom: .2rem;
		font-size: 9.36px;
		color: var(--cms-text-color-secondary);
	}

	/* .ant-select-selection__placeholder {
	    height: .3rem;
	    line-height: .3rem;
		font-size: .22rem;
		    font-weight: 400;
	    margin-top: -.15rem;
		    color: var(--theme-text-color-placeholder);
	} */
</style>
