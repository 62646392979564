<template>
	<div class="ContaBox">
		<div class="my-scrollbar" style="margin-bottom: .2rem;">
			<elSelectItem :options="optionTim" @change="_onChangeTime" :value="timValue"></elSelectItem>
			<elSelectItem style="margin-left: .2rem;"  :options="ContaType" @change="_onChangeStatus" :value="ContaTypeValue"></elSelectItem>
			<elSelectItem style="margin-left: .2rem;"  :options="game_type" @change="_onChangeGame_type" :value="game_typeValue"></elSelectItem>
			<elSelectItem style="margin-left: .2rem;"  value="1" :options='[{text:"Todas as Plataformas",value:"1"}]'></elSelectItem>
		</div>
		<div class="TBtGFT5INIot5sEUIm8w vantDataList">
			<van-list v-if="shareInfo && shareInfo.length" v-model="loading" @load="onLoad" :finished="finished" >
				<li class="H9OepNjwbd602I30Pp_s E8vjOrxznOAKdRhXJRMX" v-for="(item,index) in shareInfo"
					:key="index" :title="item">
					<p class="RmKh8CuQull6Xt98xhDv">
						<span class="hUiIi4S5mS_zgUN7grJG">
							{{item.game}}
						</span>
						<span class="bX48J467Rx96D17NO3u7">
							<span class="Bb2eRv4VS7avN4IlErAm">
								<span>{{ $t("report._ApostasVálidas") }}</span>
								<span>&nbsp;
									<span class="UXsnlJzbtvsfRVKRTLXu">R$ {{item.bet}}</span>
								</span>
							</span>
							<span class="Bb2eRv4VS7avN4IlErAm">
								<span>{{ $t("report._GanhosePerdas") }}</span>
								<!-- YgwzG0T3H3PBaUc3A3_s -->
								<!-- fABmUWsKxI0hI9mbzoYD -->
								<span>&nbsp;
											<span v-if="item.gain<0" class="YgwzG0T3H3PBaUc3A3_s">R${{item.gain}}</span>
											<span v-else class="fABmUWsKxI0hI9mbzoYD">R${{item.gain}}</span>
								</span>
							</span>
						</span>
					</p>
					<p class="wgK2LPtqwY6ykT9PTEFa">
						<span class="eFQ3axZpu8hrXwDmCzxd">
							<span>{{item.create_time}}</span>
						</span>
						<span class="aUJowNQhIpzaMd4FZGmN">
							<span class="_umF2Z34xk1F_GIsbss_">
								<span>{{ $t("report._NúmerodaNota") }}</span>&nbsp;
								<span>{{item.bet_no}}</span>
							</span>
							<span class="PmwtECGo9wfGBMP0uy71 copyIcon zrPmGto4tAdnAA_BFB54"  @click="copyContent(item.id)">
								<svg-icon icon-class="comm_icon_copy--svgSprite:all" />
							</span>
						</span>
					</p>
				</li>
				<!--  -->

				<!--  -->
			</van-list>
			<van-empty image-size="2.1rem" v-if="!shareInfo || !shareInfo.length" class="custom-image"
				:image="img_none_sj"
				:description="$t('description')" />
		
		</div>


	</div>
</template>

<script>
	import datetimePicker from "@/components/searchFor/datetimePicker.vue";
	import searchFor from "@/components/searchFor/searchFor.vue";
	import elSelectItem from "./el-select-item.vue";
	import {
		ContaType,
		tim,
		timValue,
		ContaTypeValue,
		game_type,
		game_typeValue
	} from "@/utils/tim.js"
	// allData
	import {
		getBetLog
	} from "@/apis/modules/share.js";
	export default {
		name: 'vip',
		components: {
			datetimePicker,
			searchFor,
			elSelectItem
		},

		data() {
			return {
				list: [],
				isTanzhans: false,
				loading: false,
				finished: false,
				shareInfo: [],
				shareTime: 'Ontem',
				ContaType: ContaType,
				timValue: timValue,
				optionTim: tim,
				game_type: game_type,
				game_typeValue: game_typeValue,
				ContaTypeValue: ContaTypeValue,
				active: 0,
				fromType:1,
				formData: {
					time: '',
					status: '',
					game_type: '',
				}
			}
		},
		mounted() {
			this.optionTim=tim.filter(item=>{
				return item.is!==false
			})
			this.shareInfo=[]
			this.getBetLog();
		},
		methods: {
			_onChangeTime(e) {
				this.formData.time = e[0];
				this.timValue= e[0];
				this.shareInfo=[]
				this.getBetLog();
			},
			_onChangeStatus(e) {
				this.ContaTypeValue=e[0];
				this.formData.status = e[0];
				this.shareInfo=[]
				this.getBetLog();
			},
			_onChangeGame_type(e) {
				this.formData.game_type = e[0];
				this.game_typeValue=e[0];
				this.shareInfo=[]
				this.getBetLog();
			},
			_search(e) {
				this.formData.search_id = e;
				this.shareInfo=[]
				this.getBetLog();
			},
			onLoad() {
				if(this.finished){
					return false;
				}
				
				this.fromType=this.fromType+1;
				this.getBetLog();
				// 异步更新数据
				// setTimeout 仅做示例，真实场景中一般为 ajax 请求
				// setTimeout(() => {
				// 	for (let i = 0; i < 10; i++) {
				// 		this.list.push(this.list.length + 1);
				// 	}

				// 	// 加载状态结束
				// 	this.loading = false;

				// 	// 数据全部加载完成
				// 	if (this.list.length >= 1000) {
						
				// 	}
				// }, 1000);
			},
			getBetLog() {
				// vuex_token_head
				getBetLog({
					// size: 10000,
					page:this.fromType,
					pid: this.$store.state.vuex_token_head.user_id,
					...this.formData
				}).then(({
					data
				}) => {
					if(!data.length || data.length<50){
						this.finished = true;
					}
					
					this.shareInfo = [...this.shareInfo,...data];
					this.loading=false;
				})
			},
			tabIndexTime(index, time) {
				this.shareTime = time;
				this.active = index;
				this.shareMyData()
			},
		},
	}
</script>
<style>

</style>
<style scoped>
	.my-scrollbar {
		display: flex;
		padding-left: .2rem;
		overflow: auto;
		padding-bottom: .1rem;
	}

	.ContaBox {
		padding-top: .2rem;
	}

	.nVAOZ5L2ZIX7FtNLSqAD {
		min-height: .4rem;
		position: relative;
		width: 100%
	}
	.Bb2eRv4VS7avN4IlErAm>span {
	    display: inline-block;
	    vertical-align: middle;
	    white-space: nowrap
	}
	
	.Bb2eRv4VS7avN4IlErAm>span:first-child {
	    overflow: hidden;
	    text-overflow: ellipsis;
	    white-space: nowrap
	}
	
	.H9OepNjwbd602I30Pp_s {
	    -ms-flex-pack: justify;
	    display: -ms-flexbox;
	    display: flex;
	    -ms-flex-direction: column;
	    flex-direction: column;
	    font-size: .2rem;
	    justify-content: space-between;
	    padding: .2rem
	}
		.E8vjOrxznOAKdRhXJRMX {
		    color: var(--cms-text-color-secondary)
		}
		.UXsnlJzbtvsfRVKRTLXu {
		    color: var(--cms-primary-color)
		}

	.H9OepNjwbd602I30Pp_s:not(:lang(zh_CN)) {
	    padding: .18rem .1rem!important
	}
	
	.H9OepNjwbd602I30Pp_s .RmKh8CuQull6Xt98xhDv {
	    -ms-flex-align: center;
	    align-items: center;
	    display: -ms-flexbox;
	    display: flex
	}
	
	.H9OepNjwbd602I30Pp_s .RmKh8CuQull6Xt98xhDv .hUiIi4S5mS_zgUN7grJG {
	    margin-right: .1rem;
	    overflow: hidden;
	    text-overflow: ellipsis;
	    white-space: nowrap;
	    width: 2.4rem
	}
	.copyIcon svg{
		    color: var(--cms-primary-color);
			width: .22rem;
			height: .22rem;
	}
	.H9OepNjwbd602I30Pp_s .RmKh8CuQull6Xt98xhDv .hUiIi4S5mS_zgUN7grJG:not(:lang(zh_CN)) {
	    width: 2.5rem
	}
	.YgwzG0T3H3PBaUc3A3_s {
	    color: var(--cms-global-secondary1-color)
	}
	.fABmUWsKxI0hI9mbzoYD{
		    color: var(--theme-secondary-color-error);
	}

	
	.H9OepNjwbd602I30Pp_s .RmKh8CuQull6Xt98xhDv .bX48J467Rx96D17NO3u7 {
	    -ms-flex-align: center;
	    align-items: center;
	    display: -ms-flexbox;
	    display: flex;
	    -ms-flex: 1;
	    flex: 1
	}
	
	.H9OepNjwbd602I30Pp_s .RmKh8CuQull6Xt98xhDv .bX48J467Rx96D17NO3u7>span {
	    width: 50%
	}
	
	.H9OepNjwbd602I30Pp_s .RmKh8CuQull6Xt98xhDv .bX48J467Rx96D17NO3u7>span:first-child:not(:lang(zh_CN)) {
	    display: inherit;
	    width: 2.2rem
	}
	
	.H9OepNjwbd602I30Pp_s .RmKh8CuQull6Xt98xhDv .bX48J467Rx96D17NO3u7>span:nth-child(2):not(:lang(zh_CN)) {
	    -ms-flex-pack: end;
	    display: -ms-flexbox;
	    display: flex;
	    justify-content: flex-end;
	    margin-left: .1rem;
	    white-space: nowrap;
	    width: 1.9rem
	}
	
	.H9OepNjwbd602I30Pp_s .wgK2LPtqwY6ykT9PTEFa {
	    -ms-flex-align: center;
	    word-wrap: break-word;
	    align-items: center;
	    display: -ms-flexbox;
	    display: flex;
	    -ms-flex-wrap: nowrap;
	    flex-wrap: nowrap;
	    margin-top: .12rem;
	    word-break: keep-all
	}
	
	.H9OepNjwbd602I30Pp_s .wgK2LPtqwY6ykT9PTEFa .eFQ3axZpu8hrXwDmCzxd {
	    margin-right: .1rem;
	    overflow: hidden;
	    text-overflow: ellipsis;
	    white-space: nowrap;
	    width: 2.4rem
	}
	
	.H9OepNjwbd602I30Pp_s .wgK2LPtqwY6ykT9PTEFa .eFQ3axZpu8hrXwDmCzxd:not(:lang(zh_CN)) {
	    width: 2.5rem
	}
	
	.H9OepNjwbd602I30Pp_s .wgK2LPtqwY6ykT9PTEFa .aUJowNQhIpzaMd4FZGmN {
	    -ms-flex-pack: justify;
	    display: -ms-flexbox;
	    display: flex;
	    -ms-flex: 1;
	    flex: 1;
	    justify-content: space-between
	}
	
	.H9OepNjwbd602I30Pp_s .wgK2LPtqwY6ykT9PTEFa .aUJowNQhIpzaMd4FZGmN ._umF2Z34xk1F_GIsbss_ {
	    max-width: 3.8rem;
	    overflow: hidden;
	    text-overflow: ellipsis;
	    white-space: nowrap
	}
	
	.H9OepNjwbd602I30Pp_s .wgK2LPtqwY6ykT9PTEFa .aUJowNQhIpzaMd4FZGmN ._umF2Z34xk1F_GIsbss_:not(:lang(zh_CN)) {
	    max-width: 3.86rem
	}
	
	.H9OepNjwbd602I30Pp_s .wgK2LPtqwY6ykT9PTEFa .aUJowNQhIpzaMd4FZGmN .zrPmGto4tAdnAA_BFB54 {
	    display: -ms-flexbox;
	    display: flex;
	    font-size: .22rem
	}
		
	.H9OepNjwbd602I30Pp_s:not(:lang(zh_CN)) {
	    padding: .18rem .1rem!important
	}
		
		
	.nVAOZ5L2ZIX7FtNLSqAD .PzFW1R_NxciR5KCVuKck {
		background-color: var(--cms-background-color-primary);
		border-top: .01rem solid var(--cms-divider-color);
		bottom: 0;
		font-size: .2rem;
		line-height: .22rem;
		padding: .2rem .2rem 0;
		position: fixed;
		-webkit-transition: -webkit-transform .3s;
		transition: -webkit-transform .3s;
		transition: transform .3s;
		transition: transform .3s, -webkit-transform .3s;
		width: 100%
	}

	.nVAOZ5L2ZIX7FtNLSqAD .PzFW1R_NxciR5KCVuKck.kAaqfTc5guezIg4i8OHU {
		-webkit-transform: translateY(calc(100% - .3rem));
		transform: translateY(calc(100% - .3rem))
	}

	.nVAOZ5L2ZIX7FtNLSqAD .PzFW1R_NxciR5KCVuKck .WKP0P703NvX8zzUuEJg9 {
		font-size: .2rem
	}

	.nVAOZ5L2ZIX7FtNLSqAD .PzFW1R_NxciR5KCVuKck .WKP0P703NvX8zzUuEJg9 .van-col {
		-ms-flex-align: center;
		align-items: center;
		color: var(--theme-text-color-darken);
		display: -ms-flexbox;
		display: flex;
		margin-bottom: .15rem
	}

	.nVAOZ5L2ZIX7FtNLSqAD .PzFW1R_NxciR5KCVuKck .WKP0P703NvX8zzUuEJg9 .van-col label {
		color: var(--cms-text-color-primary);
		margin-right: .1rem;
		width: 50%
	}

	.nVAOZ5L2ZIX7FtNLSqAD .PzFW1R_NxciR5KCVuKck .L7qtNTcvxkLyv8NTsUx8 {
		-ms-flex-align: self-start;
		-ms-flex-pack: center;
		align-items: self-start;
		background-color: var(--cms-background-color-primary);
		border-radius: .5rem .5rem 0 0;
		border-top: .02rem solid var(--cms-divider-color);
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		color: var(--cms-primary-color);
		display: -ms-flexbox;
		display: flex;
		height: .35rem;
		justify-content: center;
		left: 50%;
		padding: .1rem;
		position: absolute;
		top: 0;
		-webkit-transform: translate(-50%, -98%);
		transform: translate(-50%, -98%);
		width: .7rem
	}

	.nVAOZ5L2ZIX7FtNLSqAD .PzFW1R_NxciR5KCVuKck .L7qtNTcvxkLyv8NTsUx8 .dPPYvWfEoY8BG3Qg86dU {
		font-size: .2rem;
		width: .2rem;
		height: .2rem;
		-webkit-transition: -webkit-transform .3s;
		transition: -webkit-transform .3s;
		transition: transform .3s;
		transition: transform .3s, -webkit-transform .3s
	}

	.nVAOZ5L2ZIX7FtNLSqAD .PzFW1R_NxciR5KCVuKck .L7qtNTcvxkLyv8NTsUx8 .dPPYvWfEoY8BG3Qg86dU.ED6sIMJuJEuPpvzsMw30 {
		-webkit-transform: rotate(-180deg);
		transform: rotate(-180deg)
	}

	.ZjZR4FiYzgfJQbE3_Etq {
		padding: .2rem;
	}

	.ZjZR4FiYzgfJQbE3_Etq {
		-ms-flex-align: center;
		-ms-flex-pack: start;
		align-items: center;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		display: -ms-flexbox;
		display: flex;
		justify-content: flex-start;
		padding-bottom: .2rem
	}

	.ifzwCQu8BPs2c24nZdzQ {
		margin-right: .2rem;
	}

	.vantDataList .van-list>div .isGreen {
		color: var(--theme-secondary-color-success)
	}

	.vantDataList .van-list>div .isRed {
		color: var(--theme-secondary-color-error)
	}



	.dG0FpGdkHU5MgpFCLljd {
		-ms-flex-align: center;
		-ms-flex-pack: center;
		align-items: center;
		background-color: #c12929;
		border-radius: .04rem 0 .04rem 0;
		display: -ms-flexbox;
		display: flex;
		height: .23rem;
		justify-content: center;
		left: 0;
		line-height: 1;
		position: absolute;
		top: 0;
		width: -webkit-fit-content;
		width: -moz-fit-content;
		width: fit-content;
		width: .3rem
	}

	.dG0FpGdkHU5MgpFCLljd .TmCsEwuUw4tM7cplmM6W {
		-webkit-text-fill-color: transparent;
		-webkit-background-clip: text;
		background-clip: text;
		background-image: -webkit-gradient(linear, left top, left bottom, color-stop(-5%, #f7ea94), color-stop(58%, #e5b952), color-stop(114%, #ce9510));
		background-image: linear-gradient(180deg, #f7ea94 -5%, #e5b952 58%, #ce9510 114%);
		font-size: .16rem;
		font-weight: 700;
		line-height: 1
	}

	.vantDataList .van-list>div {
		color: var(--cms-text-color-secondary);
		font-size: .2rem !important;
		position: relative
	}

	.Iu2XDEUxSXVTmZ2ClDl5 .s-s svg {
		font-size: .24rem;
		width: .24rem;
		height: .24rem;
		margin-left: .1rem;
		color: var(--cms-primary-color);
		position: relative;
	}

	.vantDataList .van-list>div label {
		color: var(--cms-text-color-primary);
		margin-right: .1rem;
	}

	.vantDataList .van-list .van-col {
		margin-bottom: .05rem;
	}

	.dateTime {
		color: var(--cms-text-color-secondary);
		font-size: .16rem;
	}

	.greenColor {
		color: var(--theme-secondary-color-error) !important;
	}

	.redColor {
		color: var(--theme-secondary-color-success) !important;
	}

	.zhMFiMGv4SkVVrKaizPg .dateTime {
		font-size: .2rem !important
	}
</style>
