import http from './../http.interceptor.js';


export function startDemoGame(params) {
	return http.post('/v3.game/startDemoGame', params)
}


export function startGame(params) {
	return http.post('/v3.game/startGame', params)
}



export function vipDetail(params) {
	return http.get('/v3.user/vipDetail', params)
}
