<template>
	<div class="">
		<div class="ZjZR4FiYzgfJQbE3_Etq">
			<datetimePicker class="ifzwCQu8BPs2c24nZdzQ" @_timeOnFunEndDate="_timeOnFunEndDate"></datetimePicker>
			<div class="">
				<searchFor @search="_search" @input="_searchInput" :placeholder="$t('promote._MemberID')"></searchFor>
			</div>
			<!-- -->
			<!--  -->
			
		</div>
		<div class="TBtGFT5INIot5sEUIm8w vantDataList">
			<van-list v-if="shareInfo.list && shareInfo.list.length" :finished="finished" finished-text="没有更多了"
				@load="onLoad">
				<div slot="default" v-for="(item,index) in shareInfo.list" :key="index" :title="item">
					<van-row type="flex" justify="space-between">
						<van-col span="12">
							<div class="Iu2XDEUxSXVTmZ2ClDl5">
								<div class="s-s" @click="copyContent(item.id)">
									{{item.id}}
									<svg-icon icon-class="comm_icon_copy--svgSprite:all" />
								</div>
								<div class="dG0FpGdkHU5MgpFCLljd globalVipLevel">
									<p class="TmCsEwuUw4tM7cplmM6W">{{item.vip}}</p>
								</div>
							</div>
						</van-col>
						<van-col span="12">
							<label>{{ $t('promote._SubordinadosDele') }}</label>
							<span class="currencyAmount"
							 :class="{
							 	active:item.subordinate>0
							 }"
							 @click="item.subordinate>0?fubCtion(item,3):''"
							 >{{item.subordinate==0?'-':item.subordinate}}</span>
						</van-col>
					</van-row>
				
					<van-row type="flex" justify="space-between">
						<van-col span="12">
							<label>{{ $t('promote._Depósito') }}</label>
							<span class="currencyAmount">{{item.recharge_total}}</span>
						</van-col>
						<van-col span="12">
							<label>{{ $t('promote._DatadeCadastro') }}</label>
							<span>{{item.reg_time}}</span>
						</van-col>
					</van-row>
					<van-row type="flex" justify="space-between">
						<van-col span="12">
							<label>{{ $t('promote._ApostasVálidas') }}</label>
							<span class="currencyAmount">{{item.bet_total}}</span>
						</van-col>
						<van-col span="12">
							<label>{{ $t('promote._Datadelogin') }}</label>
							<span>{{item.login_time}}</span>
						</van-col>
					</van-row>
					<van-row type="flex" justify="space-between">
						<van-col span="12">
							<label>{{$t('promote._Atual')  }}</label>
							<span>
								<span class="onlineStatus">{{item.online}}</span>
								<!-- <span>(15Time(s))</span> -->
							</span>
						</van-col>
						<van-col span="12">
							<label>{{$t('promote._Estado')  }}</label>
							<span :class="{
								isRed:!item.status,
								isGreen:item.status
							}">{{item.status==1?'Normal':'Lock'}}</span>
						</van-col>
					</van-row>
				</div>
			</van-list>
			<van-empty image-size="2.1rem" v-if="!shareInfo.list || !shareInfo.list.length" class="custom-image"
				:image="img_none_sj"
				:description="$t('description')" />
			<!--  -->
					<!-- 
						
					 -->
			<div class="nVAOZ5L2ZIX7FtNLSqAD" v-if="shareInfo.list && shareInfo.list.length">
				<div class="PzFW1R_NxciR5KCVuKck " :class="{
						 kAaqfTc5guezIg4i8OHU:!isTanzhans
					 }">
					<!-- kAaqfTc5guezIg4i8OHU -->
					<div class="L7qtNTcvxkLyv8NTsUx8" @click="isTanzhans=!isTanzhans">
						<!-- ED6sIMJuJEuPpvzsMw30 -->
						<svg-icon class="dPPYvWfEoY8BG3Qg86dU " :class="{
						  	ED6sIMJuJEuPpvzsMw30:!isTanzhans
						  }" icon-class="img_jt_ss--svgSprite:all" />
					</div>
					<van-row type="flex" class="WKP0P703NvX8zzUuEJg9" justify="space-between">
						<van-col span="12">
							<label>{{$t('promote._Númerodeinscritos')  }}</label>
							<span class="currencyAmount">{{shareInfo.invite}}</span>
						</van-col>
						<van-col span="12">
							<label>{{$t('promote._Depósitos')  }}</label>
							<span class="currencyAmount">{{shareInfo.first_recharge}}</span>
						</van-col>
						<van-col span="12">
							<label>{{$t('promote._PrimeirosDepósitos')  }}</label>
							<span class="currencyAmount"> {{shareInfo.recharge_num}}</span>
						</van-col>
						<van-col span="12">
							<label>{{$t('promote._Depósito')  }}</label>
							<span class="currencyAmount">{{shareInfo.recharge_total}}</span>
						</van-col>
						<van-col span="12">
							<label>{{$t('promote._ApostasVálidas')  }}</label>
							<span class="currencyAmount">{{shareInfo.bet_total}}</span>
						</van-col>
					</van-row>
					<!--  -->
					
					<!--  -->
				</div>
			</div>
		</div>


	</div>
</template>

<script>
	import datetimePicker from "@/components/searchFor/datetimePicker.vue";
	import searchFor from "@/components/searchFor/searchFor.vue";
	// allData
	import {
		shareSubordinateStats
	} from "@/apis/modules/share.js";
	export default {
		name: 'vip',
		components: {
			datetimePicker,
			searchFor
		},

		data() {
			return {
				list: [],
				isTanzhans: false,
				loading: false,
				finished: false,
				shareInfo: {},
				shareTime: 'Ontem',
				active: 0,
				fromType:1,
				formData: {
					start_time: '',
					end_time: '',
					search_id: '', //搜索下级ID
				}
			}
		},
		mounted() {
			this.shareSubordinateStats();
		},
		methods: {
			fubCtion(item,type){
				shareSubordinateStats({
					pid: item.id,
					...this.formData,
					page:this.fromType
				}).then(({
					data
				}) => {
					this.$store.commit('publicFun',['isDireto',{
						DetalhesdaAposta:true,
						isDireto:false,
						type:type,
						...data,
						user_id:item.id,
						...this.formData,
					}])
				})
				
			},
			
			_timeOnFunEndDate(Tim, anD) {
				this.fromType=1;
				this.fromList=[];
				this.finished = false;
				this.formData.start_time = Tim;
				this.formData.end_time = anD;
				this.shareSubordinateStats();
			},
			_search(e) {
				this.formData.search_id = e;
				this.fromType=1;
				this.fromList={};
				this.finished = false;
				this.shareSubordinateStats();
			},
			_searchInput(e){
				this.formData.search_id = e.target.value;
			},
			onLoad() {
				if(this.finished){
					return false;
				}
				
				this.fromType=this.fromType+1;
				this.shareSubordinateStats();
			},
			shareSubordinateStats() {
				// vuex_token_head
				shareSubordinateStats({
					pid: this.$store.state.vuex_token_head.user_id,
					...this.formData,
					page:this.fromType,
				}).then(({
					data
				}) => {
					// data.list=[{},{}]
					// this.shareInfo = data;
					let {list}=data;
					if(JSON.stringify(list)=='[]' || list.length<50){
						this.finished = true;
					}
					this.loading=false;
					if(this.fromType==1){
						this.shareInfo = data;
					}
					this.fromList = [...this.fromList,...data.list];
				})
			},
			tabIndexTime(index, time) {
				this.shareTime = time;
				this.active = index;
				this.shareMyData()
			},
		},
	}
</script>
<style>

</style>
<style scoped>
	.nVAOZ5L2ZIX7FtNLSqAD {
		min-height: .4rem;
		position: relative;
		width: 100%
	}

	.nVAOZ5L2ZIX7FtNLSqAD .PzFW1R_NxciR5KCVuKck {
		background-color: var(--cms-background-color-primary);
		border-top: .01rem solid var(--cms-divider-color);
		bottom: 0;
		font-size: .2rem;
		line-height: .22rem;
		padding: .2rem .2rem 0;
		position: fixed;
		-webkit-transition: -webkit-transform .3s;
		transition: -webkit-transform .3s;
		transition: transform .3s;
		transition: transform .3s, -webkit-transform .3s;
		width: 100%
	}

	.nVAOZ5L2ZIX7FtNLSqAD .PzFW1R_NxciR5KCVuKck.kAaqfTc5guezIg4i8OHU {
		-webkit-transform: translateY(calc(100% - .3rem));
		transform: translateY(calc(100% - .3rem))
	}

	.nVAOZ5L2ZIX7FtNLSqAD .PzFW1R_NxciR5KCVuKck .WKP0P703NvX8zzUuEJg9 {
		font-size: .2rem
	}

	.nVAOZ5L2ZIX7FtNLSqAD .PzFW1R_NxciR5KCVuKck .WKP0P703NvX8zzUuEJg9 .van-col {
		-ms-flex-align: center;
		align-items: center;
		color: var(--cms-primary-color);
		display: -ms-flexbox;
		display: flex;
		margin-bottom: .15rem
	}

	.nVAOZ5L2ZIX7FtNLSqAD .PzFW1R_NxciR5KCVuKck .WKP0P703NvX8zzUuEJg9 .van-col label {
		color: var(--cms-text-color-primary);
		margin-right: .1rem;
		width: 50%
	}

	.nVAOZ5L2ZIX7FtNLSqAD .PzFW1R_NxciR5KCVuKck .L7qtNTcvxkLyv8NTsUx8 {
		-ms-flex-align: self-start;
		-ms-flex-pack: center;
		align-items: self-start;
		background-color: var(--cms-background-color-primary);
		border-radius: .5rem .5rem 0 0;
		border-top: .02rem solid var(--cms-divider-color);
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		color: var(--cms-primary-color);
		display: -ms-flexbox;
		display: flex;
		height: .35rem;
		justify-content: center;
		left: 50%;
		padding: .1rem;
		position: absolute;
		top: 0;
		-webkit-transform: translate(-50%, -98%);
		transform: translate(-50%, -98%);
		width: .7rem
	}

	.nVAOZ5L2ZIX7FtNLSqAD .PzFW1R_NxciR5KCVuKck .L7qtNTcvxkLyv8NTsUx8 .dPPYvWfEoY8BG3Qg86dU {
		font-size: .2rem;
		width: .2rem;
		height: .2rem;
		-webkit-transition: -webkit-transform .3s;
		transition: -webkit-transform .3s;
		transition: transform .3s;
		transition: transform .3s, -webkit-transform .3s
	}

	.nVAOZ5L2ZIX7FtNLSqAD .PzFW1R_NxciR5KCVuKck .L7qtNTcvxkLyv8NTsUx8 .dPPYvWfEoY8BG3Qg86dU.ED6sIMJuJEuPpvzsMw30 {
		-webkit-transform: rotate(-180deg);
		transform: rotate(-180deg)
	}

	.ZjZR4FiYzgfJQbE3_Etq {
		padding: .2rem;
	}

	.ZjZR4FiYzgfJQbE3_Etq {
		-ms-flex-align: center;
		-ms-flex-pack: start;
		align-items: center;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		display: -ms-flexbox;
		display: flex;
		justify-content: flex-start;
		padding-bottom: .2rem
	}

	.ifzwCQu8BPs2c24nZdzQ {
		margin-right: .2rem;
	}

	.vantDataList .van-list>div .isGreen {
		color: var(--cms-global-secondary1-color)
	}

	.vantDataList .van-list>div .isRed {
		color: var(--theme-secondary-color-error)
	}



	.dG0FpGdkHU5MgpFCLljd {
		-ms-flex-align: center;
		-ms-flex-pack: center;
		align-items: center;
		background-color: #c12929;
		border-radius: .04rem 0 .04rem 0;
		display: -ms-flexbox;
		display: flex;
		height: .23rem;
		justify-content: center;
		left: 0;
		line-height: 1;
		position: absolute;
		top: 0;
		width: -webkit-fit-content;
		width: -moz-fit-content;
		width: fit-content;
		width: .3rem
	}

	.dG0FpGdkHU5MgpFCLljd .TmCsEwuUw4tM7cplmM6W {
		-webkit-text-fill-color: transparent;
		-webkit-background-clip: text;
		background-clip: text;
		background-image: -webkit-gradient(linear, left top, left bottom, color-stop(-5%, #f7ea94), color-stop(58%, #e5b952), color-stop(114%, #ce9510));
		background-image: linear-gradient(180deg, #f7ea94 -5%, #e5b952 58%, #ce9510 114%);
		font-size: .16rem;
		font-weight: 700;
		line-height: 1
	}

	.vantDataList .van-list>div {
		color: var(--cms-text-color-secondary);
		font-size: .2rem !important;
		position: relative
	}

	.Iu2XDEUxSXVTmZ2ClDl5 .s-s svg {
		font-size: .24rem;
		width: .24rem;
		height: .24rem;
		margin-left: .1rem;
		color: var(--cms-primary-color);
		position: relative;
	}

	.vantDataList .van-list>div label {
		color: var(--cms-text-color-primary);
		margin-right: .1rem;
	}

	.vantDataList .van-list .van-col {
		margin-bottom: .05rem;
	}

	.dateTime {
		color: var(--cms-text-color-secondary);
		font-size: .16rem;
	}

	.zhMFiMGv4SkVVrKaizPg .dateTime {
		font-size: .2rem !important
	}
</style>
