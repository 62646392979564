const comsteKefy=[
            {
                "questionList": [
                    {
                        "agentId": 12345,
                        "content": "Escaneie o código QR ou abra o link de convite em um navegador. Baixe o jogo e inscreva-se, depois use seu código QR exclusivo ou link de convite para convidar outras pessoas.",
                        "createTime": 1714580903,
                        "id": 17876061,
                        "sort": 10,
                        "state": 1,
                        "title": "Como se tornar um agente?",
                        "updateBy": "xp99",
                        "updateTime": 1715144453,
                        "videoUrl": ""
                    },
                    {
                        "agentId": 12345,
                        "content": "Clique em 'Promoção' no lobby para entrar na página da promoção. Seu código QR exclusivo e o link promocional podem ser encontrados na parte inferior. Salve a imagem e compartilhe.",
                        "createTime": 1714580903,
                        "id": 17876062,
                        "sort": 9,
                        "state": 1,
                        "title": "Onde posso compartilhar o código de convite?",
                        "updateBy": "xp99",
                        "updateTime": 1715144457,
                        "videoUrl": ""
                    },
                    {
                        "agentId": 12345,
                        "content": "O rendimento refere-se às apostas válidas do jogador no jogo. Por exemplo, se você promoveu um jogador que fez 1.000 apostas válidas, isso equivale a 1.000 contribuições de desempenho.",
                        "createTime": 1714580903,
                        "id": 17876064,
                        "sort": 8,
                        "state": 1,
                        "title": "Como é calculado o desempenho?",
                        "updateBy": "xp99",
                        "updateTime": 1715144460,
                        "videoUrl": ""
                    },
                    {
                        "agentId": 12345,
                        "content": "Vá em 'Promoção' e clique em 'Desempenho' para verificar seu nível de desempenho. Você também pode clicar em 'Minha Comissão' para verificar suas comissões cobráveis ​​e histórico de liquidação.\r\n",
                        "createTime": 1714580903,
                        "id": 17876063,
                        "sort": 7,
                        "state": 1,
                        "title": "Onde posso verificar meu nível de desempenho e comissão?",
                        "updateBy": "xp99",
                        "updateTime": 1715144463,
                        "videoUrl": ""
                    }
                ],
                "typeIcon": "https://8cuumq-6309-ppp.s3.sa-east-1.amazonaws.com/siteadmin/upload/img/other_wt_icon.png",
                "typeId": 17630595,
                "typeName": "Outras perguntas"
            },
            {
                "questionList": [
                    {
                        "agentId": 12345,
                        "content": "Você pode sacar dinheiro clicando em 'Retirar dinheiro' inserindo os dados corretos do cartão bancário.",
                        "createTime": 1714580903,
                        "id": 17876060,
                        "sort": 11,
                        "state": 1,
                        "title": "Como sacar dinheiro?",
                        "updateBy": "xp99",
                        "updateTime": 1715144450,
                        "videoUrl": ""
                    }
                ],
                "typeIcon": "https://8cuumq-6309-ppp.s3.sa-east-1.amazonaws.com/siteadmin/upload/img/withdraw_wt_icon.png",
                "typeId": 17630557,
                "typeName": "Perguntas sobre coleção"
            },
            {
                "questionList": [
                    {
                        "agentId": 12345,
                        "content": "Após recarregar, os jogadores devem fazer apostas válidas no jogo. A transação de retirada só será concluída após a análise dessa solicitação.",
                        "createTime": 1714580903,
                        "id": 17876059,
                        "sort": 12,
                        "state": 1,
                        "title": "Qual é o requisito para recarregar e retirar dinheiro?",
                        "updateBy": "xp99",
                        "updateTime": 1715144447,
                        "videoUrl": ""
                    }
                ],
                "typeIcon": "https://8cuumq-6309-ppp.s3.sa-east-1.amazonaws.com/siteadmin/upload/img/pay_wt_icon.png",
                "typeId": 17630546,
                "typeName": "Perguntas de recarga"
            },
            {
                "questionList": [],
                "typeIcon": "https://8cuumq-6309-ppp.s3.sa-east-1.amazonaws.com/siteadmin/upload/img/game_wt_icon.png",
                "typeId": 17630510,
                "typeName": "Perguntas do jogo"
            }
        ]
export {
	comsteKefy
}